import React from 'react';
import { OperationProvider } from "../../../../Store/OperationProvider";
import PurchaseOperation from "./PurchaseOperation.jsx"

const NewPurchaseOperation = (props) => {
  return (
    <OperationProvider operation='Purchase'>
      <PurchaseOperation {...props} />
    </OperationProvider>
  )
}

export default NewPurchaseOperation;
