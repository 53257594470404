/* eslint-disable eqeqeq */
import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { formChange } from './actions';
import axios from 'axios';
import Form from './AuthLayout';
import { BASE_URL } from '../../Store/snippets';
import { FISCAL_DEV_HOST, MERCHANT_CABINET_URL, PULT_SITE_URL } from '../../constants/app';
// import fire from '../../Config/firebase';
// import firebase from 'firebase'

const protocol = window.location.protocol;
const hostName = window.location.host;
let PULT_URL = PULT_SITE_URL;
if (import.meta.env.MODE === 'production') {
    PULT_URL = MERCHANT_CABINET_URL;
    if (window.app && !!window.cordova) {
        PULT_URL = `${protocol}//business.kassa24.kz/cabinet`;
    }
}

if (hostName === FISCAL_DEV_HOST) {
    PULT_URL = PULT_SITE_URL;
}

//
// {
//     function deleteTokkenAndStorage() {
//         localStorage.clear();
//         document.cookie = 'tokken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
//     }

//     function getCookie(name) {
//         var matches = document.cookie.match(new RegExp(
//             "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//         ));
//         return matches ? decodeURIComponent(matches[1]) : undefined;
//     }
// }

// function deleteAllCookies() {
//     localStorage.clear();
//     const cookies = document.cookie.split(";");

//     for (var i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i];
//         const eqPos = cookie.indexOf("=");
//         const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//         document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//     }
// }

class AuthGarant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            errorMsg: '',
            errorHead: '',
            submitAttempt: false,
            isAlert: false,
            sessionExpired: false,
            loginBtn: true,
            kassaNumber: null
        }
        this.passField = React.createRef();
    }

    handlePassword = e => {
        const { value } = e.target;
        this.setState({
            ...this.state,
            password: value
        });
    }
    handleLogin = e => {
        const { value } = e.target;
        this.setState({
            ...this.state,
            login: value.replace(/\D+/g, '')
        });
    }

    handleKassaNumber = e => {
        const { value } = e.target;
        this.setState({
            ...this.state,
            kassaNumber: value
        })
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
              cursorPosition--;
              selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
          }

          return {
            value,
            selection
          };
        }

    register = () => {
        // axios.post(`https://api.amplitude.com/2/httpapi`,
        //     {
        //         api_key: "d314c6311f60a96a3d7ec5a856b85143",
        //         events: [
        //             {
        //                 event_type: "clicks register button",
        //                 event_category: "signup",
        //                 event_name: "clicks register button",
        //                 device_id: "10001",
        //                 user_id: "+" + this.state.login,
        //             }
        //         ]
        //     })
        this.props.history.push('/www/register')
    }

    passrecover = () => {
        // axios.post(`https://api.amplitude.com/2/httpapi`,
        //     {
        //         api_key: "d314c6311f60a96a3d7ec5a856b85143",
        //         events: [
        //             {
        //                 event_type: "forgot your password",
        //                 event_category: "login",
        //                 event_name: "forgot your password",
        //                 device_id: "10001",
        //                 user_id: "+" + "123456",
        //             }
        //         ]
        //     })
        // this.props.history.push('/passrec')
        window.open(`${PULT_URL}/www/reset`)
    }

    login = async (e) => {
        e.preventDefault();
        this.setState({
            submitAttempt: true,
            isAlert: false,
            loginBtn: false
        })

        try {
            const response = await axios.post(BASE_URL + '/auth',
                {
                    "Login": this.state.login,
                    "Password": this.state.password
                })
            const { Token, User } = response.data.Data
            
            //amplitude
            // const amplitude = await axios.post(`https://api.amplitude.com/2/httpapi`,
            // {
            //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
            //     events: [
            //         {
            //             event_type: "login success",
            //             event_category: "login",
            //             event_name: "login success",
            //             device_id: "10001",
            //             user_id: "+" + this.state.login,
            //         }
            //     ]
            // })

            localStorage.setItem('tokken', Token);
            localStorage.setItem('USER', JSON.stringify(User));
            localStorage.setItem('BASE_URL', BASE_URL);
            if(window.location.pathname === '/www/garant') {
                localStorage.setItem('KKM_GARANT', JSON.stringify(this.state.kassaNumber))
            }
            if(window.location.pathname === '/www/lombard') {
                localStorage.setItem('KKM_LOMBARD', JSON.stringify(this.state.kassaNumber))
            }
            document.cookie = 'tokken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = `tokken = ${Token};max-age=86340;path=/`;
            this.state.sessionExpired ? this.props.history.goBack() : this.props.history.push(`/www/comps`);
            if(this.state.kassaNumber !== null) {
                localStorage.setItem(`CURRENT_KKM_ID`, JSON.stringify(Number(this.state.kassaNumber)));
            }
        } catch (error) {
            // const amplitude = await axios.post(`https://api.amplitude.com/2/httpapi`,
            // {
            //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
            //     events: [
            //         {
            //             event_type: "login",
            //             event_category: "login",
            //             event_name: "login fail",
            //             device_id: "10001",
            //             user_id: "+" + this.state.login,
            //         }
            //     ]
            // })
            const submitAttempt = false;
            const isAlert = true;
            let errorHead = 'Ошибка входа';
            let errorMsg;
            if (error.response === undefined) {
                errorHead = 'Отсутствует связь с сервером'
                errorMsg = 'Проверьте соединение с интернетом или обратитесь в тех. поддержку';
            } else if (error.response.status > 501) {
                errorMsg = 'Аппаратно-программный комплекс Fiscal24 не отвечает, повторите попытку еще раз';
            } else if (error.response.status === 401) {
                errorMsg = 'Неверный логин или пароль';
            }

            if (error)
                this.setState({
                    errorMsg,
                    errorHead,
                    isAlert,
                    submitAttempt
                })
        }
    }

    componentDidMount() {

        // axios.post(`https://api.amplitude.com/2/httpapi`,
        //     {
        //         api_key: "d314c6311f60a96a3d7ec5a856b85143",
        //         events: [
        //             {
        //                 event_type: "app open",
        //                 event_category: "admin",
        //                 event_name: "app open",
        //                 device_id: "10001",
        //                 user_id: "+" + "123456",
        //             }
        //         ]
        //     })
        // deleteAllCookies();
        if (this.props.history.location.state !== undefined) {
            const expired = this.props.history.location.state;
            this.props.history.replace('/', undefined)
            const user = JSON.parse(localStorage.getItem('USER'));
            localStorage.removeItem('tokken');
            localStorage.removeItem('user');
            this.passField.current.focus()
            this.setState({
                ...this.state,
                sessionExpired: true,
                errorMsg: expired.message,
                errorHead: expired.head,
                isAlert: true,
                login: user.PhoneLogin
            })
        } else {
            const idKkm = localStorage.getItem('CURRENT_KKM_ID');
            const uid = localStorage.getItem(`Uid_${idKkm}`);
            localStorage.clear();
            if (uid !== null) {
                localStorage.setItem(`Uid_${idKkm}`, uid);
            }
        }
    }

    render() {

        return (
            <Form {...this.state} passrecover={this.passrecover} register={this.register} handleKassaNumber={this.handleKassaNumber} handlePassword={this.handlePassword} handleLogin={this.handleLogin} submit={this.login} passField={this.passField} />
        )
    }
}

export default AuthGarant;
