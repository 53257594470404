import React, { useState, useEffect } from 'react';
import SettingsTabs from './SettingsTabs';
import NewCashboxManage from './tabs/NewCashboxManage';
import NewOfdSettings from './tabs/NewOfdSettings';
import NewSections from './tabs/NewSections';
import NewCashiers from './tabs/NewCashiers';
import NewArticles from './tabs/NewArticles';
import axios from 'axios';
import { BASE_URL, getHeaders } from '../../../Store/snippets';
import NewCashboxTop from "./NewCashboxTop";
import {getAppModule} from "../../../Store/appVersion";
import style from '../styles/pages/cashbox/NewCashboxSettings.module.scss'
import HeaderVersion from "../HeaderVersion/HeaderVersion";
import MobileNav from "../../MobileNav/MobileNav";
import { useTranslation } from "react-i18next";
import { Capacitor } from '@capacitor/core';
const NewCashboxSettings = ({ history }) => {
  const { t } = useTranslation()

  let headers = getHeaders(history);
  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  const [activeNav, setActiveNav] = useState(1);
  const [step, setStep] = useState(1);
  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);
  const [ofds, setOfds] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [units, setUnits] = useState([]);
  const [showDropzone, setShowDropzone] = useState(false);
  const [edit, setEdit] = useState(false);
  const [snackbar, setSnackbar] = useState(false)
  const [snackText, setSnackText] = useState("")
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const sections = axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
    const articles = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
    const cashiers = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1`, { headers: headers })
    const ofds = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/ofds`, { headers: headers })
    const units = axios.get(`${BASE_URL}/kkms/${kkm.Id}/units`, { headers: headers })
    Promise.all([sections, articles, ofds, cashiers, units]).then(([sec, art, ofds, cashiers, units]) => {
      setSections(sec.data.Data.Sections.reverse())
      setArticles(art.data.Data.reverse())
      setOfds(ofds.data.Data)
      setCashiers(cashiers.data.Data.reverse())
      setUnits(units.data.Data)
    })
  }, [])

  useEffect(() => {
    document.getElementById('root').style.backgroundColor = getAppModule() ? '#F5F5F5' : 'transparent'
    return () => document.getElementById('root').style.backgroundColor = 'transparent'
  }, [getAppModule()])

  let navsList = [
    {
      id: 1,
      name: t('kassa_settings.cash_management')
    },
    {
      id: 2,
      name: t('kassa_settings.ofd_settings')
    },
    {
      id: 3,
      name: t('kassa_settings.sections')
    },
    {
      id: 4,
      name: t('kassa_settings.cashiers')
    },
    {
      id: 5,
      name: t('kassa_settings.nom')
    },
  ]

  const fetchArticles = () => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
    a.then(art => {
      setArticles(art.data.Data.reverse())
    })
  }

  const fetchSections = () => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
    a.then(art => {
      setSections(art.data.Data.Sections.reverse())
    })
  }

  const fetchCashiers = () => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1`, { headers: headers })
    a.then(art => {
      setCashiers(art.data.Data.reverse())
    })
  }

  const searchArticles = (key) => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles?search=${key}`, { headers: headers })
    a.then(res => {
      setArticles(res.data.Data.reverse())
    })
  }
  const searchCashiers = (key) => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1&search=${key}`, { headers: headers })
    a.then(res => {
      setCashiers(res.data.Data.reverse())
    })
  }
  const searchSections = (key) => {
    const a = axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1&search=${key}`, { headers: headers })
    a.then(res => {
      setSections(res.data.Data.Sections.reverse())
    })
  }

  const changeCashboxSettings = (name, autorenew = false) => {
    setPending(true)
    let formData = new FormData()
    formData.append('Name', name)
    formData.append('Autorenew', autorenew)
    axios.post(`${BASE_URL}/kkms/${kkm.Id}`,
      formData,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        setPending(false)
        kkm.Name = name
        kkm.Autorenew = autorenew
        localStorage.setItem('KKM', JSON.stringify(kkm))
        setSnackText(t('kassa_settings.cash_settings_saved'))
        setSnackbar(true)
        setTimeout(() => {
          setSnackText('')
          setSnackbar(false)
        }, 3000);
      }
    })
  }

  const changeOfdSettings = (ofdSettings) => {
    setPending(true)
    let formData = new FormData()
    formData.append('IdOfd', ofdSettings.ofdId)
    formData.append('Rnm', ofdSettings.rnm)
    formData.append('IdCPCR', ofdSettings.kkmid)
    formData.append('TokenCPCR', ofdSettings.token)
    axios.post(`${BASE_URL}/kkms/${kkm.Id}/settings/ofds`,
      formData,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        setPending(false)
        ofds.forEach(ofd => {
          if (ofd.Id === ofdSettings.ofdId) kkm.Ofd = ofd
        })
        kkm.Rnm = ofdSettings.rnm
        kkm.IdCPCR = ofdSettings.kkmid
        kkm.TokenCPCR = ofdSettings.token
        localStorage.setItem('KKM', JSON.stringify(kkm))
        setSnackText(t('kassa_settings.ofd_settings_saved'))
        setSnackbar(true)
        setTimeout(() => {
          setSnackText('')
          setSnackbar(false)
        }, 3000);
      }
    })
  }

  const addSection = (section) => {
    axios.post(`${BASE_URL}/kkms/${kkm.Id}/sections`,
      section,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
          .then(sec => {
            setSections(sec.data.Data.Sections.reverse())
            setSnackText(t('other.section_successfully_added'))
            setSnackbar(true)
            setTimeout(() => {
              setSnackText('')
              setSnackbar(false)
            }, 3000);
          })
      }
    })
  }

  const toggleSection = (id) => {
    axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/sections/${id}/toggle?all=1`, { headers: headers })
      .then(res => {
        if (res.data.Status === 200) {
          axios.get(`${BASE_URL}/kkms/${kkm.Id}/sections?all=1`, { headers: headers })
            .then(sec => {
              setSections(sec.data.Data.Sections.reverse())
            })
        }
      })
  }

  const toggleCashier = (id) => {
    axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers/${id}/toggle?all=1`, { headers: headers })
      .then(res => {
        if (res.data.Status === 200) {
          axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/cashiers?all=1`, { headers: headers })
            .then(sec => {
              setCashiers(sec.data.Data.reverse())
            })
        }
      })
  }

  const addArticle = (article) => {
    return axios.post(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`,
      article,
      { headers: headers }
    )
    .then(res => {
      if (res.data.Status === 200) {
        setEdit(false)
        axios.get(`${BASE_URL}/kkms/${kkm.Id}/settings/articles`, { headers: headers })
          .then(sec => {
            setArticles(sec.data.Data.reverse())
            if (article.Id) {
              setSnackText(t('other.item_successfully_updated'))
            } else {
              setSnackText(t('other.item_successfully_added'))
            }
            setSnackbar(true)
            setTimeout(() => {
              setSnackText('')
              setSnackbar(false)
            }, 3000);
          })
      }
    })
  }

  return (
    <React.Fragment>
      {
        window.matchMedia("(min-width: 1100px)").matches ?
          <HeaderVersion isActive={true} />
          :
          <MobileNav isActive={true} />
      }
      <main role="main" className={`${style.container} ${style[`container--${Capacitor.getPlatform()}`]}`}>
        <NewCashboxTop kkm={kkm}/>
        <SettingsTabs navsList={navsList} activeNav={activeNav} setActiveNav={setActiveNav} setStep={setStep} />
        {
          step === 1 ? (
            <NewCashboxManage
              kkm={kkm}
              pending={pending}
              changeCashboxSettings={(name, autorenew) => changeCashboxSettings(name, autorenew)}
            />
          ) : (
            step === 2
          ) ? (
            <NewOfdSettings
              ofds={ofds}
              pending={pending}
              changeOfdSettings={(ofdSettings) => changeOfdSettings(ofdSettings)}
            />
          ) : (
            step === 3
          ) ? (
            <NewSections
              sections={sections}
              addSection={(section) => addSection(section) }
              toggleSection={(id) => toggleSection(id)}
              searchSections={(key) => searchSections(key)}
              fetchSections={() => fetchSections()}
            />
          ) : (
            step === 4
          ) ? (
            <NewCashiers
              cashiers={cashiers}
              toggleCashier={(id) => toggleCashier(id)}
              searchCashiers={(key) => searchCashiers(key)}
              fetchCashiers={() => fetchCashiers()}
            />
          ) : (
            step === 5
          ) ? (
            <NewArticles
              articles={articles}
              showDropzone={showDropzone}
              setShowDropzone={setShowDropzone}
              fetchArticles={() => fetchArticles()}
              addArticle={(article) => addArticle(article)}
              edit={edit}
              setEdit={setEdit}
              sections={sections}
              units={units}
              searchArticles={(key) => searchArticles(key)}
              setSnackbar={setSnackbar}
              setSnackText={setSnackText}
            />
          ) : (
            null
          )
        }
      </main>
      {
        snackbar && (
          <div id="snackbar">
            <div className="leftside">
                <img src="/static/swagger/toasticon.svg?react" alt="" />
                <p>{snackText}</p>
                {/* <p>Успешно импортировано товаров: <span id="scsscount"></span></p> */}
            </div>
            {/* <div className="close-toast">
                <button className="transparent" onClick={() => setSnackbar(false)}>Закрыть</button>
            </div> */}
        </div>
        )
      }
    </React.Fragment>
  )
}

export default NewCashboxSettings;
