import React from 'react'
import { useTranslation } from "react-i18next";

import Warning from '../../../svg/warning.svg?react'

import s from './modal.module.scss'

const CanNotDidRefundModal = ({ closeModal }) => {
	const { t } = useTranslation()

	return (
		<div className={s.overlay}>
			<div className={s.wrapper}>
				<div className={s.top}>
					<Warning />
				</div>
				<p className={s.paragraph}>
					{t('other.operation_can_not_be_refunded') }
				</p>
        <button
					className={`${s.btn} ${s.secondaryBtn}`}
					onClick={closeModal}
				>
					<span className={s.btn_text}>{ t('main.close_modal') }</span>
				</button>
			</div>
		</div>
	)
}

export default CanNotDidRefundModal
