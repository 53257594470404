import React from 'react'
import style from '../styles/pages/shift/error.module.scss'
import { useTranslation } from "react-i18next";

const ErrorState = ({ history }) => {
  const { t } = useTranslation()

  const goBack = () => {
    history.goBack()
  }

  return (
    <div class={style.wrapper}>
      <div class={style.errorBlock}>
        <div class={style.header}>
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="19" fill="#E6342A"/>
            <g clipPath="url(#clip0_4723_16984)">
            <path d="M19.8337 9.34643L29.0044 25.1627C29.0889 25.3084 29.1334 25.4737 29.1334 25.642C29.1334 25.8102 29.0889 25.9755 29.0044 26.1212C28.9199 26.2669 28.7984 26.388 28.652 26.4721C28.5057 26.5562 28.3397 26.6005 28.1707 26.6005H9.82937C9.66039 26.6005 9.49438 26.5562 9.34803 26.4721C9.20169 26.388 9.08016 26.2669 8.99567 26.1212C8.91118 25.9755 8.8667 25.8102 8.8667 25.642C8.8667 25.4737 8.91118 25.3084 8.99568 25.1627L18.1663 9.34643C18.2508 9.20072 18.3724 9.07972 18.5187 8.9956C18.665 8.91147 18.8311 8.86719 19 8.86719C19.169 8.86719 19.335 8.91147 19.4814 8.9956C19.6277 9.07972 19.7492 9.20072 19.8337 9.34643ZM11.4968 24.6834H26.5033L19 11.7428L11.4968 24.6834ZM18.0373 21.8077H19.9627V23.7248H18.0373V21.8077ZM18.0373 15.8339H19.9627V20.2672H18.0373V15.8339Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_4723_16984">
            <rect width="25.3333" height="25.3333" fill="white" transform="translate(6.3335 6.33203)"/>
            </clipPath>
            </defs>
          </svg>
          <p>{ t('other.failed_testing') }</p>
        </div>
        <p class={style.info}>{ t('error.no_access_to_cash_register') }</p>
        <hr></hr>
        <div class={style.helpers}>
          <p class={style.label}>{ t('error.contact_tech_support') }</p>
          <div class={style.number}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.244 7.12133C6.86956 8.22032 7.77968 9.13044 8.87867 9.756L9.468 8.93067C9.56277 8.79796 9.7029 8.70459 9.86187 8.66822C10.0208 8.63186 10.1876 8.65502 10.3307 8.73333C11.2735 9.24862 12.3148 9.55852 13.386 9.64267C13.5532 9.65592 13.7092 9.73169 13.823 9.85488C13.9368 9.97807 14 10.1396 14 10.3073V13.282C14 13.4471 13.9388 13.6063 13.8282 13.7288C13.7177 13.8513 13.5655 13.9285 13.4013 13.9453C13.048 13.982 12.692 14 12.3333 14C6.62667 14 2 9.37333 2 3.66667C2 3.308 2.018 2.952 2.05467 2.59867C2.0715 2.43446 2.14866 2.28235 2.27119 2.17176C2.39373 2.06116 2.55294 1.99996 2.718 2H5.69267C5.86037 1.99998 6.02193 2.06317 6.14512 2.17697C6.26831 2.29077 6.34408 2.44682 6.35733 2.614C6.44148 3.68519 6.75138 4.72645 7.26667 5.66933C7.34498 5.81238 7.36814 5.97916 7.33178 6.13813C7.29541 6.2971 7.20204 6.43723 7.06933 6.532L6.244 7.12133ZM4.56267 6.68333L5.82933 5.77867C5.46986 5.00273 5.22357 4.17923 5.098 3.33333H3.34C3.336 3.444 3.334 3.55533 3.334 3.66667C3.33333 8.63733 7.36267 12.6667 12.3333 12.6667C12.4447 12.6667 12.556 12.6647 12.6667 12.66V10.902C11.8208 10.7764 10.9973 10.5301 10.2213 10.1707L9.31667 11.4373C8.95244 11.2958 8.59867 11.1287 8.258 10.9373L8.21933 10.9153C6.91172 10.1711 5.82885 9.08828 5.08467 7.78067L5.06267 7.742C4.87127 7.40133 4.70419 7.04756 4.56267 6.68333Z" fill="#8E8E8E"/>
            </svg>
            <p class={style.phone}>+7 702 075 06 01</p>
          </div>
          <button class={style.redBtn} onClick={goBack}>
            { t('error.back_to_cash_register_list') }
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorState;
