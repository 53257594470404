import React from 'react';
import BarcodeScanner from '../../../../Modal/BarcodeScanner';
// import Creatable from 'react-select/creatable';
import { Store } from '../../../../../Store/OperationProvider';
import "./_.scss";
import { Capacitor } from '@capacitor/core';
import { openScanBarcode } from '../../../../../Utils/scan-barcode';

// const customStyles = {
//     // option: (provided, state) => ({
//     //     ...provided,
//     //     borderBottom: '1px dotted pink',
//     //     color: state.isSelected ? 'red' : 'blue',
//     //     padding: 20,
//     // }),
//     container: () => ({
//         position: 'relative'
//     }),
//     control: () => ({
//         // none of react-select's styles are passed to <Control />
//         width: '100%',
//         height: '100%',
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         cursor: 'text',
//     }),
//     valueContainer: () => ({
//         padding: '.5rem 1rem',
//         height: '100%',
//     }),
//     indicatorsContainer: () => ({
//         display: 'none'
//     }),
//     input: () => ({
//         padding: 0,
//         margin: 0,
//     }),
//     menu: () => ({
//         top: '100%',
//         backgroundColor: 'hsl(0,0%,100%)',
//         borderRadius: '4px',
//         boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
//         marginBottom: '8px',
//         marginTop: '8px',
//         position: 'absolute',
//         width: '100%',
//         zIndex: 1,
//         boxSizing: 'border-box',
//         left: 0,
//     }),
// }

class NameInput extends React.Component {
    state = {
        selectedOption: {value: "", label: ""},
        currentArticle: {},
        open: false,
        showScanner: false,
    };

    setShowScanner = () => {
      this.setState({
        showScanner: !this.state.showScanner
      })
    }

    openNativeBarcodeScan = async () => {
      try {
        if (Capacitor.isNativePlatform()) {
          const result = await openScanBarcode();
  
          context.Articles.map(art => (
            art.Qr === result &&
            this.setState({
              currentArticle: art
            }, () => {
              context.setArticle(art);
            })
          ))
        } else {
          this.setShowScanner();
        }
      } catch (e) {}
    };
  

    handleClick = context => e => {
      context.Articles.map(
        art =>
          art.Id === e.target.value &&
          this.setState({
            selectedOption: {
              label: art.Name,
              value: art.Id
            },
            currentArticle: art
          }, () => {
            context.setArticle(art);
          })
      );
    }

    handleChange = context => e => {
        this.setState({
          currentArticle: {
            ...this.state.currentArticle,
            Name: e.target.value
          }
        }, () => context.setArticle(this.state.currentArticle))
    };

    setQr = context => code => {
      context.Articles.map(art => (
        art.Qr === code &&
        this.setState({
          currentArticle: art
        }, () => {
          context.setArticle(art);
        })
      ))
    }

      handleOpenToggle = () => {
          // document.getElementById("myDropdown").classList.toggle("show");
        this.setState({
          open: true
        })
      };

      handleCloseToggle = () => {
        setTimeout(() =>
          this.setState({
            open: false
          }), 180
        )

      }

    render() {
        return (
            <Store.Consumer>
                {
                    context => (
                        <div>
                            <div className="dropdown">
                              <div className="dropdown-content">
                                <div className="input-wrapper-barcode">
                                  <input
                                    className="myInput barcode-input"
                                    autoComplete="off"
                                    type="text"
                                    id="myInput"
                                    placeholder="Название товара или услуги"
                                    value={this.props.position.Name ? this.props.position.Name : ""}
                                    onChange={this.handleChange(context)}
                                    onFocus={this.handleOpenToggle}
                                    onBlur={() => this.handleCloseToggle()}
                                  />
                                  <div className="sale-barcode" onClick={this.openNativeBarcodeScan}>
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0.666992 0.333374H2.33366V13.6667H0.666992V0.333374ZM4.00033 0.333374H5.66699V13.6667H4.00033V0.333374ZM6.50033 0.333374H9.00033V13.6667H6.50033V0.333374ZM9.83366 0.333374H11.5003V13.6667H9.83366V0.333374ZM12.3337 0.333374H14.0003V13.6667H12.3337V0.333374ZM14.8337 0.333374H17.3337V13.6667H14.8337V0.333374Z" fill="#575757"/>
                                      </svg>
                                  </div>
                                </div>
                                {this.state.open && (
                                  <div id="myDropdown2" className="dropdownContainer">
                                  {context.Articles.map(art => (
                                    art.Name.toLowerCase().includes(this.props.position.Name.toLowerCase()) && (
                                      <li key={art.Id} onClick={this.handleClick(context)} value={art.Id} type="none" className="select-row">
                                        {art.Name}
                                      </li>
                                    )
                                  ))}
                                </div>
                                )}

                              </div>
                            </div>
                            <BarcodeScanner setCode={this.setQr(context)} setShowScanner={this.setShowScanner} showScanner={this.state.showScanner} />
                        </div>
                    )
                }
            </Store.Consumer>

        )
    }
};

export default NameInput;

// handleChange = context => option => {
    //     // this.setState({
    //     //     selectedOption: {value: option.Id, label: option.Name}
    //     // });
    //     this.setState({
    //         value: context.Position.SelectedOption
    //     })
    //     context.setArticle({...option});
    //     console.log("asdSAD", option);
    //     console.log("SELECTED", context.Position.SelectedOption);
    //     // console.log(`Option selected:`, option);
    //     // console.group('Value Changed');
    //     // console.log(selectedOption);
    //     // console.log(`action: ${actionMeta.action}`);
    //     // console.groupEnd();
    //     // console.log(selectedOption)
    // };
    //
    // handleInputChange  = context => (inputValue, actionMeta, e) => {
    //     this.setState({
    //         inputValue: e.target.value,
    //         globalValue: inputValue
    //     });
    //     console.group('Input Changed');
    //     console.log(inputValue);
    //     console.log(`action: ${actionMeta.action}`);
    //     console.groupEnd();
    // };
