import React, { useEffect, useState } from "react";
import style from "../../styles/pages/report/tabs/NewReportSections.module.scss";
import NewPrintButton from "../modules/NewPrintButton";
import { formatAmount } from "../../../../Store/snippets";
import ArrowDownSLine from "../../svg/arrow-down-s-line.svg?react";
import ArrowUpSLine from "../../svg/arrow-up-s-line.svg?react";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import NewDownloadPDFButton from "../modules/NewDownloadPDFButton";

const NewReportSections = ({
  reportSections,
  getReportSections,
  isLoading,
}) => {
  const { t } = useTranslation();

  const sectionSubtitles = [
    t("report.sales"),
    t("report.sales_refunds"),
    t("report.purchase_refunds"),
    t("report.service_income"),
    t("report.service_expense"),
  ];

  const [sectionMobileIsCollapsed, setSectionMobileIsCollapsed] =
    useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  useEffect(() => {
    getReportSections();
  }, []);

  const reportSectionRenderList = Object.keys(reportSections).map(
    (reportSection, index) => {
      return (
        <div
          key={index}
          className={`${style["table__list"]} ${
            sectionMobileIsCollapsed &&
            selectedSectionIndex === index &&
            style.collapsed
          } ${style["pdf-collapsed"]}`}
        >
          <div
            className={style["table__item-group"]}
            onClick={() => {
              setSelectedSectionIndex(index);
              setSectionMobileIsCollapsed(!sectionMobileIsCollapsed);
            }}
          >
            <div className={style["table__item"]}>
              {t("report.section")}:{" "}
              {`${reportSection} ${reportSections[reportSection].Name}`}
            </div>
            <button className={`${style.btn} ${style["btn--icon"]}`}>
              <ArrowDownSLine />
            </button>
            <button className={`${style.btn} ${style["btn--icon"]}`}>
              <ArrowUpSLine />
            </button>
          </div>
          {Object.keys(reportSections[reportSection])
            .filter((item) => item !== "Name")
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${style["table__item-group"]} ${style["pdf-collapsed-item"]}`}
                >
                  <div className={style["table__item"]}>
                    {sectionSubtitles[index]}
                  </div>
                  <div className={style["table__item"]}>
                    {formatAmount(reportSections[reportSection][item])} ₸
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
  );
  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {reportSections ? (
            <div className={`${style["flex-1"]} ${style["mt-20"]}`}>
              <div
                className={`${style.card} ${style["card--white-nopadding"]}`}
              >
                <div className={style.table}>
                  <div className={style["table__content"]}>
                    <div className={style["table__header"]}>
                      <div>{t("report.section_totals_for_shift")}</div>
                    </div>
                    <div
                      className={`${style["table__body"]}`}
                      id="report-container"
                    >
                      {reportSectionRenderList}
                    </div>
                  </div>
                </div>
              </div>
              {Capacitor.isNativePlatform() ? (
                <NewDownloadPDFButton
                  absolutePosition={true}
                  pdfCurrentStyle={style["pdf-styles"]}
                  fileName="Итоги-по-секциям-за-смену"
                />
              ) : (
                <NewPrintButton absolutePosition={true} />
              )}
            </div>
          ) : (
            <div
              className={`${style.card} ${style["card--white-nopadding"]} ${style["mt-20"]}`}
              style={{ width: "100%" }}
            >
              <div className={`${style["text-center"]} ${style["pa-40"]}`}>
                <p className={`${style.paragraph} ${style["paragraph--sub"]}`}>
                  {t("other.no_open_shift_for_section_report").split("|")[0]}
                  <br />
                  {t("other.no_open_shift_for_section_report").split("|")[0]}
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default NewReportSections;
