/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react"
import style from "../../styles/pages/addPosition.module.scss"
import Barcode from "../images/barcode.svg?react"
import Minus from "../images/minus.svg?react"
import Plus from "../images/plus.svg?react"
import ArrowDown from "../images/arrowDown.svg?react"
import Check from "../images/check.svg?react"
import OutsideClicker from "../helpers/OutsideClicker";
import BarcodeScanner from '../../../Modal/BarcodeScanner';
import { useTranslation } from "react-i18next";
import { COMPANIES_IDS_WITH_FIXED_DISCOUNT, DISCOUNT_TYPES, IS_DEV, OPERATION_TYPES } from "../../../../constants/app"
import RadioButton from "../../../Common/RadioButton"
import { Capacitor } from "@capacitor/core"
import { openScanBarcode } from "../../../../Utils/scan-barcode"

const FindArticle = ({ articles, name, handleClickArticle }) => {
  if (articles.length === 0) return null;

  if (name) {
    return (
      <div className={style.dropdownList} style={{maxHeight: '403px', padding: "10px 0"}}>
        {
          articles.map(article => (
            article.Name.toLowerCase().includes(name.toLowerCase()) && (
              <div className={style.dropdownItem} key={article.Id} style={{padding: '6px 16px', borderRadius: '0'}} onClick={() => handleClickArticle(article.Id)}>
                {article.Name}
              </div>
            )
          ))
        }
      </div>
    )
  }
  return (
    <div className={style.dropdownList} style={{maxHeight: '403px', padding: "10px 0"}}>
      {
        articles.map(article => (
          <div className={style.dropdownItem} key={article.Id} style={{padding: '6px 16px', borderRadius: '0'}} onClick={() => handleClickArticle(article.Id)}>
            {article.Name}
          </div>
        ))
      }
    </div>
  )
}

// const defaultMaskOptions = {
//   prefix: '',
//   includeThousandsSeparator: true,
//   thousandsSeparatorSymbol: ' ',
//   allowDecimal: true,
//   decimalSymbol: '.',
//   decimalLimit: 2,
//   allowNegative: false,
//   allowLeadingZeroes: false,
// }

const AddPosition = ({context, status, show, adaptive, closeModal}) => {
  const { t, i18n } = useTranslation()

  const [showArticles, setShowArticles] = useState(false)
  const [showUnits, setShowUnits] = useState(false)
  const [showSections, setShowSections] = useState(false)
  const [showDomains, setShowDomains] = useState(false)
  const [emptyForm, setEmptyForm] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [excise, setExcise] = useState(false)
  const [showScanner, setShowScanner] = useState(false)
  const [domainText, setDomainText] = useState({
    "Торговля": t('kassa.trade'),
    "Услуги": t('kassa.services'),
    "Ломбард": "Ломбард",
    "ГСМ": "ГСМ",
    "Отели": t('kassa.hotels'),
    "Такси": "Такси",
    "Парковка": t('kassa.parking'),
  })

  useEffect(() => {
    let checkData = {
      CheckImg: "",
      CheckId: 0,
      CheckLink: "",
    }
    localStorage.setItem('checkData', JSON.stringify(checkData))
  }, [])

  const openNativeBarcodeScan = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const result = await openScanBarcode();

        context.Articles.map(
          art =>
            art.Qr === result && context.setArticle(art, true, status)
        )
      } else {
        setShowScanner(true);
      }
    } catch (e) {}
  };


  useEffect(() => {
    if (context.Position.Name || context.Position.Price || context.Position.MarkupPercent || context.Position.DiscountPercent) {
      setEmptyForm(false)
    } else {
      setEmptyForm(true)
    }
  }, [context.Position])

  useEffect(() => {
    setDomainText({
      "Торговля": t('kassa.trade'),
      "Услуги": t('kassa.services'),
      "Ломбард": "Ломбард",
      "ГСМ": "ГСМ",
      "Отели": t('kassa.hotels'),
      "Такси": "Такси",
      "Парковка": t('kassa.parking'),
    })
  }, [i18n.language])

  const setQr = context => code => {
    context.Articles.map(
      art =>
        art.Qr === code && context.setArticle(art, true, status)
    )
  }

  const handleClickArticle = id => {
    context.Articles.map(
      art =>
        art.Id === id && (
          context.setArticle(art, true, status)
        )
    );
    setShowArticles(false)
    setNameError(false)
    setPriceError(false)
  }

  const handleChangeArticle = (name) => {
    context.nameInputHandler({ value: name }, status)
    setNameError(false)
  };
  
  const handlePriceChange = (price) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice) && regex.test(price)) {
      context.priceInputHandler({ value: price, name: 'Price' }, status);
      setPriceError(false)
    }
  };

  const handleQtyChange = price => {
    if (price === Number(price)) {
      // let qty = Number(price);
      // if (price < 1) {
      //     qty = 0;
      // }
      context.positionInputHandler({ value: price, name: 'Qty' }, status);
    }
  };

  const minusQty = () => {
    if ((parseFloat(context.Position.Qty) - 1).toFixed(2) >= 1) {
      context.positionInputHandler(
        {
          value: (parseFloat(context.Position.Qty) - 1).toFixed(2),
          name: 'Qty'
        },
        status
      );
    }
  }

  const plusQty = () => {
    context.positionInputHandler(
      {
        value: (parseFloat(context.Position.Qty) + 1).toFixed(2),
        name: 'Qty'
      },
      status
    );
  }

  const handleChangeDiscountMarkup = (value, type) => {
    context.positionDiscountMarkupHandler({ value: Number(value), name: `${type}Percent`, type }, status);
  }

  const handleInputExciseCode = (value, type) => {
    context.exciseCodeInputHandler({value, name: type}, status);
  }

  const clearForm = () => {
    context.resetPosition()
  }

  const addPosition = () => {
    if (!context.Position.Name) {
      setNameError(true)
    } else {
      setNameError(false)
    }
    if (!context.Position.Price) {
      setPriceError(true)
    } else {
      setPriceError(false)
    }
    if (context.Position.Name && context.Position.Price) {
      context.resetPosition()
      context.addNewPreCheckPosition()
    }
  }

  const onlyNumber = (e) => {
    let keyCode = (e.keyCode ? e.keyCode : e.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      e.preventDefault();
    }
  }

  const getUnitName = () => {
    if (context && context.Position && context.Position.Unit) {
      return i18n.language === 'ru' ? context.Position.Unit.NameRU : context.Position.Unit.NameKAZ;
    }
    return t('main.no_name');
  };

  const kkmInfo = JSON.parse(localStorage.getItem(`KKM`));
  const IdCompany = kkmInfo ? kkmInfo.IdCompany : null;

  return (
    <div className={style.addPosition}>
      <div className={style.header}>
        <p className={style.title}>{ t('receipt.add_item') }</p>
        {
          emptyForm ? (
            <p className={style.clear}>{ t('receipt.clear_form') }</p>
          ) : (
            <p className={style.clearActive} onClick={() => clearForm()}>{ t('receipt.clear_form') }</p>
          )
        }
      </div>

      <div className={style.body}>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.item_name') }</label>
          <OutsideClicker setShow={() => setShowArticles()}>
            <div className={style.dropdownWrapper}>
              <div className={style.barcodeWrapper}>
                <input
                  type="text"
                  placeholder={ t('receipt.enter_name') }
                  className={(nameError || context.NameError) ? style.errorInput : ''}
                  value={context.Position.Name}
                  onChange={(e) => handleChangeArticle(e.target.value)}
                  onFocus={() => setShowArticles(true)}
                  maxLength={250}
                />
                <div className={style.barcode} onClick={() => {
                  openNativeBarcodeScan()
                  setShowArticles(false)
                }}>
                  <Barcode />
                </div>
              </div>
              {
                showArticles && <FindArticle articles={context.Articles} name={context.Position.Name} handleClickArticle={(id) => handleClickArticle(id)} />
              }
            </div>
          </OutsideClicker>
          {(nameError || context.NameError) && <p className={style.errorText}>{ t('other.enter_name') }</p>}
        </div>

        <div className={style.inputWrapper}>
          <div className={style.doubleWrapper}>
            <div className={style.singleItem}>
              <label htmlFor="">{ t('receipt.price') }</label>
              <input
                type="text"
                placeholder="0.00"
                className={(priceError || context.PriceError) ? style.errorInput : ''}
                value={context.Position.Price === 0 ? '' : context.Position.Price}
                onKeyUp={(e) => onlyNumber(e)}
                onChange={(e => handlePriceChange(e.target.value))}
              />
              {(priceError || context.PriceError) && <p className={style.errorText}>{ t('other.enter_amount') }</p>}
            </div>
            <div className={style.singleItem}>
              <label htmlFor="">{ t('receipt.quantity') }</label>
              <div className={style.amount}>
                <button className={style.minus} onClick={() => minusQty()}>
                  <Minus />
                </button>
                <input type="text" placeholder="1.00" value={context.Position.Qty} onChange={(e => handleQtyChange(e.target.value))}/>
                <button className={style.plus} onClick={() => plusQty()}>
                  <Plus />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.unit_of_measurement') }</label>
          <OutsideClicker setShow={setShowUnits}>
            <div className={style.dropdownWrapper} onClick={() => setShowUnits(!showUnits)}>
                <div className={style.dropdown}>
                  <p>{getUnitName()}</p>
                  <ArrowDown />
                </div>
                {showUnits && context.Units && context.Units.length > 0 && (
                    <div className={style.dropdownList}>
                      {
                        context.Units.map(unit => (
                          <div className={style.dropdownItem} key={unit.Id} onClick={() => context.unitChangeHandler(unit.Id, status)}>
                            {i18n.language === 'ru' ? unit.NameRU : unit.NameKAZ}
                          </div>
                        ))
                      }
                    </div>
                  )
                  }
            </div>
          </OutsideClicker>
        </div>

        {
          show && (
            <div className={style.inputWrapper}>
              <div className={style.checkboxWrapper} onClick={() => setExcise(!excise)}>
                {
                  excise ? (
                    <div className={style.checkboxActive}>
                      <Check />
                    </div>
                  ) : (
                    <div className={style.checkbox}></div>
                  )
                }
                <p>{ t('receipt.excise_goods') }</p>
              </div>
              {
                excise && (
                  <input
                    type="text"
                    placeholder={ t('other.excise_code') }
                    className={style.excise}
                    value={context.Position.ProductCode}
                    onChange={e => handleInputExciseCode(e.target.value, 'ProductCode')}
                  />
                )
              }
            </div>
          )
        }

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.section') }</label>
          <OutsideClicker setShow={setShowSections}>
            <div className={style.dropdownWrapper} onClick={() => setShowSections(!showSections)}>
              <div className={style.dropdown}>
                <p>{context.Position.Section.Name}</p>
                <ArrowDown />
              </div>
              {
                showSections && (
                  <div className={style.dropdownList}>
                     {
                        context.Sections.map(section => (
                          <div className={style.dropdownItem} key={section.Id} onClick={() => context.sectionChangeHandler(section.Id, status)}>
                            {section.Name}
                          </div>
                        ))
                      }
                  </div>
                )
              }
            </div>
          </OutsideClicker>
        </div>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('other.activity_type') }</label>
          <OutsideClicker setShow={setShowDomains}>
            <div className={style.dropdownWrapper} onClick={() => setShowDomains(!showDomains)}>
              <div className={style.dropdown}>
                <p>{domainText[context.Position.Domain.Name]}</p>
                <ArrowDown />
              </div>
              {
                showDomains && (
                  <div className={style.dropdownList}>
                     {
                        context.Domains.map(domain => (
                          <div className={style.dropdownItem} key={domain.Id} onClick={() => context.domainChangeHandler(domain.Id, status)}>
                            {domainText[domain.Name]}
                          </div>
                        ))
                      }
                  </div>
                )
              }
            </div>
          </OutsideClicker>
        </div>

        {status === OPERATION_TYPES.SALE.KEY && ((!IS_DEV && COMPANIES_IDS_WITH_FIXED_DISCOUNT.includes(IdCompany)) || IS_DEV) && (
          <>
            <hr />
            <div className={style.radioBlock}>
              <label htmlFor="">{ t('discount_type.label') }</label>
              <div className={style.radioWrapper}>
                <RadioButton
                  name="discountType"
                  id="percent"
                  value={DISCOUNT_TYPES.PERCENT}
                  text={ t('discount_type.percent') }
                  onChange={(e => context.discountTypeHandler(e.target.value, status))}
                  checked={context.DiscountType === DISCOUNT_TYPES.PERCENT}
                />
                <RadioButton
                  name="discountType"
                  id="fix"
                  value={DISCOUNT_TYPES.FIXED}
                  text={ t('discount_type.fixed') }
                  onChange={(e => context.discountTypeHandler(e.target.value, status))}
                  checked={context.DiscountType === DISCOUNT_TYPES.FIXED}
                />
              </div>
            </div>
          </>
        )}

        {
          show && (
            <div className={style.inputWrapper}>
              <div className={style.doubleWrapper}>
                <div className={style.singleItem}>
                  <label htmlFor="">{ t('receipt.markup') }, {context.DiscountType === DISCOUNT_TYPES.FIXED ? '₸' : '%'}</label>
                  <input
                    type="text"
                    placeholder="0.00"
                    value={context.Position.MarkupPercent === 0 ? '' : context.Position.MarkupPercent}
                    onKeyUp={(e) => onlyNumber(e)}
                    onChange={(e) => handleChangeDiscountMarkup(e.target.value, 'Markup')}
                  />
                </div>
                <div className={style.singleItem}>
                  <label htmlFor="">{ t('receipt.discount')}, {context.DiscountType === DISCOUNT_TYPES.FIXED ? '₸' : '%'}</label>
                  <input
                    type="text"
                    placeholder="0.00"
                    value={context.Position.DiscountPercent === 0 ? '' : context.Position.DiscountPercent}
                    onKeyUp={(e) => onlyNumber(e)}
                    onChange={(e) => handleChangeDiscountMarkup(e.target.value, 'Discount')}
                  />
                </div>
              </div>
            </div>
          )
        }

        {
          adaptive ? (
            <div className={style.mobileActions}>
              <p className={style.close} onClick={() => closeModal()}>{ t('receipt.close') }</p>
              <div className={style.addToPrecheck} onClick={(e) => context.addPreCheckPosition(e, status)}>
                { t('other.add_to_precheck') }
              </div>
            </div>
          ) : (
            <button className={style.addMore} onClick={() => addPosition()}>
              <Plus />
              <p>{ t('receipt.add_another') }</p>
            </button>
          )
        }

      </div>
      <BarcodeScanner
        setCode={setQr(context)}
        setShowScanner={setShowScanner}
        showScanner={showScanner}
      />
    </div>
  )
}

export default AddPosition;
