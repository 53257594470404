import './styles/pagination.scss'
import Pagination from "react-js-pagination";
import React, {useEffect, useState} from "react";
import ArrowLeftLine from "../svg/arrow-left-s-line.svg?react";
import ArrowRightSLine from "../svg/arrow-right-s-line.svg?react";
import { useTranslation } from "react-i18next";

const NewPagination = ({list = [], setPage, activePage, total = 0, itemsPerPage = 10}) => {
	const [enteredPage, setEnteredPage] = useState(1)

	const totalItems = total > 0 ? total : list.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const [pageCount, setPageCount] = useState(
    totalItems >= itemsPerPage
      ? `${activePage * itemsPerPage - itemsPerPage + 1}-${Math.min(
          activePage * itemsPerPage,
          totalItems,
        )} из ${totalItems}`
      : `1-${totalItems} из ${totalItems}`,
  );

  const { t } = useTranslation();

  useEffect(() => {
    setPageCount(
      totalItems >= itemsPerPage
        ? `${activePage * itemsPerPage - itemsPerPage + 1}-${Math.min(
            activePage * itemsPerPage,
            totalItems,
          )} из ${totalItems}`
        : `1-${totalItems} из ${totalItems}`,
    );
  }, [list, activePage, totalItems, itemsPerPage]);

  const setPagination = (page) => {
    let newPage = page;
    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > totalPages) {
      newPage = totalPages;
    }

    setPage(newPage);
    const startItem = (newPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(newPage * itemsPerPage, totalItems);
    setPageCount(`${startItem}-${endItem} из ${totalItems}`);
  };

	return (
		<div className="page-bottom">
			<p className="page-bottom__title">{pageCount}</p>

			<div className="page-bottom__action">
				<p className="page-bottom__title">{ t('other.go_to_page') }</p>
				<div className="page-bottom__action-group">
					<div className="page-bottom__search-box">
						<input
							className="page-bottom__input"
							placeholder="1"
							onChange={e => setEnteredPage(+e.target.value)}
						/>
						<button
							className="page-bottom__btn"
							onClick={() => setPagination(enteredPage)}
						>
							›
						</button>
					</div>

					<div className="page-bottom__divider"></div>
					<Pagination
						activePage={activePage}
						itemsCountPerPage={itemsPerPage}
						totalItemsCount={totalItems}
						onChange={(p) => setPagination(p)}
						hideFirstLastPages={true}
						prevPageText={<span><ArrowLeftLine /></span>}
						nextPageText={<span><ArrowRightSLine /></span>}
						itemClass="pagination__list-item"
						itemClassPrev="pagination__list-item--prev"
						itemClassNext="pagination__list-item--next"
					/>
				</div>
			</div>
		</div>
	)
}

export default NewPagination
