import React, { useEffect, useState } from "react";
// import Header from '../../Header/Header';
import MobileNav from "../../MobileNav/MobileNav";
import Shift from "./Shift";
import ErrorState from "./ErrorState";
import MobileShift from "./MobileShift";
import MobileErrorState from "./MobileErrorState";
import Modal from "./modals/newCloseShiftModal";
import axios from "axios";
// import { getAppModuleUrl } from "../../../Store/appVersion";
import {
  BASE_URL,
  getUser,
  getKkm,
  getUid,
  getHeaders,
  getError,
} from "../../../Store/snippets";
import HeaderVersion from "../HeaderVersion/HeaderVersion";
import Error503Modal from "../Modals/Error500Modal";
import { useHistory, withRouter } from "react-router-dom";

let headers;

const ShiftContainer = (props) => {
  const [state, setState] = useState({
    user: null,
    kkm: null,
    balances: null,
    shift: null,
    shiftOpen: false,
    shiftOpenByAnotherCashier: false,
    shiftBlocked: false,
    shiftExpired: false,
    kkmBlocked: false,
    shiftErrorText: "",
    mounted: false,
    pending: false,
    connection: true,
    connectionErrorText: "",
    errorData: null,
    modalWarning: false,
    shiftOpening: false,
    dashboardData: null,
    error403: false,
  });

  const history = useHistory(); // Используем useHistory для маршрутизации

  // Функция проверки смены
  const checkShift = async ({ kkm, user }) => {
    let shiftErrorText = "";
    let shift, shiftOpen;

    if (
      kkm.Lock === true &&
      user.Lock === true &&
      kkm.IdShift === user.IdShift
    ) {
      shiftOpen = true;
    }

    if (!kkm.IsActive) {
      return {
        shiftOpen,
        shiftBlocked: true,
        shiftErrorText: "Касса не оплачена.",
      };
    }

    if (kkm.IdStatusKkm === 3) {
      return {
        shiftOpen,
        shiftBlocked: true,
        shiftErrorText: `${
          shiftOpen
            ? "Смена на кассе открыта, но касса заблокирована в ОФД"
            : "Касса заблокирована в ОФД"
        }. Это означает, что у вас не получится выбить чек. Если нужно ${
          shiftOpen ? "закрыть" : "открыть"
        } смену, можете это сделать, нажав кнопку "${
          shiftOpen ? "Закрыть" : "Открыть"
        } смену". Обратитесь в тех. поддержку чтобы разобраться с этой проблемой.`,
      };
    }
    if (kkm.IdStatusKkm === 1) {
      return {
        shiftBlocked: true,
        shiftErrorText: "Касса в процессе регистрации.",
      };
    }

    if (kkm.Lock === true) {
      // Если есть на кассе есть смена

      if (user.Lock === true) {
        if (kkm.IdShift !== user.IdShift) {
          return {
            shiftOpenByAnotherCashier: true,
            shiftBlocked: true,
            shiftErrorText: `Смена открыта кассиром ${user.Name}.`,
          };
        } else {
          const shiftResponse = await axios.get(
            `${BASE_URL}/shifts/${kkm.IdShift}`,
            { headers }
          );

          try {
            await getUid(this.props.history);
          } catch (error) {
            // console.log('error', error);
            return {
              shift,
              shiftBlocked: true,
              shiftOpen: true,
              shiftErrorText:
                "Касса заблокирована из-за работы с разных устройств.",
            };
          }

          shift = shiftResponse.data.Data;
          localStorage.setItem("SHIFT", JSON.stringify(shift));
        }
        const currentDate = new Date();
        const shiftOpenDate = new Date(shift.DateOpen);
        const shiftExpiredDifferense =
          (currentDate.getTime() - shiftOpenDate.getTime()) / 1000 / 60 / 60;
        if (currentDate.getTime() > shiftOpenDate.getTime()) {
          if (shiftExpiredDifferense > 23.9) {
            return {
              shift,
              shiftExpired: true,
              shiftOpen: true,
              shiftErrorText: "Смена просрочена.",
            };
          }
        } else {
          return {
            shift,
            shiftExpired: true,
            shiftOpen: true,
            shiftErrorText:
              "Время на вашем устройстве не соответствует действительному.",
          };
        }
      } else {
        return {
          shiftOpenByAnotherCashier: true,
          shiftBlocked: true,
          shiftErrorText: `Смена открыта кассиром ${user.Name}.`,
        };
      }

      return {
        shift,
        shiftBlocked: false,
        shiftErrorText,
        shiftOpen: true,
      };
    } else {
      // Если на кассе нет смены

      if (user.Lock === true) {
        return {
          shiftBlocked: true,
          shiftErrorText: "У вас уже есть открытая смена на другой кассе",
        };
      }

      // if (kkm.IdShift !== user.IdShift) {
      //   return {
      //     shiftBlocked: true,
      //     shiftErrorText: 'Смена открыта другим кассиром'
      //   };
      // }

      return {
        shift,
        shiftOpen: false,
      };
    }
  };

  // Открытие смены
  const openShift = async () => {
    setState((prevState) => ({
      ...prevState,
      pending: true,
      shiftOpening: true,
    }));

    const kkm = getKkm(history);
    const user = getUser(history);
    const ShiftIndex = kkm.ShiftIndex + 1;

    if (localStorage.getItem("OPEN_SHIFT_RECEIPT_COUNT") === null) {
      localStorage.setItem("OPEN_SHIFT_RECEIPT_COUNT", 0);
    }

    try {
      const openShiftResponse = await axios.post(
        `${BASE_URL}/shifts`,
        { IdKkm: state.kkm.Id },
        { headers }
      );
      const shift = openShiftResponse.data.Data.Shift;
      const uid = openShiftResponse.headers.uid;

      const newKkm = { ...kkm, ShiftIndex, IdShift: shift.Id, Lock: true };
      const newUser = { ...user, IdShift: shift.Id, Lock: true };

      localStorage.setItem("SHIFT", JSON.stringify(shift));
      localStorage.setItem("KKM", JSON.stringify(newKkm));
      localStorage.setItem(`Uid_${state.kkm.Id}`, uid);
      localStorage.setItem("USER", JSON.stringify(newUser));
      localStorage.setItem("OPEN_SHIFT_KKM_ID", shift.IdKkm);
      localStorage.setItem("SHIFT_INDEX", ShiftIndex);

      setState((prevState) => ({
        ...prevState,
        shift,
        kkm: newKkm,
        user: newUser,
        pending: false,
        shiftOpen: true,
        shiftOpening: false,
        uid,
      }));
    } catch (error) {
      const errorData = getError(error, history);
      setState((prevState) => ({
        ...prevState,
        pending: false,
        shiftOpening: false,
        shiftErrorText: errorData.Message,
        errorData,
      }));
    }
  };

  // Закрытие смены
  const closeShift = async () => {
    setState((prevState) => ({ ...prevState, pending: true }));
    localStorage.removeItem("OPEN_SHIFT_KKM_ID");
    localStorage.removeItem("OPEN_SHIFT_RECEIPT_COUNT");
    localStorage.removeItem("OPEN_SHIFT_BALANCES");
    history.push(`/www/kkms/${state.kkm.Id}/close_new`);
  };

  const logout = () => {
    // axios.post(`https://api.amplitude.com/2/httpapi`,
    //     {
    //         api_key: "d314c6311f60a96a3d7ec5a856b85143",
    //         events: [
    //             {
    //                 event_type: "login",
    //                 event_category: "login",
    //                 event_type: "logout success",
    //                 device_id: "10001",
    //                 user_id: "+" + "123456",
    //             }
    //         ]
    //     })

    var phone = "";
    if (localStorage.getItem("KKM_GARANT") !== null) {
      historyhistory.push("/www/garant");
      phone = window.localStorage.getItem("phone_login");
      window.localStorage.clear();
      window.localStorage.setItem("phone_login", phone);
    } else if (localStorage.getItem("KKM_LOMBARD") !== null) {
      historyhistory.push("/www/lombard");
      phone = window.localStorage.getItem("phone_login");
      window.localStorage.clear();
      window.localStorage.setItem("phone_login", phone);
    } else {
      historyhistory.push("/");
      phone = window.localStorage.getItem("phone_login");
      window.localStorage.clear();
      window.localStorage.setItem("phone_login", phone);
    }
  };

  const openModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalWarning: true,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalWarning: false,
    }));
  };

  useEffect(() => {
    const initialize = async () => {
      headers = getHeaders(history);
      const idKkm = JSON.parse(localStorage.getItem("CURRENT_KKM_ID"));
      const user = getUser(history);

      try {
        const updatedUserResponse = axios.get(`${BASE_URL}/users/${user.Id}`, {
          headers,
        });
        const kkmResponse = axios.get(`${BASE_URL}/kkms/${idKkm}`, { headers });
        const balancesResponse = axios.get(
          `${BASE_URL}/kkms/${idKkm}/balances`,
          { headers }
        );
        let chartsResponse = null;
        const dashboard_map = new Map(
          JSON.parse(localStorage.getItem("DASHBOARD_MAP"))
        );
        if (dashboard_map.has(idKkm)) {
          chartsResponse = new Promise((res) =>
            res({
              data: {
                Data: dashboard_map.get(idKkm),
              },
            })
          );
        } else {
          chartsResponse = axios.get(`${BASE_URL}/kkms/${idKkm}/chartsdata`, {
            headers,
          });
        }
        await Promise.all([
          updatedUserResponse,
          kkmResponse,
          balancesResponse,
          chartsResponse,
        ])
          .then(
            ([
              updatedUserResponse,
              kkmResponse,
              balancesResponse,
              chartsResponse,
            ]) => {
              const updatedUser = updatedUserResponse.data.Data;
              localStorage.setItem(`USER`, JSON.stringify(updatedUser));
              const kkm = kkmResponse.data.Data.Kkm;
              localStorage.setItem(`KKM`, JSON.stringify(kkm));
              localStorage.setItem(
                "SHIFT_INDEX",
                kkmResponse.data.Data.Kkm.ShiftIndex
              );
              const balances = balancesResponse.data.Data.Balances;
              localStorage.setItem(`BALANCES`, JSON.stringify(balances));

              if (localStorage.getItem("OPEN_SHIFT_BALANCES") === null) {
                localStorage.setItem(
                  `OPEN_SHIFT_BALANCES`,
                  JSON.stringify(balances)
                );
              }

              let dashboardData = chartsResponse.data.Data;
              localStorage.setItem(`DASHBOARD`, JSON.stringify(dashboardData));
              if (!dashboard_map.has(idKkm)) {
                dashboard_map.set(idKkm, dashboardData);
                localStorage.setItem(
                  "DASHBOARD_MAP",
                  JSON.stringify(Array.from(dashboard_map.entries()))
                );
              }

              setState((prevState) => ({
                ...prevState,
                balances,
                user,
                kkm,
                dashboard: dashboardData,
                error403: false,
              }));
            }
          )
          .catch((err) => {
            setState({
              ...state,
              error403: true,
            });
          });
      } catch (e) {
        const errorData = getError(e, history);
        setState({
          ...state,
          connection: false,
          connectionErrorText: errorData.Message,
          errorData: errorData,
          user,
        });
        throw e;
      }
    };

    initialize();
  }, [history]); // Запускаем эффект только при изменении истории

  useEffect(() => {
    if (state.kkm && state.user) {
      checkShift(state).then((res) => {
        setState({
          ...state,
          ...res,
          mounted: true,
        });
      });
    }
  }, [state.kkm, state.user]);

  // Обновление дашборда
  const updataDashboard = async () => {
    const idKkm = JSON.parse(localStorage.getItem("CURRENT_KKM_ID"));
    const chartResponse = await axios.get(
      `${BASE_URL}/kkms/${idKkm}/chartsdata`,
      { headers }
    );
    const dashboardData = chartResponse.data.Data;
    localStorage.setItem("DASHBOARD", JSON.stringify(dashboardData));
    setState((prevState) => ({ ...prevState, dashboard: dashboardData }));
  };

  return (
    <div style={{ background: "#F5F5F5", height: "100vh", overflowY: "scroll" }}>
      {state.errorData && state.errorData.Status === 503 && <Error503Modal />}
      <HeaderVersion
        isActive={state.shiftOpen}
        errorText={state.shiftErrorText}
        {...props}
        shiftIsOpen={state.shiftOpen}
      />
      <MobileNav isActive={state.shiftOpen} />
      {state.mounted &&
        !state.error403 &&
        (window.matchMedia("(min-width: 1100px)").matches ? (
          <Shift
            {...state}
            openShift={openShift}
            updataDashboard={updataDashboard}
            openModal={openModal}
            history={history}
          />
        ) : (
          <MobileShift
            {...state}
            openShift={openShift}
            updataDashboard={updataDashboard}
            openModal={openModal}
            history={history}
          />
        ))}
      {state.error403 &&
        (window.matchMedia("(min-width: 1100px)").matches ? (
          <ErrorState history={history} />
        ) : (
          <MobileErrorState history={history} />
        ))}
      <Modal
        isOpen={state.modalWarning}
        closeModal={closeModal}
        closeShift={closeShift}
        logout={logout}
        pending={state.pending}
        balances={state.balances}
      />
    </div>
  );
};

export default ShiftContainer;
