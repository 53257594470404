import React, { Component } from 'react';
import PreCheckPosition from "./precheckPosition/PreCheckPosition";
import Total from "../total/Total";
// import style from "./Prechek.module.css"
// import Card from "../../../selectCashbox/SelectCashbox";
import { Store } from "../../../../Store/OperationProvider"
// import { ReactComponent as QRButton } from "../../../Svg/qr-code.svg?react";
import ModalQRCheck from "../../../Modal/qrCodeModalPrecheck";
import Spinner from '../../../Common/Spinner';


let localkkm = localStorage.getItem("KKM") === 'undefined' ? '[]' : localStorage.getItem("KKM")
const kkm = JSON.parse(localkkm)

export default class Precheck extends Component {
    render() {
        const cid = JSON.parse(localStorage.getItem("C_ID"));
        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Предчек</span>
                                {context.PreCheck.length === 0 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиций</span>}
                                {context.PreCheck.length === 1 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиция</span>}
                                {context.PreCheck.length > 1 && context.PreCheck.length < 5 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиции</span>}
                                {context.PreCheck.length > 4 && <span className="badge badge-primary badge-pill">{context.PreCheck.length} позиций</span>}
                            </h4>

                            <ul className="list-group mb-3 text-monospace">
                                {context.PreCheck.map((check, index) =>
                                    <PreCheckPosition
                                        key={index}
                                        index={index}
                                        Name={check.Name}
                                        Price={check.Price}
                                        Markup={check.Markup}
                                        MarkupPercent={check.MarkupPercent}
                                        Discount={check.Discount}
                                        DiscountPercent={check.DiscountPercent}
                                        Qty={check.Qty}
                                        Section={check.Section}
                                        Unit={check.Unit}
                                        Storno={check.Storno}
                                        Total={check.Total}
                                        Nds={check.Nds}
                                    />
                                )}
                                <Total {...context}
                                    cash={context.Income.Cash}
                                    nonCash={context.Income.NonCash}
                                    change={context.Change}
                                    final={context.Total}
                                />
                                    {/* <button className="QR_button" onClick={() => context.showQRPrecheck()}>
                                        <QRButton style={{marginRight: "13px"}}/>
                                        Оплатить в Plus24
                                    </button> */}
                                    <button
                                        className="btn btn-outline-secondary btn-lg btn-block mb-3 mb-md-0"
                                        style={{marginTop: "20px", letterSpacing: "-0.5px", textTransform: "uppercase"}}
                                        disabled={!context.IsSaleButtonEnabled}
                                        onClick={() => context.resetPreCheck()}
                                    >
                                        Сбросить <span className="d-md-none d-lg-inline">предчек</span>
                                        {
                                            !context.IsSaleButtonEnabled &&
                                            <Spinner />
                                        }
                                    </button>
                            </ul>
                            <ModalQRCheck onClose={context.closeModalQRCheck} onSuccess={context.closeModalOnSuccess} onCheck={context.showQRPrecheck} show={context.ModalQRCheck.Status} companyName={kkm !== null && kkm.Company.FullName} bin={kkm !== null && kkm.Company.Bin} total={context.Total} cid={cid} />
                        </React.Fragment>
                    )
                }
            </Store.Consumer>

        )
    }
}
