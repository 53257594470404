/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Store } from '../../../../Store/OperationProvider';
import AddPosition from './AddPosition'

export default class AddPositionContainer extends Component {
    handleInputText = (e, functionHandler, val) => {
        functionHandler({ value: e.target.value, name: val });
    };

    handleInputDiscountMarkup = (e, functionHandler, val) => {
        if (e.target.value === Number(e.target.value)) {
            let qty = Number(e.target.value);
            functionHandler({ value: qty, name: `${val}Percent`, val });
        }
    };

    handleInputNumber = (e, functionHandler, val) => {
      if(e.target.value.slice(-4, -3) !== "."){
        functionHandler({ value: e.target.value, name: val });
      }
    };

    handleInputExciseCode = (e, functionHandler, val) => {
            functionHandler({value: e.target.value, name: val});
    }

    handleInputQty = (e, functionHandler, val) => {
        if (e.target.value === Number(e.target.value)) {
            let qty = Number(e.target.value);
            if (e.target.value < 1) {
                qty = 0;
            }
            functionHandler({ value: qty, name: val });
        }
    };

    handleFocus = (event) => event.target.select();

    hidePlaceholderOnFocus = (event) => {
        event.target.placeholder = '';
    }



    render() {
        return (
            <Store.Consumer>
                {
                    context => (
                        <AddPosition {...context}
                            handleInputText={this.handleInputText}
                            handleInputDiscountMarkup={this.handleInputDiscountMarkup}
                            handleInputNumber={this.handleInputNumber}
                            handleInputQty={this.handleInputQty}
                            handleFocus={this.handleFocus}
                            hidePlaceholderOnFocus={this.hidePlaceholderOnFocus}
                            handleInputExciseCode={this.handleInputExciseCode}
                        />
                    )
                }

            </Store.Consumer>
        )
    }
}
