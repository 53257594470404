import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Logo from "../Svg/white-logo.svg?react";
import { useTranslation } from "react-i18next";

const BarcodeScanner = ({ setCode, setShowScanner, showScanner }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={true && showScanner}
      centered
      onHide={() => setShowScanner(false)}
      className="modal-article-barcode"
    >
      <ModalBody>
        <div className="video-cover">
          <p className="title">{t("receipt.scan_barcode")}</p>
          <div className="scanner-hole">
            <BarcodeScannerComponent
              onUpdate={(err, result) => {
                if (result) {
                  setCode(result.text);
                  setShowScanner(false);
                }
              }}
            />
          </div>
          <Logo />
          <p className="cancel" onClick={() => setShowScanner(false)}>
            {t("receipt.cancel")}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BarcodeScanner;
