import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import Logo from '../Svg/logo.svg?react'
import Exclamation from '../Svg/exclamation.svg?react'
import Stop from '../Svg/stop.svg?react'
import { setAppVersion } from '../../Store/appVersion'
import { getAppModuleUrl } from '../../Store/appVersion'
import NewDesignBtn from './NewDesignBtn'
import { WEBTERMINAL_DEV_URL, WEBTERMINAL_PROD_URL } from '../../constants/app'

const HeaderLayout = ({
	isActive,
	balances: Balance,
	user: User,
	kkm: KKM,
	errorText,
	openModal,
	logout,
	paymentsLink,
}) => {
	const KKmId = KKM.Id
	const uid = localStorage.getItem(`Uid_${KKmId}`)
	const history = useHistory()
	const location = useLocation()

	const setNewDesign = () => {
		setAppVersion(true)
		const _location = location.pathname
		const locationArr = _location.split('/')

		if (_location.includes('log')) {
			history.push(_location.replace('log', getAppModuleUrl().reports.operations))
		} else if (
			locationArr[0] === '' &&
			locationArr[1] === 'www' &&
			locationArr[2] === 'kkms' &&
			!isNaN(locationArr[3]) &&
			(locationArr[4] === '' || locationArr[4] === undefined) &&
			(locationArr.length === 5 || locationArr.length === 4)
		) {
			history.push(_location + (locationArr.length === 4 ? '/shift_new' : 'shift_new'))
		} else if (_location.includes('forbiden')) {
			history.push(_location + '_new_default')
		} else if (_location === '/www/comps') {
			history.push('/www/comps_new')
		} else if (!_location.includes('_new')) {
			history.push(_location + '_new')
		}
	}

	return (
		<header className='header d-none d-lg-block'>
			<div className='container' style={{ position: 'relative' }}>
				<NewDesignBtn setNewDesign={setNewDesign} />
				<div className='row'>
					<div
						className={`col-sm d-flex ${!isActive && 'justify-content-between'} align-items-center`}
					>
						{/* <!-- Лого --> */}
						<div className='header__logo'>
							{isActive ? (
								<Link to='/www/comps'>
									<Logo />
								</Link>
							) : (
								<Link to='/www/comps'>
									<Logo />
								</Link>
							)}
						</div>
						{/* <!-- Операции --> */}
						{
							isActive && (
								<React.Fragment>
									<div className='header__operations-list'>
										<div className='btn-group'>
											<div className='btn btn-primary'>Новая продажа</div>
											<div className='btn btn-primary dropdown-toggle'></div>
											<div className='dropdown-menu'>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/sale`}>
													Продажа
												</Link>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/purchase`}>
													Покупка
												</Link>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/income`}>
													Приход
												</Link>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/expense`}>
													Расход и инкассация
												</Link>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/refund`}>
													Возврат продажи
												</Link>
												<Link className='dropdown-item' to={`/www/kkms/${KKM.Id}/purchase_refund`}>
													Возврат покупки
												</Link>
											</div>
										</div>
									</div>

									{/* <!-- Конец Операций --> */}

									{/* <!-- Информация о смене --> */}
									<div className='header__operation-info mr-auto ml-auto'>
										<div className='btn-group'>
											<div className='input-group-text'>
												{Balance && Balance.map((bal) => {
													if (bal.TypeBalance.Id === 1) {
														return (
															<div className='mr-3' key={bal.TypeBalance.Id}>
																<span className='svg-handler mr-2'>
																	<svg
																		width='20'
																		height='19'
																		fill='none'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<path d='M16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' fill='#46425C' />
																		<path
																			fillRule='evenodd'
																			clipRule='evenodd'
																			d='M3 5h14a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm-.707 2.293A1 1 0 0 0 2 8v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H3a1 1 0 0 0-.707.293z'
																			fill='#46425C'
																		/>
																		<path
																			d='M15.055 2.245a3 3 0 0 0-4.133-.954L4.984 5l1.06 1.696 5.936-3.71a1 1 0 0 1 1.378.319l2.12 3.392 1.696-1.06-2.12-3.392zM16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'
																			fill='#46425C'
																		/>
																	</svg>
																</span>
																{new Intl.NumberFormat('ru-RU').format(bal.Amount)} ₸
															</div>
														)
													}
													if (bal.TypeBalance.Id === 2) {
														return (
															<div key={bal.TypeBalance.Id}>
																<span className='svg-handler mr-2'>
																	<svg
																		width='20'
																		height='14'
																		fill='none'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<path
																			d='M17 0H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zM2 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1H2V3zm16 8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6h16v5z'
																			fill='#46425C'
																		/>
																		<path
																			d='M5 10h4a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2zM13 10h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2z'
																			fill='#46425C'
																		/>
																	</svg>
																</span>
																{new Intl.NumberFormat('ru-RU').format(bal.Amount)} ₸
															</div>
														)
													}
													return null
												})}
											</div>
											<div
												className={`btn ${
													!errorText ? 'btn-primary' : 'btn-danger'
												} dropdown-toggle`}
											>
												{errorText ? (
													<React.Fragment>
														<span className='svg-handler mr-1'>
															<Stop />
														</span>
														Смена
													</React.Fragment>
												) : (
													<React.Fragment>
														<span className='svg-handler mr-1'>
															<svg
																width='17'
																height='13'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M5.86 13a1 1 0 0 1-.73-.32L.27 7.51a1.001 1.001 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33h-.01z'
																	fill='#fff'
																/>
															</svg>
														</span>
														Смена
													</React.Fragment>
												)}
											</div>
											<div className='dropdown-menu'>
												<div className='dropdown-item'>
													<div
														className={`operation-info__block ${
															errorText && 'operation-error__block'
														}`}
													>
														Информация о смене
													</div>
												</div>
												{errorText && (
													<div className='dropdown-item'>
														<div className='operation-info__block operation-error__block'>
															<h5>
																<span className='svg-handler mr-1'>
																	<Exclamation />
																</span>
																Внимание
															</h5>
															<p>{errorText}</p>
														</div>
													</div>
												)}
												<div className='dropdown-item pt-1 pb-1'>
													{Balance && Balance.map((bal) => {
														if (bal.TypeBalance.Id === 1) {
															return (
																<div className='operation-info__block' key={bal.TypeBalance.Id}>
																	<div className='cash-title'>
																		<span className='svg-handler'>
																			<svg
																				width='20'
																				height='19'
																				fill='none'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<path
																					d='M16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'
																					fill='#46425C'
																				/>
																				<path
																					fillRule='evenodd'
																					clipRule='evenodd'
																					d='M3 5h14a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm-.707 2.293A1 1 0 0 0 2 8v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H3a1 1 0 0 0-.707.293z'
																					fill='#FBFAFF'
																				/>
																				<path
																					d='M15.055 2.245a3 3 0 0 0-4.133-.954L4.984 5l1.06 1.696 5.936-3.71a1 1 0 0 1 1.378.319l2.12 3.392 1.696-1.06-2.12-3.392zM16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'
																					fill='#FBFAFF'
																				/>
																			</svg>
																		</span>{' '}
																		Наличными
																	</div>
																	<div className='cash-number'>
																		{new Intl.NumberFormat('ru-RU').format(bal.Amount)} ₸{' '}
																	</div>
																</div>
															)
														}
														if (bal.TypeBalance.Id === 2) {
															return (
																<div className='operation-info__block' key={bal.TypeBalance.Id}>
																	<div className='cash-title'>
																		<span className='svg-handler'>
																			<svg
																				width='20'
																				height='14'
																				fill='none'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<path
																					d='M17 0H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zM2 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1H2V3zm16 8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6h16v5z'
																					fill='#FBFAFF'
																				/>
																				<path
																					d='M5 10h4a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2zM13 10h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2z'
																					fill='#FBFAFF'
																				/>
																			</svg>
																		</span>{' '}
																		Безналичными
																	</div>
																	<div className='cash-number'>
																		{new Intl.NumberFormat('ru-RU').format(bal.Amount)} ₸{' '}
																	</div>
																</div>
															)
														}
														return null
													})}
												</div>
												<div className='dropdown-item'>
													<Link
														className='btn btn-counter btn-block'
														to={`/www/shifts/${KKM.Id}/log`}
													>
														<div>Журнал операций</div>
														<svg
															width='5'
															height='8'
															viewBox='0 0 5 8'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M4.33329 4C4.3338 3.91226 4.31698 3.82529 4.28381 3.74406C4.25063 3.66284 4.20175 3.58896 4.13996 3.52667L1.47329 0.860002C1.41113 0.797843 1.33734 0.748536 1.25613 0.714895C1.17491 0.681255 1.08787 0.66394 0.999961 0.66394C0.912054 0.66394 0.825009 0.681255 0.743794 0.714895C0.662579 0.748535 0.588786 0.797843 0.526627 0.860002C0.464468 0.922161 0.415161 0.995954 0.381521 1.07717C0.34788 1.15838 0.330566 1.24543 0.330566 1.33333C0.330566 1.42124 0.34788 1.50829 0.381521 1.5895C0.415161 1.67072 0.464468 1.74451 0.526627 1.80667L2.73329 4L0.613294 6.2C0.5451 6.26133 0.490295 6.33607 0.452304 6.41954C0.414313 6.50302 0.393955 6.59343 0.392502 6.68514C0.391049 6.77684 0.408533 6.86785 0.44386 6.95249C0.479187 7.03713 0.531599 7.11356 0.597815 7.17702C0.664031 7.24048 0.742628 7.28959 0.828691 7.32129C0.914754 7.35298 1.00643 7.36658 1.09799 7.36123C1.18955 7.35588 1.27901 7.33169 1.3608 7.29018C1.44258 7.24868 1.51492 7.19074 1.57329 7.12L4.14663 4.45333C4.26417 4.33142 4.33091 4.16934 4.33329 4Z'
																fill='#FBFAFF'
															/>
														</svg>
													</Link>
												</div>
												<div className='dropdown-item'>
													<div className='operation-info__block justify-content-start'>
														<Link to={`/www/shifts/${KKM.Id}/reports/x`}>X-отчет</Link>
														<Link to={`/www/kkms/${KKM.Id}/zlog`}>Z-отчеты</Link>
														<Link to={`/www/kkms/${KKM.Id}/reports/sections`}>По секциям</Link>
													</div>
													{!errorText ? (
														<div className='operation-info__block'>
															{User.Lock ? (
																<button className='btn btn-danger btn-block' onClick={openModal}>
																	Закрыть смену
																</button>
															) : (
																<Link
																	to={`/www/kkms/${KKM.Id}`}
																	className='btn btn-primary btn-block'
																>
																	Перейти на кассу и открыть смену
																</Link>
															)}
														</div>
													) : (
														<div className='operation-info__block'>
															<Link
																to='/www/comps'
																title='Сменить кассу'
																className='btn btn-dark btn-block'
															>
																Сменить кассу
															</Link>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)
							// {/* <!-- Конец информации о смене --> */}
						}
						{/* <!-- Переключиться на платежи --> */}
						<div className={`${!isActive && 'ml-auto'} header__operation-switch`}>
							<div className='btn-group'>
								<div className='btn btn-primary'>Продажи</div>
								{KKmId !== undefined ? (
									<a
										href={
											window.location.hostname === 'fiscal2.kassa24.kz'
												? `${WEBTERMINAL_PROD_URL}/fiscal?uid=${uid}&kkmid=${KKmId}`
												: `${WEBTERMINAL_DEV_URL}/fiscal?uid=${uid}&kkmid=${KKmId}`
										}
										className='btn btn-secondary'
									>
										Платежи
									</a>
								) : (
									<a
										href={
											window.location.hostname === 'fiscal2.kassa24.kz'
												? `${WEBTERMINAL_PROD_URL}`
												: `${WEBTERMINAL_DEV_URL}`
										}
										className='btn btn-secondary'
									>
										Платежи
									</a>
								)}
							</div>
						</div>
						{/* <!-- Конец переключения на платежи --> */}

						{/* <!-- Информация о пользователе --> */}
						{isActive ? (
							<div className='header__current-user d-flex align-items-center dropdown-toggle'>
								<div className='current-user__data'>
									{/* <!--<div className="current-user__company">{ТУТ БУДЕТ ИМЯ КОМПАНИИ }</div> --> */}
									<div className='current-user__kkm'>Касса №{KKmId}</div>
								</div>
								<div className='dropdown-menu'>
									<div className='dropdown-item'>
										<div className='operation-info__block'>{User.Name}</div>
									</div>
									<div className='dropdown-item dropdown-item__company'>
										<Link to='/www/comps' className='btn btn-counter btn-block'>
											<div>{KKM.Company.ShortName}:</div>

											<svg
												width='5'
												height='8'
												viewBox='0 0 5 8'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M4.33329 4C4.3338 3.91226 4.31698 3.82529 4.28381 3.74406C4.25063 3.66284 4.20175 3.58896 4.13996 3.52667L1.47329 0.860002C1.41113 0.797843 1.33734 0.748536 1.25613 0.714895C1.17491 0.681255 1.08787 0.66394 0.999961 0.66394C0.912054 0.66394 0.825009 0.681255 0.743794 0.714895C0.662579 0.748535 0.588786 0.797843 0.526627 0.860002C0.464468 0.922161 0.415161 0.995954 0.381521 1.07717C0.34788 1.15838 0.330566 1.24543 0.330566 1.33333C0.330566 1.42124 0.34788 1.50829 0.381521 1.5895C0.415161 1.67072 0.464468 1.74451 0.526627 1.80667L2.73329 4L0.613294 6.2C0.5451 6.26133 0.490295 6.33607 0.452304 6.41954C0.414313 6.50302 0.393955 6.59343 0.392502 6.68514C0.391049 6.77684 0.408533 6.86785 0.44386 6.95249C0.479187 7.03713 0.531599 7.11356 0.597815 7.17702C0.664031 7.24048 0.742628 7.28959 0.828691 7.32129C0.914754 7.35298 1.00643 7.36658 1.09799 7.36123C1.18955 7.35588 1.27901 7.33169 1.3608 7.29018C1.44258 7.24868 1.51492 7.19074 1.57329 7.12L4.14663 4.45333C4.26417 4.33142 4.33091 4.16934 4.33329 4Z'
													fill='#FBFAFF'
												/>
											</svg>
										</Link>
									</div>
									<div className='dropdown-item dropdown-item__cashbox'>
										<Link to='/www/comps' className='btn btn-counter btn-block'>
											<div>Касса №{KKmId}</div>
											<svg
												width='5'
												height='8'
												viewBox='0 0 5 8'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M4.33329 4C4.3338 3.91226 4.31698 3.82529 4.28381 3.74406C4.25063 3.66284 4.20175 3.58896 4.13996 3.52667L1.47329 0.860002C1.41113 0.797843 1.33734 0.748536 1.25613 0.714895C1.17491 0.681255 1.08787 0.66394 0.999961 0.66394C0.912054 0.66394 0.825009 0.681255 0.743794 0.714895C0.662579 0.748535 0.588786 0.797843 0.526627 0.860002C0.464468 0.922161 0.415161 0.995954 0.381521 1.07717C0.34788 1.15838 0.330566 1.24543 0.330566 1.33333C0.330566 1.42124 0.34788 1.50829 0.381521 1.5895C0.415161 1.67072 0.464468 1.74451 0.526627 1.80667L2.73329 4L0.613294 6.2C0.5451 6.26133 0.490295 6.33607 0.452304 6.41954C0.414313 6.50302 0.393955 6.59343 0.392502 6.68514C0.391049 6.77684 0.408533 6.86785 0.44386 6.95249C0.479187 7.03713 0.531599 7.11356 0.597815 7.17702C0.664031 7.24048 0.742628 7.28959 0.828691 7.32129C0.914754 7.35298 1.00643 7.36658 1.09799 7.36123C1.18955 7.35588 1.27901 7.33169 1.3608 7.29018C1.44258 7.24868 1.51492 7.19074 1.57329 7.12L4.14663 4.45333C4.26417 4.33142 4.33091 4.16934 4.33329 4Z'
													fill='#FBFAFF'
												/>
											</svg>
										</Link>
									</div>
									<div className='dropdown-item pt-1 pb-1'>
										<div className='operation-info__block mb-2'>
											<div className='operation-info__block-links'>
												<a href={`/www/kkms/${KKmId}/settings`}>Настройки</a>
												<Link to={`/www/kkms/${KKmId}/zlog`}>Архив Z-отчетов</Link>
											</div>
										</div>
										<div className='operation-info__block'>
											<Link
												to={
													localStorage.getItem('KKM_GARANT') !== null
														? '/www/garant'
														: localStorage.getItem('KKM_LOMBARD') !== null
														? '/www/lombard'
														: '/'
												}
												title='Выход'
												onClick={logout}
												className='btn btn-dark btn-block'
											>
												Выйти из аккаунта
											</Link>
										</div>
									</div>
								</div>
							</div>
						) : (
							<React.Fragment>
								<div className='header__current-user d-flex align-items-center'>
									<div className='mr-3'>{User.Name}</div>
									<Link
										to={
											localStorage.getItem('KKM_GARANT') !== null
												? '/www/garant'
												: localStorage.getItem('KKM_LOMBARD') !== null
												? '/www/lombard'
												: '/'
										}
										onClick={logout}
									>
										Выход
									</Link>
								</div>
							</React.Fragment>
						)}
					</div>
					{/* <!-- Конец колонки --> */}
				</div>
				{/* <!-- Конец Row --> */}
			</div>
			{/* <!-- Конец контейнера --> */}
		</header>
	)
}
export default HeaderLayout
