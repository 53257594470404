import React, { useState, useEffect } from "react"
import { Store } from "../../../../Store/OperationProvider";
// import Header from '../../../Header/Header';
import MobileNav from '../../../MobileNav/MobileNav';
import Title from "../modules/Title"
import SaleContainer from "./SaleContainer";
import SaleSimpleContainer from "./SaleSimpleContainer";
// import ArrowRight from "../images/arrowRight.svg?react";
// import versionStyle from "../../styles/oldVersionBtn.module.scss"
// import {setAppVersion} from "../../../../Store/appVersion";
import style from "../../styles/pages/operation.module.scss"
import HeaderVersion from "../../HeaderVersion/HeaderVersion";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";

const SaleOperation = (props) => {
  const { t } = useTranslation()

  const [formType, setSimpleForm] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const simpleForm = JSON.parse(localStorage.getItem('simpleForm'))
    setSimpleForm(simpleForm)
  }, [formType])

  const toggleForm = () => {
    localStorage.setItem('simpleForm', JSON.stringify(!formType))
    setSimpleForm(!formType)
  }

  // const goOld = () => {
  //   props.history.push('sale')
  //   setAppVersion(false)
  // }

  return (
    <Store.Consumer>
      {
        context => (
          <React.Fragment>
            {
              window.matchMedia("(min-width: 1100px)").matches ? (
                <HeaderVersion isActive={true} balances={context.Balances} {...props} />
              ) : (
                <MobileNav isActive={true} />
              )
            }
            <div className={`${style.main} ${style[`main--${Capacitor.getPlatform()}`]}`}>
              {/* <div className="container">
                <div className={versionStyle.oldVersion} onClick={() => goOld()}>
                  <p className={versionStyle.oldVersionText}>Старый вид<br />сайта</p>
                  <ArrowRight />
                </div>
              </div> */}
              <div className={style.container}>
                <Title
                  context={context}
                  title={ t('receipt.new_sale') }
                  toggle={true}
                  history={props.history}
                  showPopup={showPopup}
                  setShowPopup={setShowPopup}
                  formType={formType}
                  toggleForm={() => toggleForm()}
                />
                {
                  formType ? (
                    <SaleSimpleContainer context={context} history={props.history} />
                  ) : (
                    <SaleContainer context={context} history={props.history} />
                  )
                }
              </div>
            </div>
          </React.Fragment>
        )
      }
    </Store.Consumer>
  )
}

export default SaleOperation;
