import React, {useState} from "react";
import style from "../../styles/pages/report/duplicates/NewReportZDuplicateSections.module.scss";
import stylePdf from "../../styles/pages/report/duplicates/NewReportZDuplicateTop.module.scss";
import ArrowDownSLine from "../../svg/arrow-down-s-line.svg?react";
import ArrowUpSLine from "../../svg/arrow-up-s-line.svg?react";
import {formatAmount} from "../../../../Store/snippets";
import { useTranslation } from "react-i18next";

const NewReportZDuplicateSections = ({ reportZChoosen }) => {
	const { t } = useTranslation()

	const [reportZSelectedSectionIndex, setReportZSelectedSectionIndex] = useState(0)
	const [reportZsectionMobileIsCollapsed, setReportZsectionMobileIsCollapsed] = useState(false)

	const checkSectionReportIsAvailable = (sections) => {
		for (let section in sections) {
			for (let item in sections[section]) {
				if (sections[section][item]) {
					return true
				}
			}
		}
		return false
	}


	const reportZSectionRenderList = Object.keys(reportZChoosen.Sections)
		.filter((reportZSection) => 
			reportZChoosen.Sections[reportZSection].Sales || 
			reportZChoosen.Sections[reportZSection].Purchases || 
			reportZChoosen.Sections[reportZSection].Refunds || 
			reportZChoosen.Sections[reportZSection].PurchaseRefunds
		)
		.map((reportZSection, index) => (
			<div key={index} className={`${style['table__list']} ${reportZsectionMobileIsCollapsed && (reportZSelectedSectionIndex === index) && style.collapsed} ${stylePdf['pdf-collapsed']}`}>
				<div
					className={`${style['table__item-group']} ${stylePdf['pdf-collapsed-item']}`}
					onClick={() => {
						setReportZSelectedSectionIndex(index)
						setReportZsectionMobileIsCollapsed(!reportZsectionMobileIsCollapsed)
					} }
				>
					<div className={style['table__item']}>Секция: {`${reportZSection} ${reportZChoosen.Sections[reportZSection].Name}`}</div>
					<button className={`${style.btn} ${style['btn--icon']}`}>
						<ArrowDownSLine/>
					</button>
					<button className={`${style.btn} ${style['btn--icon']}`}>
						<ArrowUpSLine/>
					</button>
				</div>
				<div className={`${style['table__item-group']} ${stylePdf['pdf-collapsed-item']}`}>
					<div className={style['table__item']}>{ t('close_shift.sales') }</div>
					<div className={style['table__item']}>
						{formatAmount(reportZChoosen.Sections[reportZSection].Sales)} ₸
					</div>
				</div>
				<div className={`${style['table__item-group']} ${stylePdf['pdf-collapsed-item']}`}>
					<div className={style['table__item']}>{ t('close_shift.sales_return') }</div>
					<div className={style['table__item']}>
						{formatAmount(reportZChoosen.Sections[reportZSection].Refunds)} ₸
					</div>
				</div>
				<div className={`${style['table__item-group']} ${stylePdf['pdf-collapsed-item']}`}>
					<div className={style['table__item']}>{ t('close_shift.purchases') }</div>
					<div className={style['table__item']}>
						{formatAmount(reportZChoosen.Sections[reportZSection].Purchases)} ₸
					</div>
				</div>
				<div className={`${style['table__item-group']} ${stylePdf['pdf-collapsed-item']}`}>
					<div className={style['table__item']}>{ t('close_shift.purchase_refunds') }</div>
					<div className={style['table__item']}>
						{formatAmount(reportZChoosen.Sections[reportZSection].PurchaseRefunds)} ₸
					</div>
				</div>
			</div>
		))

	return (
		<React.Fragment>
			<div className={`${style.card} ${style['card--white-nopadding']} ${style['ml-20']}`}>
				<div className={style.table}>
					<div className={style['table__content']}>
						<div
							className={style['table__header']}
						>
							<div>{ t('close_shift.section_report') }</div>
						</div>
						<div className={style['table__body']}>
							{
								checkSectionReportIsAvailable(reportZChoosen.Sections)
									?
									reportZSectionRenderList
									:
									<div className={style['table__list']}>
										<div className={style['table__item']} style={{'fontWeight': 'normal'}}>{ t('close_shift.no_operations') }</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicateSections
