import React from "react"
import s from './cashboxcomponent.module.scss'
import { SpinnerPrimary } from '../../../Common/Spinner';
import { Danger, OpenShift, Success, Warning, Copied } from "../../../Icons";
import { useTranslation } from "react-i18next";

const DAYS_THRESHOLD_SHORT = 3;
const DAYS_THRESHOLD_MIN = 0;
const DATE_PADDING_THRESHOLD = 10;
const COPIED_ICON_SIZE = 12;
const MAX_NAME_LENGTH = 22;

const CashboxComponent = React.memo(({ kkm, openKkm, copied, copyZnm, znmForCopy, pending, idx, id }) => {
	const { t } = useTranslation()

	const getKKmLabelText = (text) => {
		switch (text) {
			case 'Касса в процессе регистрации':
				return t('other.registration_in_progress')
			case 'Касса заблокирована в ОФД и в K24B':
				return t('other.cash_register_blocked_in_ofd_kassa')
			case 'Касса заблокирована в ОФД':
				return t('other.cash_register_blocked_in_ofd')
			case 'Касса заблокирована в K24B':
				return t('other.k24b_blocked')
			case 'Касса доступна':
				return t('other.cash_register_available')
			case 'Смена открыта другим кассиром':
				return t('other.shift_opened_by_another_cashier')
			case 'Ваша смена':
				return t('other.your_shift')
			default:
				return text
		}
	}

	let expireText;
	if (!pending) {
    const { ExpirationDate, ExpirationDiffDays: diffDays } = kkm;
    const now = new Date();

    if (!ExpirationDate || ExpirationDate <= now || diffDays == null) {
        expireText = t('main.payment_required');
    } else if (diffDays <= DAYS_THRESHOLD_SHORT && diffDays > DAYS_THRESHOLD_MIN) {
        expireText = t('main.days_left', { count: diffDays });
    } else if (diffDays > DAYS_THRESHOLD_SHORT) {
        const year = ExpirationDate.getFullYear();
        let month = ExpirationDate.getMonth() + 1;
        let day = ExpirationDate.getDate();

        if (month < DATE_PADDING_THRESHOLD) month = `0${month}`;
        if (day < DATE_PADDING_THRESHOLD) day = `0${day}`;

        expireText = t('main.paid_until_date', { date: `${day}.${month}.${year}` });
    } else {
        expireText = t('main.payment_required');
    }
	}


	return (
		<div className={`${s.wrapper} ${kkm.LabelType === 'success' && s.wrapper_opened}`} onClick={openKkm} data-idx={idx} id={id}>
			<section className={s.cashbox_container}>
				<div className={s.cashbox_name}>
					<div className={s.znm}>
						{((znmForCopy !== kkm.Znm) || !copied) &&
							<div className={s.znm_container} onClick={(e) => copyZnm(e, kkm.Znm)}>
								<span className={s.znm_title}>ЗНМ</span>
								{kkm.Znm}
							</div>
						}
						{(znmForCopy === kkm.Znm) && copied &&
							<span className={s.copied} onClick={e => e.stopPropagation()}>
								<Copied size={COPIED_ICON_SIZE} />&nbsp;{t('main.copied')}
							</span>
						}
					</div>
					<h5
						data-idx={idx}
						id={kkm.Id}
						className={kkm.LabelType === 'success' ? (
							kkm.Name && (kkm.Name.length) < MAX_NAME_LENGTH ? s.cashbox_name_exactGreen : `${s.cashbox_name_exactGreen} + ${s.overflow}`
						) : (
							kkm.Name && (kkm.Name.length) < MAX_NAME_LENGTH ? s.cashbox_name_exact : `${s.cashbox_name_exact} + ${s.overflow}`
						)
					}
					>
						{kkm.Name}
					</h5>
				</div>

				<span className={`${s.address} ${kkm.LabelType === 'success' && s.address_opened}`}>
					{`${kkm.Address.Town.Name}, ${kkm.Address.Street}, ${kkm.Address.House}, ${kkm.Address.Flat} `}
				</span>

			</section>

			<section className={`${s.cashbox_container} ${s.cashbox_container_right}`}>
				<p className={`${s.status} ${kkm.LabelType === 'success' && s.status_opened}`}>
					<i className={s.status_logo}>
						{kkm.LabelType === 'success' ? <OpenShift fill="#FFFFFF" /> :
							kkm.LabelType === 'warning' ? <Warning /> :
							kkm.LabelType === 'primary' ? <Success fill='#28A745' /> :
							kkm.LabelType === 'available' ? <Success fill='#28A745' /> :
							<Danger />}
					</i>
					{getKKmLabelText(kkm.LabelText)}
				</p>
				<span className={`${s.expire_date} ${kkm.LabelType === 'success' && s.expire_date_opened}`}>
					{(!pending) ? expireText : t('main.loading')}
				</span>
			</section>
		</div>
	)
}, (prevProps, nextProps) => {
  return (
    prevProps.kkm === nextProps.kkm &&
    prevProps.pending === nextProps.pending
  );
})

export default CashboxComponent
