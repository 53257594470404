/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getHeaders } from './snippets';
import { withRouter } from 'react-router-dom';
// import amplitude from 'amplitude-js';
// import { withRouter } from "react-router-dom";
import { getError } from './snippets';
import Error503Modal from '../Components/NewDesign/Modals/Error500Modal';
import { AMPLITUDE_API_URL, DISCOUNT_TYPES, PULT_API_DEV_URL, OPERATION_TYPES } from '../constants/app';
import { bincheck, calculateDiscountOrMarkup, getPositionNds, getPositionTotal } from './OperationProviderUtils';

const Store = React.createContext();

let headers, IdKkm;
let isElectron = require("is-electron");

// var instance = amplitude.getInstance().init("d314c6311f60a96a3d7ec5a856b85143");

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

let platform = ''
if(/Android||BlackBerry/i.test(navigator.userAgent) && window.app && !!window.cordova) {
	platform = 'Android'
}
if(/webOS/i.test(navigator.userAgent) || isElectron()) {
	platform = 'DesktopApp'
}
if(iOS() && window.app && !!window.cordova) {
	platform = 'IOS'
}
else{
	platform = 'Web'
}

// const root = document.getElementById('root');
// const IdKkm = root.dataset.idKkm;

const onSuccess = function (result) {
	console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
	console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
};

const onError = function (msg) {
	console.log("Sharing failed with message: " + msg);
};

// const leftAmount = JSON.parse(localStorage.getItem("BALANCES"));

const DomainTitles = {
	"DOMAIN_TRADING": "Торговля",
	"DOMAIN_SERVICES": "Услуги",
	"DOMAIN_LOMBARD": "Ломбард",
	"DOMAIN_GASOIL": "ГСМ",
	"DOMAIN_HOTELS": "Отели",
	"DOMAIN_TAXI": "Такси",
	"DOMAIN_PARKING": "Парковка"
};

// const RECIVED_SECTION_DOMAINS = {
// 	"Status": 200,
// 	"Message": "OK",
// 	"Data": {
// 		"Domains": [
// 			{
// 				"IdDomain": 1,
// 				"Name": "DOMAIN_TRADING"
// 			},
// 			// {
// 			// 	"IdDomain": 2,
// 			// 	"Name": "DOMAIN_SERVICES"
// 			// },
// 			{
// 				"IdDomain": 2,
// 				"Name": "DOMAIN_LOMBARD"
// 			},
// 			{
// 				"IdDomain": 3,
// 				"Name": "DOMAIN_GASOIL"
// 			},
// 			{
// 				"IdDomain": 4,
// 				"Name": "DOMAIN_HOTELS"
// 			},
// 			{
// 				"IdDomain": 5,
// 				"Name": "DOMAIN_TAXI"
// 			},
// 			{
// 				"IdDomain": 6,
// 				"Name": "DOMAIN_PARKING"
// 			}
// 		]
// 	}
// }

// let RECIVED_SECTION_LIST = [
// 	{
// 		Id: 1,
// 		Name: "AAA",
// 		Nds: 12,
// 	},
// 	// {
// 	//     Id: 2,
// 	//     Name: "BBB",
// 	//     Nds: 0,
// 	// },
// 	// {
// 	//     Id: 3,
// 	//     Name: "CCC",
// 	//     Nds: 0,
// 	// },
// ]

// let RECIVED_ARTICLES = {
// 	Articles: [
// 		{
// 			Active: true,
// 			Discount: 0,
// 			Id: 1088,
// 			IdCompany: 1,
// 			IdGroupArticle: 1,
// 			IdSection: 1,
// 			Markup: 0,
// 			Name: "ЛОКАЛХОСТ",
// 			Price: 100,
// 			Qr: "",
// 			label: "ЛОКАЛХОСТ",
// 			value: "ЛОКАЛХОСТ"
// 		},
// 		{
// 			Active: true,
// 			Discount: 0,
// 			Id: 1091,
// 			IdCompany: 1,
// 			IdGroupArticle: 1,
// 			IdSection: 1,
// 			Markup: 0,
// 			Name: "LOCKALHOST",
// 			Price: 0,
// 			Qr: "",
// 			label: "LOCKALHOST",
// 			value: "LOCKALHOST"
// 		}
// 	]
// }

const POSITION = {
	Name: '',
	Price: '',
	Markup: 0,
	Domain: {},
	MarkupPercent: 0,
	Discount: 0,
	DiscountPercent: 0, // может содержать как %, так и фиксированное значение для некоторых компаний
	Qty: "1.00",
	ProductCode: '',
	Section: {
		// ...RECIVED_SECTION_LIST[0]
		Nds: 0
	},
	SectionIndex: 0,
	Storno: false,
	Total: 0,
	Nds: 0,
	SelectedOption: { value: '', label: '' },
	Unit: {
		Code: 0,
		Id: 0,
		NameKAZ: "",
		NameRU: "",
		ShortName: ""
	}
}

const POSITION_ERRORS = {
	Name: false,
	Price: false,
	Qty: false,
	Markup: false,
	Discount: false,
	isValid: true,
}

const SALE = {
	"IdDomain": '1',
	"Cash": null,
	"NonCash": null,
	"Positions": [
		{
			"Name": null,
			"IdSection": null,
			"IdUnit": null,
			"Price": null,
			"Markup": null,
			"ProductCode": null,
			"Discount": null,
			"Qty": null,
			"Storno": null,
		}
	],
	"Total": null,
	"Uid": '111'
}

class OperationProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			NameError: false,
			PriceError: false,
			DiscountType: DISCOUNT_TYPES.PERCENT,
			Position: {
				...POSITION
			},
	
			Income: {
				Cash: 0,
				NonCash: 0,
				Mobile: 0,
			},
	
			PreCheck: [],
			NewPreCheck: [
				{
					id: 0,
					Position: {
						Name: "",
						Price: "",
						Qty: "",
						Unit: "",
						Section: "",
						Domain: "",
						Markup: "",
						MarkupPercent: "",
						Discount: "",
						DiscountPercent: "",
						DiscountType: ""
					}
				}
			],
			Total: 0,
			Change: 0,
			Lack: 0,
	
			Domain: {
				// ...RECIVED_SECTION_DOMAINS.Data.Domains[0]
			},
			DomainIndex: '',
	
			Domains: [
				// ...RECIVED_SECTION_DOMAINS.Data.Domains
			],
			Sections: [
				// ...RECIVED_SECTION_LIST
			],
			Units: [],
			sectionLS: {},
			unitLS: {},
			domainLS: {},
			PositionErrors: {
				...POSITION_ERRORS
			},
			IsAvailableForSale: true,
			IsSaleButtonEnabled: true,
	
			Modal: {
				Status: false,
				Header: '',
				Body: ''
			},
			ModalCheck: {
				Status: false,
			},
			ModalQRCheck: {
				Status: false
			},
			SaleError: {
				Status: true,
				Header: "Ошибка",
				Body: "Ошибка ответа от сервера..."
			},
			SaleSuccess: {
				Status: true,
				Header: "Операция прошла успешно",
				Body: ''
			},
	
			CheckImg: '',
			CheckId: '',
			CheckLink: '',
			OperationErrorText: '',
	
			Uid: '',
			IdDomain: '1',
	
			Amount: 0,
			IdKkm: IdKkm,
			Operation: props.operation || '',
			StorageOperationName: `${props.operation}_${IdKkm}`,
			Articles: [],
			ArticlesForSelect: [],
			GlobalError: false,
			GlobalErrorTitle: '',
			Balances: {},
	
			ExciseChecked: false,
			CustomerIin: "",
			CustomerIinError: false,
			totalReceiptCount: JSON.parse(localStorage.getItem("USER")).Lock ? Number(JSON.parse(localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT'))) : 0,
			errorData: null,
			SnForRefunds: undefined
		}
		this._Mounted = false
	}


	shareSocial = (file) => {
		const optionsShare = {
			// message: 'share this', // not supported on some apps (Facebook, Instagram)
			// subject: 'the subject', // fi. for email
			files: [file], // an array of filenames either locally or remotely
			// url: 'https://www.website.com/foo/#bar?a=b',
			// chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
			// appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
			// iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
		};
		// console.log(file);
		// console.log(optionsShare);
		window.plugins.socialsharing.shareWithOptions(optionsShare, onSuccess, onError);
	}

	controller = new AbortController();

	componentWillUnmount() {
		this._Mounted = false
		window.removeEventListener('beforeunload', this.keepOnPage);

		localStorage.getItem('SECTION') !== null && localStorage.removeItem('SECTION')
		this.controller.abort()
	}

	async componentDidMount() {
		this._Mounted = true
		if (this._Mounted) {
			headers = getHeaders(this.props.history);
			IdKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
			let PositionDetailFromReport = JSON.parse(localStorage.getItem('PositionDetailFromReport'))
			const operation = this.state.Operation;
			if (operation === 'Sale' || operation === 'Refund' || operation === 'Purchase' || operation === 'Purchase_Refund') {
				const articleReq = axios.get(`${BASE_URL}/kkms/${IdKkm}/articles`, { headers: headers, signal: this.controller.signal })
				const domainsReq = axios.get(`${BASE_URL}/directories/domains`, { headers: headers, signal: this.controller.signal })
				const sectionsReq = axios.get(`${BASE_URL}/kkms/${IdKkm}/sections`, { headers: headers, signal: this.controller.signal })
				const unitsReq = axios.get(`${BASE_URL}/kkms/${IdKkm}/units`, { headers: headers, signal: this.controller.signal })
				let Articles = []
				Promise.all([articleReq, domainsReq, sectionsReq, unitsReq]).then(([articles, domains, sections, units]) => {
					if (Array.isArray(articles.data.Data.Articles)) {
						Articles = articles.data.Data.Articles.map(elem => {
							return {
								...elem,
								value: elem.Name.toLowerCase(),
								label: elem.Name,
							}
						});
					}
					const Domains = domains.data.Data.Domains.map(domain => {
						return {
							Name: DomainTitles[domain.Name],
							Id: domain.Id
						}
					});

					const Sections = sections.data.Data.Sections;
					const Units = units.data.Data
					// if (Articles.length === 0) {
					// 	throw 'Articles is empty';
					// }
					// if (Domains.length === 0) {
					// 	throw 'Domains is empty';
					// }
					// if (Sections.length === 0) {
					// 	throw 'Sections is empty';
					// }

					if(localStorage.getItem('SECTION') !== null) {
						this.setState({
							sectionLS: JSON.parse(localStorage.getItem('SECTION'))
						}, () => this.sortSections(Sections))
					}

					if (localStorage.getItem('UNITS') !== null) {
						this.setState({
							unitLS: JSON.parse(localStorage.getItem('UNITS'))
						})
					}

					if(localStorage.getItem('DOMAIN') !== null) {
						this.setState({
							domainLS: JSON.parse(localStorage.getItem('DOMAIN'))
						}, () => this.sortDomains(Domains))
					}
					this.setState({
						...this.state,
						IdKkm,
						StorageOperationName: `${this.props.operation}_${IdKkm}`,
						Articles,
						Domains,
						IdDomain: Domains[0],
						Sections,
						Units,
						Position: {
							...POSITION,
							Section: Sections[0],
							SectionIndex: Sections[0].Id,
							Domain: Domains[0],
							Unit: Units[0],
						}
					})

					if (PositionDetailFromReport !== null) {
						this.setState({
							...this.state,
							Position: {
								...POSITION,
								...PositionDetailFromReport,
							}
						})
					}

				}).catch(error => {
					const errorData = getError(error, this.props.history)
					this.setState({
						...this.state,
						...this.getInitError(error),
						errorData: errorData
					});
				})
			} else this.setState({
					...this.state,
					IdKkm,
					StorageOperationName: `${this.props.operation}_${IdKkm}`,
				})
		}
	}

	setNameError = (value) => {
		this.setState({
			NameError: value
		})
	}

	setPriceError = (value) => {
		this.setState({
			PriceError: value
		})
	}

	getUid = async (idKkm) => {
		const fromLS = localStorage.getItem(`Uid_${idKkm}`);
		if (typeof fromLS === 'string' && fromLS.length >= 36) {
			return fromLS;
		}
		
		try {
			const response = await axios.get(`${BASE_URL}/uid/${idKkm}`,
				{
					headers: headers
				});
				console.log('getUid() SET UID:', response.data.Data.Uid);
			await localStorage.setItem(`Uid_${idKkm}`, response.data.Data.Uid);
			return response.data.Data.Uid;
		} catch (e) {
			await localStorage.removeItem(`Uid_${idKkm}`);
			throw e;
		}
	};

	getInitError = (error) => {
		if (error === 'Articles is empty') {
			return {
				GlobalErrorTitle: 'На кассе не настроена номенклатура',
				GlobalError: 'Вы не сможете оформить чек. Зайкройте смену, перейдите в Настройки и настройте номенклатуру на кассе. Если у Вас нет доступа к настройкам - обратитесь в тех. поддержку.'
			}
		}
		if (error === 'Domains is empty') {
			return {
				GlobalErrorTitle: 'На кассе не настроены домены',
				GlobalError: 'Вы не можете оформить чек не указав домен. Зайкройте смену, перейдите в Настройки и настройте домены на кассе. Если у Вас нет доступа к настройкам - обратитесь в тех. поддержку.'
			}
		}
		if (error === 'Sections is empty') {
			return {
				GlobalErrorTitle: 'На кассе не настроены секции',
				GlobalError: 'Вы не можете оформить чек не указав секцию у товарной позиции. Зайкройте смену, перейдите в Настройки и настройте секции на кассе.\n Если у Вас нет доступа к настройкам - обратитесь в тех. поддержку.'
			}
		}
		if (error.response === undefined) {
			return {
				GlobalErrorTitle: 'Ошибка соединения',
				GlobalError: 'Проверьте соединение с интернетом',
			};
		}
		const uid = error.response.headers.uid;
		if (uid) {
			localStorage.setItem(`Uid_${this.state.IdKkm}`, uid);
		} else {
			localStorage.removeItem(`Uid_${this.state.IdKkm}`);
		}
		if (error.response.status === 401) {
			return {
				GlobalErrorTitle: 'Ошибка авторизации',
				GlobalError: 'Нужно заново ввести логин и пароль',
			};
		}

		if (error.response.status === 403) {
			return {
				GlobalErrorTitle: 'Нет доступа к кассе',
				GlobalError: 'Обратитесь в тех. поддержку',
			};
		}
		if (error.response.status === 502 || error.response.status === 429) {
			return {
				GlobalErrorTitle: "Не удалось оформить чек",
				GlobalError: 'Касса заблокирована из-за работы с разных устройств'
			};
		}

		// if (error.response.status === 504 || error.response.status === 452) {
		//     this.saveStateLocal(this.state.Operation, this.state.IdKkm);
		//     return {
		//         Modal: {
		//             Status: true,
		//             Header: "Не удалось оформить чек",
		//             Body: "Нажмите кнопку \"Оформить чек\" ещё раз"
		//         },
		//     };
		// }

		if (error.response.status > 500) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			const errorText = error.request.status === 0 ? 'Отсутствует соединение с сервером, проверьте связь' : 'Ошибка ответа от сервера! Попробуйте обновить страницу.'
			return {
				GlobalErrorTitle: "Ошибка сети",
				GlobalError: errorText
			};
		}
	}

	getResponseError = (error) => {
		if (error.response === undefined) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			return {
				Modal: {
					Status: true,
					Header: "Ошибка сети",
					Body: 'Проверьте соединение с интернетом'
				},
			};
		}
		const uid = error.response.headers.uid;
		if (uid) {
			localStorage.setItem(`Uid_${this.state.IdKkm}`, uid);
		} else {
			localStorage.removeItem(`Uid_${this.state.IdKkm}`);
		}

		if (error.response.status === 401) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			return {
				Modal: {
					Status: true,
					Header: "Ошибка авторизации",
					Body: 'Нужно заново ввести логин и пароль'
				},
			};
		}

		if (error.response.status === 403) {
			return {
				Modal: {
					Status: true,
					Header: "Нет доступа к кассе",
					Body: 'Обратитесь в тех. поддержку'
				},
			};
		}
		if (error.response.status === 502 || error.response.status === 429) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			return {
				Modal: {
					Status: true,
					Header: "Не удалось оформить чек",
					Body: 'Касса заблокирована из-за работы с разных устройств'
				},
			};
		}

		if (error.response.status === 504 || error.response.status === 452) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			return {
				Modal: {
					Status: true,
					Header: "Не удалось оформить чек",
					Body: "Нажмите кнопку \"Оформить чек\" ещё раз"
				},
			};
		}

		if (error.response.status > 499) {
			this.saveStateLocal(this.state.Operation, this.state.IdKkm);
			const errorText = error.request.status === 0 ? 'Отсутствует соединение с сервером, проверьте связь' : 'Ошибка ответа от сервера!'
			return {
				Modal: {
					Status: true,
					Header: "503 ошибка",
					Body: errorText
				},
			};
		}

		return {
			Modal: {
				Status: true,
				Header: "Ошибка " + error.response.status,
				Body: error.response.data.Message
			},
			Uid: uid
		}
	};

	getResponseSuccess = async (response, idKkm, fromNew) => {
		console.log('response', response)
		const { headers: { uid, Uid }, data: { Data: { Balances, Location, Receipt, IdDocument } } } = response
		localStorage.setItem(`BALANCES`, JSON.stringify(Balances));
		if (fromNew) {
			this.setState({
				...this.state,
				CustomerIin: "",
				Balances,
				CheckImg: Receipt,
				CheckId: IdDocument,
				CheckLink: Location.replace(/&/g, "%26"),
			});
			this.resetNewPreCheck()
			this.resetOnlyPosition()
		} else {
			this.setState({
				...this.state,
				CustomerIin: "",
				Balances,
				Modal: {
					Status: true,
					Header: "Операция прошла успешно",
					Body: ''
				},
				CheckImg: Receipt,
				CheckId: IdDocument,
				CheckLink: Location.replace(/&/g, "%26"),
			});
			this.resetPreCheck();
		}
		let checkData = {
			CheckImg: Receipt,
			CheckId: IdDocument,
			CheckLink: Location.replace(/&/g, "%26"),
		}
		
		console.log('getResponseSuccess() SET UID:', uid, Uid);
		localStorage.setItem('checkData', JSON.stringify(checkData))
		localStorage.setItem(`Uid_${idKkm}`, uid || Uid);
		return true
	}

	positionInputHandler = (data, status) => {
		this.setState({
			...this.state,
			Position: {
				...this.state.Position,
				[data.name]: data.value
			}
		}, () => this.editNewPreCheckPosition(status))
	}

	priceInputHandler = (data, status) => {
		this.setState({
			...this.state,
			Position: {
				...this.state.Position,
				Price: data.value,
			}
		}, () => {
			if (data.value) {
				this.setPriceError(false)
			}
			this.editNewPreCheckPosition(status)
		})
	}

	discountTypeHandler = (discountTypeValue, status) => {
		this.setState({
			...this.state,
			DiscountType: discountTypeValue
		}, () => {
			this.editNewPreCheckPosition(status)
		})
	}

	nameInputHandler = (data, status) => {
		this.setState({
			...this.state,
			Position: {
				...this.state.Position,
				Name: data.value,
			}
		}, () => {
			if (data.value) {
				this.setNameError(false)
			}
			this.editNewPreCheckPosition(status)
		})
	}

	exciseCodeInputHandler = (data, status) => {
		this.setState({
			...this.state,
			Position: {
				...this.state.Position,
				ProductCode: data.value
			}
		}, () => this.editNewPreCheckPosition(status))
	}

	// inputNumberHandler = (data) => {
	//     this.setState({
	//         Position: {
	//             ...this.state.Position,
	//             [data.name]: data.value
	//         }
	//     })
	//
	// }
	//
	positionDiscountMarkupHandler = (data, status) => {
		this.setState({
			...this.state,
			Position: {
				...this.state.Position,
				[data.name]: data.value,
			}
		}, () => this.editNewPreCheckPosition(status))
	}

	sectionChangeHandler = (value, status) => {
		const currentSection = this.state.Sections.filter((section) => section.Id === value);
		currentSection.forEach((sect) => {
			localStorage.setItem('SECTION', JSON.stringify(sect))
		})
		this.setState({
			sectionLS: JSON.parse(localStorage.getItem('SECTION')),
			Position: {
				...this.state.Position,
				Section: {
					...currentSection[0]
				}
			}
		}, () => this.editNewPreCheckPosition(status))
	}

	unitChangeHandler = (value, status) => {
		const currentUnit = this.state.Units.filter((unit) => unit.Id === value);
		currentUnit.forEach((unit) => {
			localStorage.setItem('UNIT', JSON.stringify(unit))
		})
		this.setState({
			unitLS: JSON.parse(localStorage.getItem('UNIT')),
			Position: {
				...this.state.Position,
				Unit: {
					...currentUnit[0]
				}
			}
		}, () => this.editNewPreCheckPosition(status))
	}

	sortSections = (array) => {
		let old_index = 0;
		for(let i=0; i < array.length; i++) {
			if(this.state.sectionLS.Id === array[i].Id) {
				old_index = i;
			}
		}
		array.splice(old_index, 1)
		array.unshift(this.state.sectionLS)
		return array;
	}

	domainChangeHandler = (value, index) => {
		const currentDomain = this.state.Domains.filter((domain) => domain.Id === value);
		currentDomain.forEach((dom) => {
			localStorage.setItem("DOMAIN", JSON.stringify(dom))
		})
		this.setState({
			domainLS: JSON.parse(localStorage.getItem('DOMAIN')),
			Position: {
				...this.state.Position,
				Domain: {
					...currentDomain[0]
				}
			},
			DomainIndex: value,
			IdDomain: this.state.Domains[value],
		}, () => this.sortDomains(this.state.Domains))
	}

	sortDomains = (array) => {
		let old_index = 0;
		for(let i=0; i < array.length; i++) {
			if(this.state.domainLS.Id === array[i].Id) {
				old_index = i;
			}
		}
		array.splice(old_index, 1)
		array.unshift(this.state.domainLS)
		return array;
	}

	amountHandlerExpense = (data, status) => {
		const leftAmount = JSON.parse(localStorage.getItem("BALANCES"));
		if (data.value > 0) {
			this.setState({
				IsAvailableForSale: true
			})
		}
        if (data.value === 0) {
            this.setState({
                Amount: ''
            }, () => this.editNewPreCheckPosition(status))
        }
        if (data.value >= Math.trunc(leftAmount[0].Amount)) {
            this.setState({
                Amount: Math.trunc(leftAmount[0].Amount),
            }, () => this.editNewPreCheckPosition(status))
        } else {
            this.setState({
                Amount: data.value
            }, () => this.editNewPreCheckPosition(status))
        }
    }

	amountHandler = (data, status) => {

		if (data.value > 0) {
			this.setState({
				IsAvailableForSale: true
			})
		}
		this.setState({
			Amount: data.value
		}, () => this.editNewPreCheckPosition(status))
	}

	_stateChange = (data) => {
		this.setState({ [`${data.key}`]: data.value })
	}

	validatePosition = (position) => {
		const positionKeys = Object.keys(position);
		const errors = positionKeys.reduce((errors, key) => {
			switch (key) {
				case 'Name':
					if (position[key] === '') {
						errors[key] = 'Наименование не должно быть пустым';
					}
					return errors;
				case 'Price':
					if (position[key] === '') errors[key] = 'Цена не должна быть пустой';
					if (typeof (position[key]) === 'number' && position[key] <= 0) errors[key] = 'Цена должна быть положительным числом';
					return errors;
				case 'Qty':
					if (position[key] === '') errors[key] = 'Количество не должно быть пустым';
					if (typeof (position[key]) === 'number' && position[key] <= 0) errors[key] = 'Количество должно быть положительным числом';
					return errors;
				case 'Markup':
					if (position[key] !== '' && position[key] > 0) {
						if (typeof (position[key]) === 'number' && position[key] <= 0) errors[key] = 'Наценка должна быть положительным числом';
					}
					return errors;
				case 'Discount':
					if (position[key] !== '' && position[key] > 0) {
						if (typeof (position[key]) === 'number' && position[key] <= 0) errors[key] = 'Скидка должна быть положительным числом';
					}
					return errors;
				case 'DiscountPercent':
					if (position[key] >= 100) {
						errors[key] = 'Скидка не должна быть больше 100%';
					}
					return errors;

				default:
					return errors;
			}
		}, {})

		const errorKeys = positionKeys.filter(key => errors[key]);
		errors.isValid = errorKeys.length > 0 ? false : true;
		return errors;
	}

	stornoToggle = (idx) => {
		const storno = Object.assign([], this.state.PreCheck);
		storno[idx].Storno = !this.state.PreCheck[idx].Storno;
		this.setState({
			PreCheck: storno,
			storno
		}, () => this.setFinalSum())
	}

	newStornoToggle = (idx) => {
    const newStorno = [...this.state.NewPreCheck];
    const storno = this.state.PreCheck.length > 0 ? [...this.state.PreCheck] : null;

    if (newStorno[idx] && newStorno[idx].Position) {
        newStorno[idx].Position.Storno = !newStorno[idx].Position.Storno;
    } 

    if (storno && storno[idx]) {
        storno[idx].Storno = !storno[idx].Storno;
    }

    this.setState({
        NewPreCheck: newStorno,
        ...(storno && { PreCheck: storno })
    }, () => this.setNewFinalSum());
}

	setQty = (idx, num) => {
		const newQty = Object.assign([], this.state.PreCheck);
		newQty[idx].Qty = Number(this.state.PreCheck[idx].Qty) + num;

		this.setState({ newQty })
	}

	resetOnlyPosition = () => {
		this.setState({
			Amount: 0,
			Position: {
				...this.state.Position,
				...POSITION,
				Section: this.state.Sections[POSITION.SectionIndex],
				Unit: this.state.Units[0]
			},
			PositionErrors: {
				...this.state.PositionErrors,
				...POSITION_ERRORS
			}
		})
	}

	resetPosition = () => {
		this.setState({
			Amount: 0,
			Position: {
				...this.state.Position,
				...POSITION,
				Section: this.state.Sections[POSITION.SectionIndex],
				Unit: this.state.Units[0]
			},
			PositionErrors: {
				...this.state.PositionErrors,
				...POSITION_ERRORS
			},
			CheckImg: '',
			CheckId: '',
			CheckLink: ''
		})
	};

	resetPreCheck = () => {
		this.setState({
			Amount: 0,
			PreCheck: [],
			Total: 0,
			Change: 0,
			Lack: 0,
			Income: {
				Cash: 0,
				NonCash: 0,
				Mobile: 0,
			},
			IsAvailableForSale: true,
			IsSaleButtonEnabled: true,
		})
		localStorage.removeItem(this.state.StorageOperationName);
	}

	resetNewPreCheck = () => {
		this.setState({
			Income: {
				Cash: 0,
				NonCash: 0,
				Mobile: 0,
			},
			Total: 0,
			NewPreCheck: [
				{
					id: 0,
					Position: {
						Name: "",
						Price: "",
						Qty: "",
						Unit: "",
						Section: "",
						Markup: "",
						MarkupPercent: "",
						Discount: "",
						DiscountPercent: "",
						DiscountType: ""
					}
				}
			]
		})
	}

	addPreCheckPosition = (e, status) => {
		e.preventDefault();
		let { Position } = this.state;
		if (Position.Qty < 1) Position = { ...Position, Qty: "1.00" }
		const errors = this.validatePosition(Position);
		if (errors.isValid) {
			Position.Nds = getPositionNds(Position.Total, this.state.Position.Section.Nds);
			Position.Price = Number(Position.Price).toFixed(2);
			Position.Markup = calculateDiscountOrMarkup(Position.Price, Position.MarkupPercent, this.state.DiscountType);
			Position.Discount = calculateDiscountOrMarkup(Position.Price, Position.DiscountPercent, this.state.DiscountType);
			Position.isFixedDiscountOrMarkup = Boolean(this.state.DiscountType === DISCOUNT_TYPES.FIXED);
			//Position.Total = Math.floor(Number(getPositionTotal(Position, status).toFixed(2)));
			const total = getPositionTotal(Position, status);
      Position.Total = parseFloat(total.toFixed(2));
			Position.id = this.state.PreCheck.length;
			this.setState({
				PreCheck: [
					...this.state.PreCheck,
					Position
				],
				// Income: {
				// 	Cash: Position.Total,
				// 	NonCash: 0
				// }
			}, () => this.setFinalSum());
			this.resetPosition();
		} else {
			this.setState({
				PositionErrors: errors,
			})
		}
	};

	editNewPreCheckPosition = (status) => {
		let { Position } = this.state;
		let precheck = [ ...this.state.NewPreCheck ];
		precheck[0] = {
			id: precheck.length,
			Position
		};
		precheck[0].Position.Markup = calculateDiscountOrMarkup(Position.Price, Position.MarkupPercent, this.state.DiscountType);
		precheck[0].Position.DiscountType = this.state.DiscountType;
		precheck[0].Position.Discount = calculateDiscountOrMarkup(Position.Price, Position.DiscountPercent, this.state.DiscountType);
		precheck[0].Position.isFixedDiscountOrMarkup = Boolean(this.state.DiscountType === DISCOUNT_TYPES.FIXED);
		precheck[0].Position.Total = parseFloat(getPositionTotal(Position, status)).toFixed(2);
		this.setState({
			NewPreCheck: precheck
		}, () => this.setNewFinalSum());
	}

	addNewPreCheckPosition = () => {
		let precheck = [ ...this.state.NewPreCheck ];
		precheck.unshift(
			{
				id: 0,
				Position: {
					Name: "",
					Price: 0,
					Qty: "1.00",
					Unit: "",
					Section: "",
					Markup: "",
					Discount: "",
					DiscountType: ""
				}
			}
		)
		this.setState({
			NewPreCheck: precheck
		}, () => this.editNewPreCheckPosition())
	}

	setNewFinalSum = async () => {
		let start = 0;
		const result = this.state.NewPreCheck.reduce((acc, curr) => {
			if (curr.Position && !curr.Position.Storno) {
				return acc + Number(curr.Position.Total || 0)
			}
			return acc;
		}, start).toFixed(2);

		await this.setState({
			Total: Number(result),
			Income: {
				Cash: Number(result),
				NonCash: 0,
				Mobile: 0,
			}
		}, () => this.setChange())
	}

	setFinalSum = async () => {
		let start = 0;
		const result = this.state.PreCheck.reduce((acc, curr) => {
			if (!curr.Storno) {
				return acc + parseFloat(curr.Total);
			}
			return acc;
		}, start);

		await this.setState({
			Total: parseFloat(result),
			Income: {
				Cash: parseFloat(result),
				NonCash: 0,
				Mobile: 0,
			}
		}, () => this.setChange())
	}

	swapSums = () => {
		let cash = this.state.Income.Cash
		this.setState({
			Income: {
				Cash: this.state.Income.NonCash,
				NonCash: cash,
				Mobile: 0,
			}
		})
	}

	setSum = (type) => {
		const sum = Object.values(this.state.Income).find(el => el !== 0)
		switch (type) {
			case 1:
				this.setState({
					Income: {
						Cash: sum,
						NonCash: 0,
						Mobile: 0,
					}
				})
				break
			case 2:
				this.setState({
					Income: {
						Cash: 0,
						NonCash: sum,
						Mobile: 0,
					}
				})
				break
			case 3:
				this.setState({
					Income: {
						Cash: 0,
						NonCash: 0,
						Mobile: sum,
					}
				})
				break
			default: {
				break
			}
		}
	}

	setChange = () => {
		const cash = Number(this.state.Income.Cash);
		const nonCash = Number(this.state.Income.NonCash);
		const mobile = Number(this.state.Income.Mobile);
		const total = Number(this.state.Total);
		let change = 0;
		let lack = 0;
		change = (cash + nonCash + mobile - total);

		if (change < 0) {
			lack = change * -1;
			change = 0;
			this.setState({
				IsAvailableForSale: false,
				Change: change.toFixed(2),
				Lack: lack
			})
		} else {
			lack = 0;
			this.setState({
				IsAvailableForSale: true,
				Change: change.toFixed(2),
				Lack: lack
			})
		}

		if (nonCash > total) {
			change = cash;
			this.setState({
				Income: {
					...this.state.Income,
					NonCash: total
				},
				Change: change.toFixed(2),
			})
		}


		if (mobile > total) {
			change = cash;
			this.setState({
				Income: {
					...this.state.Income,
					Mobile: total
				},
				Change: change.toFixed(2),
			})
		}
	}

	setRefundsChange = () => {
		const cash = Number(this.state.Income.Cash);
		const nonCash = Number(this.state.Income.NonCash);
		const mobile = Number(this.state.Income.Mobile);
		const total = Number(this.state.Total);		
		let change = 0;
		let lack = 0;
		change = (cash + nonCash + mobile - total);

		if (change < 0) {
			lack = change * -1;
			change = 0;
			this.setState({
				IsAvailableForSale: false,
				Change: change,
				Lack: lack
			})
		} else {
			lack = 0;
			this.setState({
				IsAvailableForSale: true,
				Change: change,
				Lack: lack
			})
		}

		if (cash > total) {
			console.log('cash > total')
			change = cash - total;
			this.setState({
				Income: {
					...this.state.Income,
					Cash: total - nonCash - mobile
				},
				Change: change
			})
		}

		if (nonCash > total) {
			change = nonCash - cash;
			this.setState({
				Income: {
					...this.state.Income,
					NonCash: total - cash
				},
				Change: change
			})
		}

		if (mobile > total) {
			change = nonCash - cash;
			this.setState({
				Income: {
					...this.state.Income,
					Mobile: total - mobile,
				},
				Change: change
			})
		}
	}
	iinHandler = (bin) => {
		console.log("BIN ", bin);
		this.setState({
			CustomerIin: bin.value,
		})
		if (bincheck(bin.value)) {
			this.setState({
				CustomerIinError: false
			})
		} else {
			this.setState({
				CustomerIinError: true
			})
		}
		if (!bin.value.length) {
			this.setState({
				CustomerIinError: false
			})
		}
	}
	cashHandler = (data) => {
		if (!isNaN(Number(data.value))) {
			this.setState({
				[data.objName]: {
					...this.state[data.objName],
					[data.name]: Number(data.value)
				}
			}, () => this.setChange())
		}
	}

	cashRefundsHandler = (data) => {
		if (!isNaN(Number(data.value))) {
			this.setState({
				[data.objName]: {
					...this.state[data.objName],
					[data.name]: Number(data.value)
				}
			}, () => this.setRefundsChange())
		}
	}

	isCurrentIncome = (cash, nonCash, mobile, total) => cash + nonCash + mobile >= total && total > 0;

	saveStateLocal = (operation, idKkm) => {
		localStorage.setItem(`${operation}_${idKkm}`, JSON.stringify(this.state));
	}

	getSale = ({ IdDomain, Income, PreCheck, Total, Amount, Operation, IdKkm, CustomerIin, DiscountType }, status) => {
		let idDomain = '';
		if (IdDomain) {
			idDomain = IdDomain.Id || '';
		}
		const cash = this.state.Income.Cash;
		const nonCash = this.state.Income.NonCash;
		const mobile = this.state.Income.Mobile;
		const { Uid, ...templateSale } = { ...SALE }
		let positionsArr = [...this.state.PreCheck]

		if (this.state.NewPreCheck.length && this.state.NewPreCheck[0].id && !this.state.PreCheck.length) {
			positionsArr = this.state.NewPreCheck.map(p => p.Position)
		}

		const positions = positionsArr
      .filter((position) => {
        if (
          (status === 'purchases' || status === 'refunds' || status === 'purchase_refunds') &&
          position.Storno
        ) {
          return false;
        }
        return true;
      })
      .map((position) => {
        const { Name, Price, Markup, Discount, Qty, Storno, Section, ProductCode, Unit } = position;
        const IdSection = Section.Id;
        const IdUnit = Unit.Id;
        const qty = Number(Qty);
        const price = Number(Price);

        if (status === 'sales') {
          return {
            Name,
            IdSection,
            IdUnit,
            Price: price,
            Markup: isNaN(Markup) ? 0 : parseFloat(Markup),
            Discount: isNaN(Discount) ? 0 : parseFloat(Discount),
            Qty: qty,
            Storno,
            ProductCode,
            IsFixedDiscountOrMarkup: position.isFixedDiscountOrMarkup,
          };
        }

        if (status === 'purchases' || status === 'refunds' || status === 'purchase_refunds') {
          return {
            Name,
            IdSection,
            IdUnit,
            Price: price,
            Markup: Markup,
            Discount: Discount,
            Qty: qty,
            Storno,
          };
        }

        if (this._Mounted && (status === 'expenses' || status === 'incomes')) {
          return [];
        }

        return position;
      });

    const total = this.state.Total;
		const sn = this.state.SnForRefunds;

		return {
			...templateSale,
			"IdDomain": idDomain,
			"Cash": cash,
			"NonCash": nonCash,
			"Mobile": mobile,
			"Positions": positions,
			"Total": total,
			"Amount": Amount,
			"GenerateCheck": true,
			CustomerIin,
			SN: sn,
		}
	}

	makeSale = async (operation) => {
		// if (!window.confirm('Вы уверены что вы хотите совершить операцию на ' + this.state.Total)) return;
		const cash = this.state.Income.Cash;
		const nonCash = this.state.Income.NonCash;
		const mobile = this.state.Income.Mobile
		const total = this.state.Total;

		if (!this.state.CustomerIinError && this.isCurrentIncome(cash, nonCash, mobile, total)) {
			this.saleButtonDisable();
			window.addEventListener('beforeunload', this.keepOnPage);
			this.setState({
				IsAvailableForSale: true,
			});
			try {
				const uid = await this.getUid(this.state.IdKkm);
				const response = await axios.post(
					`${BASE_URL}/kkms/${IdKkm}/${operation}`,
					this.getSale(this.state, operation),
					{
						headers: { ...headers, 'uid': uid }
					});
				let fromNew = this.state.NewPreCheck.length && this.state.NewPreCheck[0].id && !this.state.PreCheck.length

				const receiptCount = Number(JSON.parse(localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT'))) || 0;
				if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') !== null) {
					localStorage.setItem('OPEN_SHIFT_RECEIPT_COUNT', receiptCount + 1)
				}

				return await this.getResponseSuccess(response, this.state.IdKkm, fromNew);
			} catch (e) {
				this.setState(this.getResponseError(e));
				return false
			}

			// const analiticsHeaders = {
			// 	'Content-Type':'application/json',
			// 	'Accept':'*/*',
			// 	'Access-Control-Allow-Origin': '*',
			// 	'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
			// 	'Access-Control-Max-Age': '0',
			// 	'Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
			// 	'X-Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
			// 	'X-Webkit-CSP': "default-src *, connect-src *, script-src 'unsafe-inline' 'unsafe-eval' *; object-src *",
			// 	'Sec-Fetch-Dest': "empty",
			// 	'Sec-Fetch-Mode': "cors",
			// 	'Sec-Fetch-Site': "cross-site",
			// };

			// try {
			// 	await axios.post(`${AMPLITUDE_API_URL}/2/httpapi`,
			// 	{
			// 		"api_key": "d314c6311f60a96a3d7ec5a856b85143",
			// 		"events": [
			// 			{
			// 				"event_type": "check",
			// 				"user_id": JSON.parse(localStorage.getItem("USER")).PhoneLogin,
			// 				"device_id": "10001",
			// 				"event_properties": {
			// 					"date": new Date().toLocaleString(),
			// 					"bin": JSON.parse(localStorage.getItem("COMPANY")).Bin,
			// 					"company_name": JSON.parse(localStorage.getItem("COMPANY")).FullName,
			// 					"payment_method": Object.keys(this.state.Income).filter(key => this.state.Income[key] !== 0).map(el => el.toLowerCase()).join(' & '),
			// 					"amount": total,
			// 					"platform": platform,
			// 					"znm": JSON.parse(localStorage.getItem("KKM")).Znm
			// 				}

			// 			}
			// 		]
			// 	},
			// 	{
			// 		headers: analiticsHeaders
			// 	}
			// 	)
			//  }catch (e) {
			// 	console.log('AMPLITUDE_ERROR')
			// }

			// window.removeEventListener('beforeunload', this.keepOnPage);
		} else {
			this.setState({
				IsAvailableForSale: false
			});
			if (total === 0)
				console.log('error')
		}
	};

	makeAmount = async (operation) => {
		// const total = this.state.Amount;
		if (this.state.Amount > 0) {
			this.saleButtonDisable();
			window.addEventListener('beforeunload', this.keepOnPage);
			this.setState({
				IsAvailableForSale: true,
				OperationErrorText: '',
			});
			try {
				const uid = await this.getUid(this.state.IdKkm);
				const response = await axios.post(
					`${BASE_URL}/kkms/${IdKkm}/${operation}`,
					this.getSale(this.state, operation),
					{
						headers: { ...headers, 'uid': uid }
					});

					const receiptCount = Number(JSON.parse(localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT'))) || 0;
					if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') !== null) {
						localStorage.setItem('OPEN_SHIFT_RECEIPT_COUNT', receiptCount + 1)
					}

				return this.getResponseSuccess(response, this.state.IdKkm);
			} catch (e) {
				this.setState(this.getResponseError(e));
				return false
			}

			// const analiticsHeaders = {
			// 	'Content-Type':'application/json',
			// 	'Accept':'*/*',
			// 	'Access-Control-Allow-Origin': '*',
			// 	'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
			// 	'Access-Control-Max-Age': '0',
			// 	'Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
			// 	'X-Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
			// 	'X-Webkit-CSP': "default-src *, connect-src *, script-src 'unsafe-inline' 'unsafe-eval' *; object-src *",
			// 	'Sec-Fetch-Dest': "empty",
			// 	'Sec-Fetch-Mode': "cors",
			// 	'Sec-Fetch-Site': "cross-site",
			// };

			// try {

			// 	await axios.post(`${AMPLITUDE_API_URL}/2/httpapi`,
			// 	{
			// 		"api_key": "d314c6311f60a96a3d7ec5a856b85143",
			// 		"events": [
			// 			{
			// 				"event_type": "check",
			// 				"user_id": JSON.parse(localStorage.getItem("USER")).PhoneLogin,
			// 				"device_id": "10001",
			// 				"event_properties": {
			// 					"date": new Date().toLocaleString(),
			// 					"bin": JSON.parse(localStorage.getItem("COMPANY")).Bin,
			// 					"company_name": JSON.parse(localStorage.getItem("COMPANY")).FullName,
			// 					"payment_method": "cash",
			// 					"amount": total,
			// 					"platform": platform,
			// 					"znm": JSON.parse(localStorage.getItem("KKM")).Znm
			// 				}

			// 			}
			// 		]
			// 	},
			// 	{
			// 		headers: analiticsHeaders
			// 	}
			// 	)
			//  }catch (e) {
			// 	console.log('AMPLITUDE_ERROR')
			// }

			// window.removeEventListener('beforeunload', this.keepOnPage);
		} else {
			this.setState({
				IsAvailableForSale: false
			});
			if (this.state.Amount === 0) {
				this.setState({
					OperationErrorText: 'Сумма операции не может быть равна нулю'
				})
			}
		}
	}

	makeRefund = async (operation) => {
		const total = this.state.Total;

		if (!this.state.CustomerIinError) {
			if (total > 0) {
				this.saleButtonDisable();
				window.addEventListener('beforeunload', this.keepOnPage);
				await this.setState({
					IsAvailableForSale: true,
					OperationErrorText: '',
					Income: {
						...this.state.Income,
						Cash: this.state.Income.Cash,
						NonCash: this.state.Income.NonCash,
						Mobile: this.state.Income.Mobile,
					}
				});
				try {
					const uid = await this.getUid(this.state.IdKkm);
					const response = await axios.post(
						`${BASE_URL}/kkms/${this.state.IdKkm}/${operation}`,
						this.getSale(this.state, operation),
						{
							headers: { ...headers, 'uid': uid }
						});
					let fromNew = this.state.NewPreCheck.length && this.state.NewPreCheck[0].id && !this.state.PreCheck.length

					const receiptCount = Number(JSON.parse(localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT'))) || 0;
					if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') !== null) {
						localStorage.setItem('OPEN_SHIFT_RECEIPT_COUNT', receiptCount + 1)
					}

					return await this.getResponseSuccess(response, this.state.IdKkm, fromNew);
				} catch (e) {
					this.setState(this.getResponseError(e));
				}

				const analiticsHeaders = {
					'Content-Type':'application/json',
					'Accept':'*/*',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
					'Access-Control-Max-Age': '0',
					'Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
					'X-Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
					'X-Webkit-CSP': "default-src *, connect-src *, script-src 'unsafe-inline' 'unsafe-eval' *; object-src *",
					'Sec-Fetch-Dest': "empty",
					'Sec-Fetch-Mode': "cors",
					'Sec-Fetch-Site': "cross-site",
				};

				// let userId = JSON.parse(localStorage.getItem("USER")).PhoneLogin;
				// let event = 'check';

				// let eventProperties = {
				// 	"date": new Date().toLocaleString(),
				// 	"bin": JSON.parse(localStorage.getItem("COMPANY")).Bin,
				// 	"company_name": JSON.parse(localStorage.getItem("COMPANY")).FullName,
				// 	"payment_method": "cash",
				// 	"amount": total,
				// 	"platform": platform,
				// 	"znm": JSON.parse(localStorage.getItem("KKM")).Znm
				// }

				// amplitude.getInstance().setUserId(userId)
				// amplitude.getInstance().logEvent(event, eventProperties)

				try {
					await axios.post(`${AMPLITUDE_API_URL}/2/httpapi`,
					{
						"api_key": "d314c6311f60a96a3d7ec5a856b85143",
						"events": [
							{
								"event_type": "check",
								"user_id": JSON.parse(localStorage.getItem("USER")).PhoneLogin,
								"device_id": "10001",
								"event_properties": {
									"date": new Date().toLocaleString(),
									"bin": JSON.parse(localStorage.getItem("COMPANY")).Bin,
									"company_name": JSON.parse(localStorage.getItem("COMPANY")).FullName,
									"payment_method": "cash",
									"amount": total,
									"platform": platform,
									"znm": JSON.parse(localStorage.getItem("KKM")).Znm
								}

							}
						]
					},
					{
						headers: analiticsHeaders
					}
					)
				 }catch (e) {
					console.log('AMPLITUDE_ERROR')
				}

				window.removeEventListener('beforeunload', this.keepOnPage);
			} else {
				this.setState({
					IsAvailableForSale: false
				});
				if (total === 0) {
					this.setState({
						OperationErrorText: 'Для проведения операции нужно заполнить минимум 1 позицию'
					})
				}
			}
		}
	};

	// makePurchaseRefund = async (operation) => {
	// 	const total = this.state.Total;
	// 	if (total > 0) {
	// 		this.saleButtonDisable();
	// 		window.addEventListener('beforeunload', this.keepOnPage);
	// 		await this.setState({
	// 			IsAvailableForSale: true,
	// 			OperationErrorText: '',
	// 			Income: {
	// 				...this.state.Income,
	// 				Cash: total
	// 			}
	// 		});
	// 		try {
	// 			const uid = await this.getUid(this.state.IdKkm);
	// 			const response = await axios.post(
	// 				`${BASE_URL}/kkms/${IdKkm}/${operation}`,
	// 				this.getSale(this.state, operation),
	// 				{
	// 					headers: { ...headers, 'uid': uid }
	// 				});
	// 			this.getResponseSuccess(response, this.state.IdKkm);
	// 		} catch (e) {
	// 			this.setState(this.getResponseError(e));
	// 		}
	// 		window.removeEventListener('beforeunload', this.keepOnPage);
	// 	} else {
	// 		this.setState({
	// 			IsAvailableForSale: false
	// 		});
	// 		if (total === 0) {
	// 			this.setState({
	// 				OperationErrorText: 'Для проведения операции нужно заполнить минимум 1 позицию'
	// 			})
	// 		}
	// 	}

	// };

	makePurchase = async (operation) => {
		const total = this.state.Total;
		//window.addEventListener('beforeunload', this.keepOnPage);
		if (!this.state.CustomerIinError) {
			if (total > 0) {
				// this.saleButtonDisable();
				await this.setState({
					// IsAvailableForSale: true,
					OperationErrorText: '',
					Income: {
						...this.state.Income,
						Cash: total
					}
				});
				try {
					const uid = await this.getUid(this.state.IdKkm);
					const response = await axios.post(
						`${BASE_URL}/kkms/${IdKkm}/${operation}`,
						this.getSale(this.state, operation),
						{
							headers: { ...headers, 'uid': uid }
						});
					let fromNew = this.state.NewPreCheck.length && this.state.NewPreCheck[0].id && !this.state.PreCheck.length

					const receiptCount = Number(JSON.parse(localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT'))) || 0;
					if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') !== null) {
						localStorage.setItem('OPEN_SHIFT_RECEIPT_COUNT', receiptCount + 1)
					}

					return await this.getResponseSuccess(response, this.state.IdKkm, fromNew);
				} catch (e) {
					this.setState(this.getResponseError(e));
				}

				const analiticsHeaders = {
					'Content-Type':'application/json',
					'Accept':'*/*',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
					'Access-Control-Max-Age': '0',
					'Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
					'X-Content-Security-Policy': 'default-src *, connect-src *, script-src *, object-src *',
					'X-Webkit-CSP': "default-src *, connect-src *, script-src 'unsafe-inline' 'unsafe-eval' *; object-src *",
					'Sec-Fetch-Dest': "empty",
					'Sec-Fetch-Mode': "cors",
					'Sec-Fetch-Site': "cross-site",
				};

				try {
					await axios.post(`${AMPLITUDE_API_URL}/2/httpapi`,
					{
						"api_key": "d314c6311f60a96a3d7ec5a856b85143",
						"events": [
							{
								"event_type": "check",
								"user_id": JSON.parse(localStorage.getItem("USER")).PhoneLogin,
								"device_id": "10001",
								"event_properties": {
									"date": new Date().toLocaleString(),
									"bin": JSON.parse(localStorage.getItem("COMPANY")).Bin,
									"company_name": JSON.parse(localStorage.getItem("COMPANY")).FullName,
									"payment_method": "cash",
									"amount": total,
									"platform": platform,
									"znm": JSON.parse(localStorage.getItem("KKM")).Znm
								}

							}
						]
					},
					{
						headers: analiticsHeaders
					}
					)
				 }catch (e) {
					console.log('AMPLITUDE_ERROR')
				}
				window.removeEventListener('beforeunload', this.keepOnPage);
			} else {
				this.setState({
					IsAvailableForSale: false
				});
				if (total === 0) {
					this.setState({
						OperationErrorText: 'Для проведения операции нужно заполнить минимум 1 позицию'
					})
				}
			}
		}
	};

	showQRPrecheck = async () => {
		headers = getHeaders(this.props.history)
		// const company = JSON.parse(localStorage.getItem("COMPANY"))
		try {
			const response = await axios.get(`${PULT_API_DEV_URL}/wallet/512412432423`)
			localStorage.setItem("C_ID", response.data.body.id);
		} catch (err) {
			console.log(err)
		}
		// try {
		// 	const res = await axios.get(`${PULT_API_DEV_URL}/companies/${company.Id}/wallet/balance`,
		// 		{headers: headers }
		// 	)
		// 	console.log("RESRES>>>", res)
		// } catch (error) {
		// 	console.log(error)
		// }
		this.setState({
			ModalQRCheck: {
				Status: true
			}
		})
	}

	closeModalQRCheck = () => {
		this.setState({
			ModalQRCheck: {
				Status: false
			}
		})
	}

	closeModalOnSuccess = () => {
		this.setState({
			ModalQRCheck: {
				Status: false
			},
			// IsSaleButtonEnabled: true,
			// CheckImg: '',
			Amount: 0,
			PreCheck: [],
			NewPreCheck: [],
			Total: 0,
			Change: 0,
			Lack: 0,

			Income: {
				Cash: 0,
				NonCash: 0,
				Mobile: 0,
			},
			IsAvailableForSale: true,
			IsSaleButtonEnabled: true,
		})
		this.resetPosition()
		this.resetNewPreCheck()
		localStorage.removeItem(this.state.StorageOperationName);
	}

	closeModal = () => {
		if (this.state.CheckImg) {
			// document.location.reload(true);
		}
		this.setState({
			Modal: {
				...this.state.Modal,
				Status: false
			},
			ModalCheck: {
				...this.state.ModalCheck,
				Status: false
			},
			IsSaleButtonEnabled: true,
			CheckImg: '',
			CheckId: '',
			CheckLink: '',
		})
	}

	showCheck = async () => {
		// await this.setState({
		//     Modal: {
		//         ...this.state.Modal,
		//         Status: false
		//     },
		//     ModalCheck: {
		//         ...this.state.ModalCheck,
		//         Status: true
		//     }
		// })
		// window.print();
		const receipt = document.getElementById("receipt");
		receipt.style.width = '280px'
		const printWindow = window.open("");
		printWindow.document.write(receipt.outerHTML);
		printWindow.print();
		// printWindow.close();
	}

	electronShowCheck = async () => {
		window.print();
	}

	keepOnPage = (e) => {
		e.returnValue = "Происходит отправка чека, уверены что хотите закрыть окно?";
	}

	saleButtonDisable = () => {
		this.setState({
			IsSaleButtonEnabled: false
		})
	}

	saleButtonEnable = () => {
		this.setState({
			IsSaleButtonEnabled: true
		})
	}

	// auth = (login, pass) => {
	//     axios.post(BASE_URL + '/auth',
	//         {
	//         "Login": login,
	//         "Password": pass
	//         })
	//         .then(response => {
	//             console.log(response);
	//             if (response.data.Status === 200){
	//                 let token = response.data.Data.Token
	//                 localStorage.setItem('token', token)
	//                 return true;
	//             }
	//         })
	//         .catch( e => console.log(e))
	//
	//     return false;
	// }

	setArticle = (data, fromNew, status) => {
		const currentSection = this.state.Sections.filter((section) => section.Id === data.IdSection)
		const SelectedOption = {
			value: data.Id, label: data.Name
		}
		const { label, value, idCompany, Id, IdCompany, IdGroupArticle, IdSection, Qr, Markup, Discount, ...dataToPosition } = data;
		let unit = [
			{
				Code: 796,
				Id: 1,
				NameKAZ: "дана",
				NameRU: "штука",
				ShortName: "шт",
			}
		]
		if (this.state.Units.length && data.IdUnit) {
			unit = this.state.Units.filter(u => (
				u.Id === data.IdUnit
			))
		}
		this.setState({
			Position: {
				...this.state.Position,
				...dataToPosition,
				MarkupPercent: status === OPERATION_TYPES.SALE.KEY ? Markup : 0,
				DiscountPercent: status === OPERATION_TYPES.SALE.KEY ? Discount : 0,
				Section: {
					...this.state.Position.Section,
					...currentSection[0],
				},
				Unit: unit[0],
				SelectedOption: SelectedOption
			}
		}, () => {
			this.setNameError(false)
			this.setPriceError(false)
			fromNew && this.editNewPreCheckPosition(status)
		});
	}

	exciseCheck = () => {
		this.setState({
			ExciseChecked: !this.state.ExciseChecked
		})
	}

	setSnForRefunds = (SnForRefunds) => {
		this.setState({ SnForRefunds });
	}

	getRefundPositionDetails = (refundPositions, PositionDetailsIsCashTransaction) => {
		const positionsDataFromLs = localStorage.getItem('PositionDetailsFromReport');
		const parsedPositionsDataFromLs = positionsDataFromLs ? JSON.parse(positionsDataFromLs) : [];
		const positionsData = refundPositions ? refundPositions : parsedPositionsDataFromLs;
		const isCashTransactionFromLs = localStorage.getItem('PositionDetailsIsCashTransaction') === 'true';
		const isCashTransaction = PositionDetailsIsCashTransaction ? PositionDetailsIsCashTransaction : isCashTransactionFromLs;

		const positions = positionsData.map(position => {
			return {
				id: position.Id ,
				Position: {
					Name: position.Name,
					Price: position.Price,
					Qty: position.Qty,
					Unit: position.Unit,
					Section: position.Section,
					Markup: position.Markup,
					MarkupPercent: Math.round((position.Markup * 100) / position.Price),
					Discount: position.Discount,
					DiscountPercent: Math.round((position.Discount * 100) / position.Price),
					Storno: position.Storno,
					Total: position.Total,
				}
			}
		})

		const totalAmount = positionsData.reduce((acc, curr) => {
			if (!curr.Storno) {
				return acc + curr.Total
			}
			return acc;
		}, 0).toFixed(2);

		const flatPositions = positions.map(item => {
			return {
					id: item.id,
					...item.Position
			};
		});

		this.setState({
			...this.state,
			NewPreCheck: [
				...positions,
			],
			PreCheck: [
				...flatPositions,
			],
			Income: {
				Cash: isCashTransaction ? Number(totalAmount) : 0,
    		NonCash: isCashTransaction ? 0 : Number(totalAmount),
				Mobile: 0,
			},
			Total: Number(totalAmount)
		})
	}

	// setSelectOption = (data) => {
	//     this.setState({
	//         Position: {
	//             ...this.state.Position,
	//             SelectedOption: { value: '', label: data}
	//         }
	//     })
	// }
	//
	//
	// nomenInputHandler = (data) => {
	//     // console.log('nomenInputHandler', data);
	//     this.setState({
	//         Position: {
	//             ...this.state.Position,
	//             Name: data,
	//             SelectedOption: { value: '', label: data}
	//         }
	//     })
	// }

	render() {
		return (
			<Store.Provider
				value={
					{
						Amount: this.state.Amount,
						Position: this.state.Position,
						PositionErrors: this.state.PositionErrors,
						DiscountType: this.state.DiscountType,
						Domains: this.state.Domains,
						DomainIndex: this.state.DomainIndex,
						DomainTitles: this.state.DomainTitles,
						quantityOfProducts: this.state.quantityOfProducts,
						Sections: this.state.Sections,
						Units: this.state.Units,
						sectionLS: this.state.sectionLS,
						unitLS: this.state.unitLS,
						domainLS: this.state.domainLS,
						stateChange: data => this._stateChange(data),
						positionInputHandler: (data, status) => this.positionInputHandler(data, status),
						priceInputHandler: (data, status) => this.priceInputHandler(data, status),
						nameInputHandler: (data, status) => this.nameInputHandler(data, status),
						discountTypeHandler: (data, status) => this.discountTypeHandler(data, status),
						exciseCodeInputHandler: (data, status) => this.exciseCodeInputHandler(data, status),
						inputNumberHandler: data => this.inputNumberHandler(data),
						amountHandlerExpense: (data, status) => this.amountHandlerExpense(data, status),
						amountHandler: (data, status) => this.amountHandler(data, status),
						positionDiscountMarkupHandler: (data, status) => this.positionDiscountMarkupHandler(data, status),
						sectionChangeHandler: (data, status) => this.sectionChangeHandler(data, status),
						unitChangeHandler: (data, status) => this.unitChangeHandler(data, status),
						domainChangeHandler: data => this.domainChangeHandler(data),
						iinHandler: data => this.iinHandler(data),
						CustomerIin: this.state.CustomerIin,
						CustomerIinError: this.state.CustomerIinError,
						cashHandler: data => this.cashHandler(data),
						cashRefundsHandler: data => this.cashRefundsHandler(data),
						addPreCheckPosition: (e, obj) => this.addPreCheckPosition(e, obj),
						addNewPreCheckPosition: () => this.addNewPreCheckPosition(),
						swapSums: () => this.swapSums(),
						setSum: (type) => this.setSum(type),
						resetPosition: this.resetPosition,
						IsAvailableForSale: this.state.IsAvailableForSale,
						PreCheck: this.state.PreCheck,
						NewPreCheck: this.state.NewPreCheck,
						Income: this.state.Income,
						Total: this.state.Total,
						Change: this.state.Change,
						Lack: this.state.Lack,
						GlobalError: this.state.GlobalError,
						GlobalErrorTitle: this.state.GlobalErrorTitle,
						stornoToggle: idx => this.stornoToggle(idx),
						newStornoToggle: id => this.newStornoToggle(id),
						setQty: (idx, num) => this.setQty(idx, num),
						Modal: this.state.Modal,
						ModalQRCheck: this.state.ModalQRCheck,
						closeModalQRCheck: this.closeModalQRCheck,
						showQRPrecheck: this.showQRPrecheck,
						closeModal: this.closeModal,
						showCheck: this.showCheck,
						electronShowCheck: this.electronShowCheck,
						ModalCheck: this.state.ModalCheck,
						IsSaleButtonEnabled: this.state.IsSaleButtonEnabled,
						CheckImg: this.state.CheckImg,
						CheckId: this.state.CheckId,
						CheckLink: this.state.CheckLink,
						makeSale: this.makeSale,
						makeRefund: this.makeRefund,
						makePurchase: this.makePurchase,
						makeAmount: this.makeAmount,
						resetPreCheck: this.resetPreCheck,
						resetNewPreCheck: this.resetNewPreCheck,
						closeModalOnSuccess: this.closeModalOnSuccess,
						OperationErrorText: this.state.OperationErrorText,
						Articles: this.state.Articles,
						setArticle: this.setArticle,
						Balances: this.state.Balances,
						ExciseChecked: this.state.ExciseChecked,
						exciseCheck: this.exciseCheck,
						shareSocial: (file) => this.shareSocial(file),
						NameError: this.state.NameError,
						PriceError: this.state.PriceError,
						setNameError: (value) => this.setNameError(value),
						setPriceError: (value) => this.setPriceError(value),
						getRefundPositionDetails: this.getRefundPositionDetails,
						setSnForRefunds: (value) => this.setSnForRefunds(value),
						totalReceiptCount: this.state.totalReceiptCount,
						// setSelectOption: data => this.sestSelectOption(data),
						// nomenInputHandler: data => this.nomenInputHandler(data),
						// auth: data => this.auth(data)
					}
				} >
					<React.Fragment>
						{this.props.children}
						{
							this.state.errorData && this.state.errorData.Status === 503 && (
								<Error503Modal />
							)
						}
					</React.Fragment>

			</Store.Provider>
		);
	}
}

const OperationProviderWithRouter = withRouter(OperationProvider);

export { Store, OperationProviderWithRouter as OperationProvider };
