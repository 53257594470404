import React from 'react';
import { Modal, Button, ModalBody } from 'react-bootstrap';
import isElectron from 'is-electron';
import { BASE_URL } from '../../Store/snippets';
import "../../Styles/layout/_only-for-shareCheck.scss"
import Whatsapp from "../Svg/whatsapp-logo.svg?react";
// import { ReactComponent as Telegram } from "../Svg/telegram-logo.svg?react";
import Mail from "../Svg/mail-logo.svg?react";
import MaskedInput from 'react-text-mask';
import Done from "../Svg/done-green.svg?react"
import { WHATSAPP_API_URL } from '../../constants/app';

export default class Example extends React.Component {

	state = {
		waInput: false,
		mailInput: false,
		number: "",
		email: "",
		waSent: false,
		mailSent: false
	}

	handleClickOpenWa = () => {
		this.setState({
			waInput: true,
		})
	}

	handleClickCloseWa = () => {
		this.setState({
			waInput: false,
		})
	}

	handleClickOpenMail = () => {
		this.setState({
			mailInput: true,
		})
	}

	handleClickCloseMail = () => {
		this.setState({
			mailInput: false,
		})
	}

	handleChangeNumber = e => {
        const { value } = e.target;
        this.setState({
            ...this.state,
			number: value.replace(/\D+/g, ''),
        });
	}
	
	handleChangeEmail = (e) => {
		this.setState({
			...this.state,
			email: e.target.value
		})
	}

	sendEmail = async() => {
		// window.open(`mailto:${this.state.email}?subject=Fiscal24&body=${BASE_URL}/www/showcheck?id=${this.props.docId}`)
		let data = {
			"action": "email",
			"recipient": this.state.email
		}
		const Token = localStorage.getItem("tokken");

		await fetch(`${BASE_URL}/www/sendcheck/${this.props.docId}`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${Token}`,
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body:  JSON.stringify(data)
		})
		.then((res) => {res.json()})
		.then((data) => {
			this.setState({
				mailInput: false,
				mailSent: true
			})
		})
	}

	sendWa = async() => {
		window.open(`${WHATSAPP_API_URL}/send?phone=${this.state.number}&text=Номер чека: ${this.props.docId}. Проверьте чек, перейдя по ссылке: ${this.props.docLink}`)
		setTimeout(() => {
			this.setState({
				waInput: false,
				waSent: true
			})
		}, 1000);
		
		// let data = {
		// 	"action": "whatsapp",
		// 	"recipient": this.state.number
		// }
		// await fetch(`${BASE_URL}/www/sendcheck/${this.props.docId}`, {
		// 	method: 'POST',
		// 	mode: 'cors',
		// 	cache: 'no-cache',
		// 	credentials: 'same-origin',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	redirect: 'follow',
		// 	referrerPolicy: 'no-referrer',
		// 	body:  JSON.stringify(data)
		// })
		// .then((res) => {res.json()})
		// .then((data) => {
		// 	this.setState({
		// 		waInput: false,
		// 		waSent: true
		// 	})
		// })
	}

	closeSent = () => {
		this.setState({
			waSent: false,
			mailSent: false
		})
	}

	render() {

		// const image = new Image();
		// image.src = `data:image/png;base64,${this.props.img}`;
		// console.log(image)


		return (
			<Modal show={this.props.show}
				centered
				onHide={this.props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.header}</Modal.Title>
				</Modal.Header>
				{
					this.props.img &&
					<Modal.Header className="d-none">
						{window.app && window.device &&
							<Button onClick={() => this.props.share(`data:image/png;base64, ${this.props.img}`)}>
								Отправить
							</Button>
						}
						<Button variant="secondary" onClick={this.props.onClose} className="ml-auto">
							Закрыть
						</Button>
					</Modal.Header>
				}
				<Modal.Body>
					<p>{this.props.body}</p>
					<p className='text-center d-print-block'>
						{
							this.props.img &&
							<img style={{ width: "250px" }} id="receipt" src={'data:image/png;base64, ' + this.props.img} alt="Check" />
						}
					</p>
				</Modal.Body>
				<Modal.Footer>
					{this.props.header === 'Операция прошла успешно' ? 
						<div style={{display: "flex", alignItems: "center"}}>
							<div className="mail-content" onClick={this.handleClickOpenMail}>
								<Mail/>
								<p>Email</p>
							</div>
							<div className="wa-content" onClick={this.handleClickOpenWa}>
								<Whatsapp/>
								<p>WhatsApp</p>
							</div>
						</div>
						: null
					}
					{this.state.waInput ? 
						<Modal show={this.props.show} centered>
							<Modal.Header>
							<Modal.Title>Введите номер получателя</Modal.Title>
							</Modal.Header>
							<ModalBody>
							<MaskedInput
								showMask={false}
								guide={true}
								// placeholderChar={'*'}
								placeholderChar={'\u2000'}
								mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
								inputMode="tel"
								id="inputPhone"
								name="number"
								className="form-control"
								placeholder="+7 (777) 000-00-00"
								autoComplete="phone"
								required
								value={this.state.number}
								onChange={this.handleChangeNumber}
								autoFocus
								onFocus={(e) => e.target.value = "+7"}
								onBlur={(e) => e.target.placeholder = "+7 (777) 000-00-00"}
							/>
							</ModalBody>
							<Modal.Footer>
								<Button variant="secondary" className="d-none d-lg-block" onClick={this.handleClickCloseWa}>
									Отмена
								</Button>
								<Button variant="primary" onClick={this.sendWa}>
										Далее
								</Button>
							</Modal.Footer>
						</Modal>
						:
						null
					}
					{this.state.waSent ? 
						<Modal show={this.props.show} centered>
							<Modal.Header>
								<Modal.Title>Чек успешно отправлен</Modal.Title>
							</Modal.Header>
							<ModalBody>
								<div style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
									<span style={{marginBottom: "30px"}}>Чек успешно отправлен на WhatsApp номер клиента</span>
									<Done/>
								</div>
							</ModalBody>
							<Modal.Footer>
								<Button variant="secondary" className="d-lg-block" onClick={this.closeSent}>
									Закрыть
								</Button>
							</Modal.Footer>
						</Modal>
						: null
					}
					{this.state.mailInput ? 
						<Modal show={this.props.show} centered>
							<Modal.Header>
								<Modal.Title>Введите адрес электронной почты</Modal.Title>
							</Modal.Header>
							<ModalBody>
								<input
									type="email"
									id="inputEmail"
									name="email"
									className="form-control"
									placeholder="example@mail.ru"
									autoComplete="email"
									required
									value={this.state.email}
									onChange={this.handleChangeEmail}
									autoFocus
								/>
							</ModalBody>
							<Modal.Footer>
								<Button variant="secondary" className="d-none d-lg-block" onClick={this.handleClickCloseMail}>
									Отмена
								</Button>
								<Button variant="primary" onClick={this.sendEmail}>
									Далее
								</Button>
							</Modal.Footer>
						</Modal>
						:
						null
					}
					{this.state.mailSent ? 
						<Modal show={this.props.show} centered>
							<Modal.Header>
								<Modal.Title>Чек успешно отправлен</Modal.Title>
							</Modal.Header>
							<ModalBody>
								<div style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
									<span style={{marginBottom: "30px"}}>Чек успешно отправлен на электронную почту клиента</span>
									<Done/>
								</div>
							</ModalBody>
							<Modal.Footer>
								<Button variant="secondary" className="d-lg-block" onClick={this.closeSent}>
									Закрыть
								</Button>
							</Modal.Footer>
						</Modal>
						: null
					}
					{
						this.props.img && isElectron() &&
						<Button variant="primary" onClick={this.props.onElectronCheck} className="d-none d-lg-block">
							Распечатать
                        </Button>
					}
					{
						this.props.img && !isElectron() &&

						<Button variant="primary" onClick={this.props.onCheck} className="d-none d-lg-block">
							Распечатать
                        </Button>
					}

					{this.props.img && window.app && window.device &&
						<Button onClick={() => this.props.share(`data:image/png;base64, ${this.props.img}`)}>
							Отправить
						</Button>
					}
					<Button variant="secondary" onClick={this.props.onClose}>
						Закрыть
                	</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
