
import { codePush, InstallMode } from '@nerdfrenzs/capacitor-codepush';

var app = {
  language:{
    restartConfirmMessage: 'Обновления были загружены, презагрузить приложение?',
    restartConfirmAgree: 'Перезагрузить',
    restartConfirmStay: 'Остаться',
    updateMessage: 'Доступны новые обновления, установить их?',
    installMessage: 'Установить',
    ignoreMessage: 'Нет',
    title: 'Обновления',
    checkUpdate: 'Проверка обновлений.',
    download: 'Загрузка пакета.',
    waitting: 'Ожидание действия пользователя.',
    install: 'Устанавливается обновление.',
    successUpdate: 'Приложение обновлено.',
    cancel: 'Обновление отменено.',
    successInstall: 'Обновление установлено и будет применено после перезапуска.',
    error: 'Произошла неизвестная ошибка.',
    clickBackground: 'Нажмите для синхронизации фона',
    clickDialog: 'Нажмите для синхронизации с помощью диалога',
    restart: 'Перезапуск',
    allowed: 'разрешено',
    forbidden: 'запрещено',
    metaData: 'Нажмите для обновления метаданных',
    runBinary: 'Работает бинарная версия',
    next: 'Продолжит'
  },
  // Application Constructor
  initialize: function() {
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },

  // deviceready Event Handler
  //
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady: function() {
    if (Capacitor.isNativePlatform()) {
        codePush.sync(
          this.codePushStatusDidChange.bind(this),
          { 
            installMode: InstallMode.ON_NEXT_RESTART,
            updateDialog: false
          },
          this.codePushDownloadDidProgress.bind(this),
        ).then((syncStatus) => {
          console.log("CodePush sync status:", syncStatus);
        })
        .catch((error) => {
          console.error("CodePush sync error:", error);
        });
    }
  },
  codePushDownloadDidProgress: function({ receivedBytes, totalBytes }) {
    console.log('progress', receivedBytes / totalBytes || 10);
  },
  codePushStatusDidChange: function(syncStatus) {
    console.log(syncStatus, syncStatus);
    switch (syncStatus) {
    case SyncStatus.CHECKING_FOR_UPDATE:
        console.log('syncMessage', this.language.checkUpdate);
        break;
    case SyncStatus.DOWNLOADING_PACKAGE:
        console.log('syncMessage', this.language.download);
        break;
    case SyncStatus.AWAITING_USER_ACTION:
        console.log('syncMessage', this.language.waitting);
        break;
    case SyncStatus.INSTALLING_UPDATE:
        console.log('syncMessage', this.language.install);
        break;
    case SyncStatus.UP_TO_DATE:
        console.log('syncMessage', this.language.successUpdate, {load: true, progress: false});
        break;
    case SyncStatus.UPDATE_IGNORED:
        console.log('syncMessage', this.language.cancel, {progress: false});
        break;
    case SyncStatus.UPDATE_INSTALLED:
        console.log('syncMessage', this.language.successInstall, {progress: false});
        break;
    case SyncStatus.UNKNOWN_ERROR:
        console.log('syncMessage', this.language.error, {progress: false, load: true});
        break;
    default:
    }
  }
};

app.initialize();

console.log('capacitor-app.js loaded');