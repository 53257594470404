/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getHeaders, getUser, getKkm, formatDate, getError } from '../../Store/snippets';
import ReportXlayout from './ReportXlayout';
import PreloaderFullScreen from '../Preloader/Preloader';
// import Header from '../Header/Header';

let headers;
class ReportX extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			user: {},
			date: '',
			mounted: false,
			idKkm: null,
			error: {
				status: false,
				title: '',
				text: ''
			}
		};
	}

	goBack = () => {
		this.props.history.goBack();
	}

	async componentDidMount() {
		headers = getHeaders(this.props.history);
		const currentDate = new Date();
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		const date = formatDate(currentDate);
		const user = getUser(this.props.history);
		const kkm = getKkm(this.props.history);

		if (kkm.Lock === false) {
			await this.setState({
				...this.state,
				user,
				idKkm,
				error: {
					...this.state.error,
					status: 410,
					title: `Отчеты недоступны`,
					text: `Вы не можете просматривать отчеты при закрытой смене. Пожалуйста, откройте смену для просмотра отчетов`
				},
				mounted: true
			})
			return
		}

		axios.get(`${BASE_URL}/shifts/${kkm.IdShift}/x`, { headers }).then(resp => {
			this.setState({
				...this.state,
				report: resp.data.Data,
				user,
				idKkm,
				date,
				mounted: true
			})
		}).catch(err => {
			// console.log(err.response);
			const resp = getError(err, this.props.history);
			this.setState({
				...this.state,
				user,
				idKkm,
				error: {
					...this.state.error,
					status: true,
					title: `Ошибка: ${resp.Status}`,
					text: resp.Message
				},
				mounted: true
			})
		});

	}

	render() {
		return (
			this.state.mounted ?
				<ReportXlayout {...this.state} goBack={this.goBack} formatDate={formatDate} {...this.props} />
				: <PreloaderFullScreen />
		)
	}
}

export default ReportX;