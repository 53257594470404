/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import style from './Sale.module.css';
import Modal from '../../Modal/operationModal';
import ModalCheck from '../../Modal/operationModalCheck';
import { Store } from "../../../Store/OperationProvider";
import Spinner from '../../Common/Spinner';
// import Header from '../../Header/Header';
import MobileNav from '../../MobileNav/MobileNav';
import { Link } from 'react-router-dom';
import {setAppVersion} from "../../../Store/appVersion";
import HeaderVersion from "../../NewDesign/HeaderVersion/HeaderVersion";

class Expense extends Component {

    handleInputNumber = (e, func) => {
        func({ value: Number(e.target.value.replace(/\+|-/ig, '')) });
    };

    handleFocus = (event) => event.target.select();

    goNew = () => {
        this.props.history.push('expense_new')
        setAppVersion(true)
    }

    render() {
        let kkmid = JSON.parse(localStorage.getItem("KKM"))
        // const oldVersion = {
        //     float: "right",
        //     marginTop: "10px",
        //     width: "106px",
        //     height: "38px",
        //     background: "#FFFFFF",
        //     borderRadius: "8px",
        //     display: "flex",
        //     justifyContent: "space-between",
        //     alignItems: "center",
        //     padding: "4px 13px 4px 8px",
        //     border: "1px solid #D7D7D7",
        //     cursor: "pointer"
        // }
        // const oldVersionText = {
        //     color: "#575757",
        //     lineHeight: "15px",
        //     fontSize: "12px",
        //     margin: "0"
        // }

        return (
            <Store.Consumer>
                {
                    context => (
                        <React.Fragment>
                            {
                                window.matchMedia("(min-width: 1100px)").matches ?
                                    <HeaderVersion isActive={true} balances={context.Balances} {...this.props} />
                                    :
                                    <MobileNav isActive={true} />
                            }
                            <div className={`${style.NewSale}`}>
                                <div className="container pt-4 d-print-none">
                                    <div className='d-flex justify-content-between'>
                                        <Link class="btn btn-secondary btn-lg btn-sm-block mb-3" to={`/www/kkms/${kkmid.Id}`} style={{paddingLeft: "11px", paddingRight: "11px"}}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a role="button">← Вернуться в кассу</a>
                                        </Link>
                                        {/* <div style={oldVersion} onClick={() => this.goNew()}>
                                            <p style={oldVersionText}>Новый вид<br />сайта</p>
                                            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.6665 4L0.666504 8V0L4.6665 4Z" fill="#8E8E8E"/>
                                            </svg>
                                        </div> */}
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                        <h1 className="h2">Служебный расход</h1>
                                        <div className="btn-toolbar mb-2 mb-md-0">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 order-md-1 pr-3">
                                            <form className="needs-validation" noValidate onSubmit={(e) => context.makeAmount(e, 'expenses')}>

                                                <div className="form-row pt-2">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="field">Сумма расхода</label>
                                                        <input autoComplete="off" type="number" className={`form-control form-control-lg ${context.PositionErrors.Name && 'is-invalid'}`} id="field"
                                                            inputMode="numeric"
                                                            placeholder="0"
                                                            onChange={e => {this.handleInputNumber(e, context.amountHandlerExpense)} }
                                                            value={context.Amount === 0 ? '' : context.Amount}
                                                            onFocus={this.handleFocus}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                            {
                                                !context.IsAvailableForSale &&
                                                <div className="mb-4 mt-1 alert alert-danger">
                                                    {
                                                        context.Amount === 0 &&
                                                        <span>{context.OperationErrorText}</span>
                                                    }
                                                </div>

                                            }

                                            <button className="btn btn-success btn-lg"
                                                onClick={(e) => context.makeAmount('expenses')}
                                                disabled={!context.IsSaleButtonEnabled}
                                            >
                                                Оформить чек
                                            {
                                                    !context.IsSaleButtonEnabled &&
                                                    <Spinner />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <Modal onClose={context.closeModal} onCheck={context.showCheck} onElectronCheck={context.electronShowCheck} show={context.Modal.Status} header={context.Modal.Header} body={context.Modal.Body} img={context.CheckImg} docId={context.CheckId} docLink={context.CheckLink} share={context.shareSocial} />
                                <ModalCheck onClose={context.closeModal} show={context.ModalCheck.Status} body={context.Modal.Body} img={context.CheckImg} />
                            </div>
                        </React.Fragment>
                    )
                }
            </Store.Consumer>
        )
    }
}

export default Expense;
