import React from 'react';
import { Link } from 'react-router-dom';
import isElectron from 'is-electron';
import Spinner from '../Common/Spinner';
import Modal from '../Modal/closeShiftModal';

const NewShiftLayout = ({ balances, kkm, shiftOpen, shiftOpenByAnotherCashier, shiftBlocked, shiftErrorText, pending, shiftExpired, openShift, openModal, history }) => {
  return (
    <React.Fragment>
      <main role="main" className="container">
        <div className="jumbotron shadow newshift">
          {
            isElectron() || localStorage.getItem('KKM_GARANT') || localStorage.getItem('KKM_LOMBARD') !== null ? (
              null
            ) : (
              <div className='shiftNewWrapper'>
                <Link className="btn btn-secondary btn-lg btn-sm-block mb-3" to='/www/comps' role="button">
                  ← Назад к выбору касс
                </Link>
              </div>
            )}
          {shiftOpen ? (
            <React.Fragment>
              <h1 className="pb-2">Смена №{kkm.ShiftIndex}</h1>
              <div className="alert alert-success d-none d-md-block" role="alert">
                <h4 className="alert-heading pb-2">Автоматическое тестирование пройдено</h4>
                <p><span role='img' aria-label='checked'>✅</span> Основные блоки и узлы</p>
                <p><span role='img' aria-label='checked'>✅</span> Программное обеспечение ККМ</p>
                <p><span role='img' aria-label='checked'>✅</span> Контроль целостности данных сохраненных в накопителе фискальных данных</p>
                <p><span role='img' aria-label='checked'>✅</span> Связь с сервером оператора фискальных данных</p>
                <hr />
                <p className="mb-0">Касса проверена и готова к работе</p>
              </div>
            </React.Fragment>
          ) : (
              <h1 className="pb-2">Новая смена</h1>
            )}

          <p className="lead">
            Касса <strong>№{kkm.Id}</strong>
          </p>
          <p className="lead">
            ЗНМ <strong>{kkm.Znm}</strong>
          </p>
          <p className="lead">
            Зарегистрирована на <strong>{kkm.Company.FullName}</strong>
          </p>
          <p className="lead">
            Адрес{' '}
            <strong>
              {kkm.Address.Town.Name}, {kkm.Address.Street} {kkm.Address.House}, {kkm.Address.Flat}
            </strong>
          </p>
          <hr />
          {balances.map((balance, idx) => {
            return (
              <p className="lead" key={balance.Id}>
                {balance.TypeBalance.Name} <strong>{new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸</strong>
              </p>
            );
          })}
          <hr className="my-4" />
          {shiftErrorText &&
            <div
              className={`alert ${kkm.IdStatusKkm === 1 ? 'alert-warning' : 'alert-danger'}   `}
              role="alert"
            >
              {shiftErrorText}
            </div>
          }
          {
            !shiftBlocked && (
              <React.Fragment>
                {shiftOpen ? (
                  <React.Fragment>
                    {!shiftExpired && (
                      <Link
                        className="btn btn-primary btn-lg btn-sm-block mr-sm-2"
                        to={`/www/kkms/${kkm.Id}/sale`}
                        role="button"
                      >
                        Оформить чек
                      </Link>
                    )}
                  </React.Fragment>
                ) :
                  (
                    <React.Fragment>
                      { pending
                        ? <button
                          className="btn btn-primary btn-lg  mr-sm-2 btn-sm-block"
                          disabled
                        >
                          Открываем <Spinner className="mr-2" />
                        </button>
                        : <button
                          className="btn btn-primary btn-lg btn-sm-block mr-sm-2"
                          onClick={openShift}
                        >
                          Открыть смену
                        </button>
                      }
                    </React.Fragment>
                  )}
              </React.Fragment>
            )
          }
          <Link
              className="btn btn-primary btn-lg mr-sm-2 btn-sm-block"
              to={`/www/shifts/${kkm.IdShift}/log`}
              role="button"
              onClick={() => history.push(`/www/shifts/${kkm.Id}/log`)}
          >
              Журнал операций
          </Link>
          {
            shiftOpen &&
            <React.Fragment>
              {pending ?
                <button
                  className="btn btn-warning btn-lg  mr-sm-4 btn-sm-block"
                  data-toggle="modal"
                  data-target="#closeShiftDialog"
                  disabled
                >
                  Закрываем <Spinner className="" />
                </button>
                :
                <button
                  className="btn btn-warning btn-lg  mr-sm-4 btn-sm-block"
                  data-toggle="modal"
                  data-target="#closeShiftDialog"
                  onClick={openModal}
                >
                  Закрыть смену
                </button>
              }
            </React.Fragment>
          }
          {
            shiftOpen ? (
              <a
                className="btn btn-warning btn-lg mr-sm-2 btn-sm-block"
                href={`/www/kkms/${kkm.Id}/settings/forbiden`}
                role="button"
              >
                Настройки
              </a>
            ) : shiftOpenByAnotherCashier ? (
                <a
                  className="btn btn-warning btn-lg mr-sm-2 btn-sm-block"
                  href={`/www/kkms/${kkm.Id}/settings/forbiden`}
                  role="button"
                >
                  Настройки
                </a>
              ) : (
              <a
                className="btn btn-warning btn-lg mr-sm-2 btn-sm-block"
                href={`/www/kkms/${kkm.Id}/settings`}
                role="button"
              >
                Настройки
              </a>
            )
          }
          <Link
            className="btn btn-secondary btn-lg btn-sm-block"
            role="button"
            to={`/www/kkms/${kkm.Id}/zlog`}
          >
            Архив Z-отчётов
        </Link>
        </div>
      </main>
      <Modal />
    </React.Fragment>
  );
};

export default NewShiftLayout;
