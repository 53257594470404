import style from '../styles/components/calendar.module.scss'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import "../styles/components/cal.scss";
import {DateRange} from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import React from "react";
import ArrowUpSLine from "../svg/arrow-up-s-line.svg?react";
import ArrowDownSLine from "../svg/arrow-down-s-line.svg?react";
import ArrowRightSLine from "../svg/arrow-right-s-line.svg?react";
import { useTranslation } from "react-i18next";
import { findPeriodTypes } from '../../../Utils/calendar';
import OutsideClicker from '../NewOperations/helpers/OutsideClicker';

const NewCalendar = ({ setCalendarOptionsIsOpen, calendarOptionsIsOpen, datePeriod, getPeriod, setDateRange, dateRange, calendarIsOpen, setCalendarIsOpen }) => {
	const { t, i18n } = useTranslation()

	return (
		<div className={`${style.calendar}`} >
			<div
				className={style['calendar__dropdown']}
				onClick={() => {
					setCalendarOptionsIsOpen(!calendarOptionsIsOpen)
					setCalendarIsOpen(false)
				}}
			>
				<div className={style['calendar__date-title']}>
					<span>{datePeriod.from} - {datePeriod.to}</span>
				</div>
				{  calendarOptionsIsOpen ? <ArrowUpSLine/> : <ArrowDownSLine/> }
			</div>
				<OutsideClicker setShow={() => {
					setCalendarIsOpen(false)
					setCalendarOptionsIsOpen(false)
					}}>
				{ (calendarOptionsIsOpen && !calendarIsOpen) &&
				<div className={style['calendar__options']}>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(1)}}
				>
					<span>{ t('report.yesterday') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(2)}}
				>
					<span>{ t('report.three_days') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(3)}}
				>
					<span>{ t('report.week') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(4)}}
				>
					<span>{ t('report.month') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(5)}}
				>
					<span>{ t('report.quarter') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(6)}}
				>
					<span>{ t('report.half_year') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => {getPeriod(7)}}
				>
					<span>{ t('report.year') }</span>
				</div>
				<div
					className={style['calendar__option']}
					onClick={() => setCalendarIsOpen(true)}
				>
						<span className={style['mr-10']}>
							{ t('report.select_period') }
						</span>
					<ArrowRightSLine/>
				</div>
				
			</div>
}
					{ calendarIsOpen &&
						<div className={style['calendar__wrapper']}>
							<DateRange
								onChange={(date) => {
									setDateRange([date.selection])
									if (date.selection.startDate !== date.selection.endDate) {
										const type = findPeriodTypes(date.selection)
										getPeriod(type, [date.selection][0])
									}
								}}
								showSelectionPreview={true}
								moveRangeOnFirstSelection={false}
								ranges={dateRange}
								months={2}
								direction="horizontal"
								locale={locales[i18n.language][i18n.language]}
								preventSnapRefocus={true}
								calendarFocus="backwards"
								shouldCloseOnSelect={true}
							/>
						</div>
						}
				</OutsideClicker>
		</div>
	)
}

export default NewCalendar
