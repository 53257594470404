/* eslint-disable eqeqeq */
import { DISCOUNT_TYPES } from "../constants/app"

export const getPositionTotal = ({ Qty, Price, Markup, Discount, DiscountType }, status) => {
  const markupValue = isNaN(Markup) ? 0 : Number(Markup)
  const discountValue = isNaN(Discount) ? 0 : Number(Discount)

  if(status === "purchase") {
    return Qty * Price
  }
  if(status === "refund") {
    return Qty * Price
  }
  if(status === "purchase_refund") {
    return Qty * Price
  }
  if(status === "sale") {
    if (DiscountType === DISCOUNT_TYPES.FIXED) {
      // СуммаВсегоПоСтроке = Количество*Цена - СуммаСкидки + Наценка.
      return Qty * Price - discountValue + markupValue;
    }
    return Qty * (Price - discountValue + markupValue);
  }
  return Qty * (Price - discountValue + markupValue);
}


export const getPositionNds = (total, nds) => {
  if (Number(nds) > 0 && Number(total)) {
    return (total * nds) / (100 + nds);
  }
}


// Рассчет скидки или наценки
// Скидка = Цена позиции * Процент скидки в позиции / 100
// Наценка = Цена позиции * Процент наценки в позиции / 100
export const calculateDiscountOrMarkup = (positionAmount, valueOrPercent, DiscountType) => {
  if (isNaN(valueOrPercent) || !valueOrPercent || !positionAmount) {
    return 0;
  }

  if (DiscountType === DISCOUNT_TYPES.PERCENT) {
    // В задаче сказано: СуммаВсегоПоСтроке округляется вниз. Дробная часть от округления добавляется в СуммуСкидки
    // Пример: стоимость товара 30 тг. Скидка вышла 2.10 (7%). Итоговая сумма получается 27.90
    // Получается нужно вывести итого: 27 тг
    // а 0.90 я должна прибавить к скидке, итого у меня скидка будет 3.00, проще говоря, скидку можно округлить вверх
    return Math.ceil(Number(positionAmount * valueOrPercent / 100));
  }

  return Number(valueOrPercent);
}


export const bincheck = (bin) => {
  let b1 = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ];
  let b2 = [ 3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2 ];
  let a = [];
  let controll = 0;

  for (let i = 0; i < 12; i++) {
    a[i] = parseInt(bin.substring(i, i+1));
    if (i < 11) {
        controll += a[i]*b1[i];
    }
  }

  controll = controll % 11;

  if (controll === 10) {
      controll = 0;
      for (let i = 0; i < 11; i++) {
          controll += a[i]*b2[i];
          controll = controll % 11;
      }
  }

  return controll === a[11];
}