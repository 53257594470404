import React, { useState, useEffect } from 'react';
import BarcodeScanner from './BarcodeScanner';
import { Modal, ModalBody } from 'react-bootstrap';
import "../Settings/styles/articlesEdit.scoped.css"
import { Capacitor } from '@capacitor/core';
import { openScanBarcode } from '../../Utils/scan-barcode';

const ArticleEditModal = ({ addArticle, sections, units, currentArticle, show, onClose }) => {
  
  const [Name, setName] = useState("")
  const [Qr, setQr] = useState("")
  const [IdSection, setIdSection] = useState(sections[0].Id)
  const [IdUnit, setIdUnit] = useState(units[0].Id)
  const [Price, setPrice] = useState("")
  const [Discount, setDiscount] = useState("")
  const [Markup, setMarkup] = useState("")
  const [Active, setActive] = useState(false)
  const [NameError, setNameError] = useState("")
  const [BarcodeError, setBarcodeError] = useState("")
  const [showScanner, setShowScanner] = useState(false)

  const openNativeBarcodeScan = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const result = await openScanBarcode();

        setQr(result);
      } else {
        setShowScanner(true);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setNameError("")
    setBarcodeError("")
    if (currentArticle.Id) {
      setName(currentArticle.Name)
      setQr(currentArticle.Qr)
      setIdSection(currentArticle.IdSection)
      setIdUnit(currentArticle.IdUnit)
      setPrice(currentArticle.Price)
      setDiscount(currentArticle.Discount)
      setMarkup(currentArticle.Markup)
      setActive(currentArticle.Active)
    } else {
      setName("")
      setQr("")
      setIdSection(sections[0].Id)
      setIdUnit(units[0].Id)
      setPrice("")
      setDiscount("")
      setMarkup("")
      setActive(false)
    }
  }, [currentArticle])

  const createArticle = () => {
    let data = {
      Name,
      Qr,
      "IdSection": parseInt(IdSection),
      "IdUnit": parseInt(IdUnit),
      "Price": Price ? parseFloat(Price) : 0,
      "Discount": Discount ? parseFloat(Discount) : 0,
      "Markup": Markup ? parseFloat(Markup) : 0,
      Active,
      Id: currentArticle.Id || undefined
    }
    if (Name) {
      setNameError("")
    } else {
      setNameError("Пожалуйста, укажите название товара")
    }
    if (Qr) {
      if (Qr.length === 8 || Qr.length === 13) {
        setBarcodeError("")
      } else {
        setBarcodeError("Длина штрихкода должна быть 8 или 13")
      }
    } else {
      setBarcodeError("")
    }
    if (Qr) {
      if (Name && (Qr.length === 8 || Qr.length === 13)) {
        addArticle(data).then(() => onClose())
      }
    } else {
      if (Name) {
        addArticle(data).then(() => onClose())
      }
    }
  }

  const errorTextStyles = {
    color: 'red',
    fontSize: '14px',
    margin: '5px 0 0 0'
  }

  return (
    <React.Fragment>
      {
        !showScanner ? (
          <Modal
            show={true && !showScanner}
            centered
            onHide={() => onClose()}
            className="modal-article-edit"
          >
            <ModalBody>
              <div className="heading">
                <h1 className="title">
                  {
                    currentArticle.Id ? 'Редактор товара' : 'Добавить новый товар'
                  }
                </h1>
              </div>
              
              <div className="pt-3">
                <div className="form-group">
                  <label htmlFor="Qr" className="col-form-label">Штрих-код</label>
                  <div>
                    <div className="input-wrapper-barcode">
                      <input type="text" placeholder="Введите штрихкод" className="form-control barcode-input" maxLength="13" value={Qr} onChange={e => setQr(e.target.value.replace(/\D/,''))} id="Qr" name="Qr" />
                      <div className="barcode" onClick={openNativeBarcodeScan}>
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.666992 0.333374H2.33366V13.6667H0.666992V0.333374ZM4.00033 0.333374H5.66699V13.6667H4.00033V0.333374ZM6.50033 0.333374H9.00033V13.6667H6.50033V0.333374ZM9.83366 0.333374H11.5003V13.6667H9.83366V0.333374ZM12.3337 0.333374H14.0003V13.6667H12.3337V0.333374ZM14.8337 0.333374H17.3337V13.6667H14.8337V0.333374Z" fill="#575757"/>
                          </svg>
                      </div>
                    </div>
                    {
                      BarcodeError && <p style={errorTextStyles} class="error-text">{BarcodeError}</p>
                    }
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Name" className="col-form-label">Название товара</label>
                  <div>
                    <input type="text" placeholder="Введите название" className="form-control" value={Name} onChange={e => setName(e.target.value)} id="Name" name="Name" />
                    {
                      NameError && <p style={errorTextStyles} class="error-text">{NameError}</p>
                    }
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Name" className="col-form-label">Единица изменения</label>
                  <div>
                    <select className="form-control old-padding" id="Section" name="Section" value={IdUnit} onChange={e => setIdUnit(e.target.value)}>
                      {
                        units.map(u => (
                          <option key={u.Id} value={u.Id}>{u.NameRU}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="form-group-wrapper" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="form-group">
                    <label htmlFor="Price" className="col-form-label">Цена</label>
                    <div>
                      <input type="number" step=".01" className="form-control" value={Price} onChange={e => setPrice(e.target.value)} id="Price" name="Price" placeholder="00.00" style={{width: '180px'}}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="Name" className="col-form-label">Секция</label>
                    <div>
                      <select className="form-control old-padding" id="Section" name="Section" value={IdSection} onChange={e => setIdSection(e.target.value)} style={{width: '180px'}}>
                        {
                          sections.map(s => (
                            <option key={s.Id} value={s.Id}>{s.Name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group-wrapper" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="form-group">
                    <label htmlFor="Discount" className="col-form-label">Скидка %</label>
                    <div>
                      <input type="number" step=".01" className="form-control" value={Discount} onChange={e => setDiscount(e.target.value)} id="Discount" name="Discount" placeholder="00.00" style={{width: '180px'}} />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="Markup" className="col-form-label">Наценка %</label>
                    <div>
                      <input type="number" step=".01" className="form-control" value={Markup} onChange={e => setMarkup(e.target.value)} id="Markup" name="Markup" placeholder="00.00" style={{width: '180px'}} />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="toggle-wrapper">
                    <p className="text">Товар доступен для продажи</p>
                    <div className={Active ? 'type-check' : 'type-check-off'} onClick={e => setActive(!Active)} >
                      <div className="type-circle"></div>
                    </div>
                  </div>
                  <p className="info-text">
                    {
                      Active ? (
                        'На странице оформления продажи, товар можно выбрать из выпадающего списка и все данные заполнятся автоматически'
                      ) : (
                        'Товар не будет отображаться в выпадающем списке на странице оформления продажи'
                      )
                    }
                  </p>
                </div>

                <div className="form-group">
                  <div>
                    <button onClick={() => createArticle()} className="btn btn-primary btn-lg save">Сохранить</button>
                  </div>
                  <div>
                    <button onClick={() => onClose()} className="btn btn-secondary btn-lg">Закрыть</button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : (
          <BarcodeScanner setCode={setQr} setShowScanner={setShowScanner} showScanner={showScanner} />
        )
      }
    </React.Fragment>
  )
}

export default ArticleEditModal;