import React, { forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import s from "./FormGroup.module.scss";

export const Form = ({ onSubmit, children, formOptions }) => {
  const methods = useForm(formOptions ? formOptions : null);
  return (
    <form
      className={s.form}
      onSubmit={methods.handleSubmit(onSubmit)}
      aria-label="form"
    >
      {children(methods)}
    </form>
  );
};

export const Label = ({ htmlFor, labelText }) => (
  <label htmlFor={htmlFor} className={s.label}>
    {labelText}
  </label>
);

export const Input = forwardRef((props, ref) => (
  <input
    ref={ref}
    {...props}
    className={`${s.input} ${props.className ? s[props.className] : ""}`}
  />
));

export const Error = ({ errorText }) => (
  <span className={s.errorText}>{errorText}</span>
);

export const MaskedInput = ({ name, mask, control, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, ref, value } }) => (
        <PatternFormat
          format={mask}
          onBlur={onBlur}
          onChange={onChange}
          id={name}
          ref={ref}
          value={value}
          {...rest}
          className={`${s.input} ${rest.className ? s[rest.className] : ""}`}
        />
      )}
    />
  );
};
