import React from 'react'
import RiArrowRightSFill from '../../NewDesign/svg/arrow-right-s-fill.svg?react'
import s from './newdesignbtn.module.scss'

const NewDesignBtn = ({ setNewDesign }) => (
	<button onClick={setNewDesign} className={s.wrapper}>
		Новый вид сайта <RiArrowRightSFill />
	</button>
)

export default NewDesignBtn
