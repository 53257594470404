import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import isElectron from "is-electron";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";

import { WHATSAPP_API_URL } from "../../../constants/app";
import { BASE_URL } from "../../../Store/snippets";
import { shareFile } from "../../../Utils/share-file";

import Success from "./images/success.svg?react";
import Printer from "./images/printer.svg?react";
import Goback from "./images/goback.svg?react";
import Spinner from "./images/spinner.svg?react";
import SpinnerGray from "./images/spinner-gray.svg?react";
import ToastIcon from "./images/toasticon.svg?react";
import Share from "./images/share.svg?react";
import Close from "./images/close.svg?react";
import "react-toastify/dist/ReactToastify.css";
import style from "./styles/sendCheck.module.scss";
import mainStyle from "./styles/check.module.scss";

const Check = ({ context, history }) => {
  const { t } = useTranslation();

  const LANGUAGES = {
    KAZAKH: 1,
    RUSSIAN: 2,
  };

  const SEND_METHODS = {
    EMAIL: 1,
    WHATSAPP: 2,
  };

  const [lang, setLang] = useState(LANGUAGES.RUSSIAN);
  const [active, setActive] = useState(SEND_METHODS.EMAIL);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [sending, setSending] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (window.document)
      document.body.style.overflow = show ? "hidden" : "auto";
    return () => (document.body.style.overflow = "auto");
  }, [show]);

  let checkData = JSON.parse(localStorage.getItem("checkData"));

  const sendEmail = () => {
    const Token = localStorage.getItem("tokken");

    if (email.length) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError(t("other.check_email_correctness"));
      } else {
        setEmailError(false);
        setSending(true);
        let data = {
          action: "email",
          recipient: email,
        };
        fetch(`${BASE_URL}/www/sendcheck/${checkData.CheckId}`, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify(data),
        })
          .then((res) => {
            return res.json();
          })
          .then((d) => {
            setSending(false);
            if (d.Status === 200) {
              setEmail("");
              toast.success(
                `${t("other.check_email_sent_successfully")}: ${email}.`,
                { theme: "dark" }
              );
            }
          });
      }
    } else {
      setEmailError(t("other.enter_email"));
    }
  };

  const sendWa = () => {
    if (phone.replace(/\D+/g, "").length) {
      if (phone.replace(/\D+/g, "").length !== 11) {
        setPhoneError(t("other.check_phone_number_correctness"));
      } else {
        setEmailError(false);
        setSending(true);
        setTimeout(() => {
          setSending(false);
          window.location.href = `${WHATSAPP_API_URL}/send?phone=${phone}&text=Номер чека: ${checkData.CheckId}. Проверьте чек, перейдя по ссылке: ${checkData.CheckLink}`;
          setPhone("");
          toast.success(
            `${t("other.check_sent_successfully_to_number")}: ${phone}.`,
            { theme: "dark" }
          );
        }, 1000);
      }
    } else {
      setPhoneError(t("other.enter_phone_number"));
    }
  };

  const MyIcon = () => <ToastIcon />;

  const printCheck = () => {
    toast.success(`${t("other.check_sent_for_printing")}.`, { theme: "dark" });
    if (isElectron()) {
      context.electronShowCheck();
    } else {
      context.showCheck();
    }
  };

  const sendCheck = () => setShow(true);

  const stop = (e) => e.stopPropagation();

  const closeModal = () => setShow(false);

  const downloadCheck = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const fileName = `Чек-${checkData.CheckId}.png`;

        await Filesystem.writeFile({
          directory: Directory.Documents,
          path: fileName,
          data: "data:image/png;base64, " + checkData.CheckImg,
        });

        const fileUri = await Filesystem.getUri({
          directory: Directory.Documents,
          path: fileName,
        });

        await shareFile(fileName, fileUri.uri, `Чек-${checkData.CheckId}`);
      } else {
        const receipt = document.getElementById("reciept");
        const printWindow = window.open("");
        printWindow.document.write(receipt.outerHTML);
        printWindow.print();
      }
    } catch (e) {
      console.error("Error downloading or saving file:", e);
    }
  };

  const shareLink = () => {
    navigator
      .share({
        url: checkData.CheckLink,
      })
      .then(() => console.log("successful"))
      .catch((err) => console.error(err));
  };

  const goBack = () => history.goBack();

  const goToKkmsList = () => {
    history.push(`/www/comps_new`);
  };

  return (
    <div className={`${mainStyle.main} ${mainStyle[`main--${Capacitor.getPlatform()}`]}`}>
      <div className={`${style.mobileHeader} ${style[`mobileHeader--${Capacitor.getPlatform()}`]}`}>
        <p>{t("receipt.operation_successful")}</p>
        <Close onClick={() => goBack()} />
      </div>

      <div className={`${style.checkWrapper} ${style[`checkWrapper--${Capacitor.getPlatform()}`]}`}>
        <div className={style.check}>
          {checkData && checkData.CheckImg ? (
            <img
              id="receipt"
              src={`data:image/png;base64,${checkData.CheckImg}`}
              alt="Check"
            />
          ) : (
            <div className={mainStyle.spinnerWrapper}>
              <SpinnerGray />
              <p className={mainStyle.text}>{t("main.loading")}</p>
            </div>
          )}
        </div>
      </div>

      <div className={`${style.mobileButtons} container`}>
        <button className={style.newSale} onClick={() => goBack()}>
          {t("main.new_operation")}
        </button>
        <button className={style.goBack} onClick={() => goToKkmsList()}>
          {t("receipt.return_to_cash_register")}
        </button>
      </div>

      <div className={style.form}>
        <div className={style.formHeader}>
          <Success />
          <p>{t("receipt.operation_successful")}</p>
        </div>
        <div className={style.formBody}>
          <div className={style.holder}>
            <button
              className={
                active === SEND_METHODS.EMAIL ? style.emailActive : style.email
              }
              onClick={() => setActive(SEND_METHODS.EMAIL)}
            >
              Email
            </button>
            <button
              className={
                active === SEND_METHODS.WHATSAPP ? style.wtspActive : style.wtsp
              }
              onClick={() => setActive(SEND_METHODS.WHATSAPP)}
            >
              WhatsApp
            </button>
          </div>
          {active === SEND_METHODS.EMAIL ? (
            <div className={style.inputWrapper}>
              <label htmlFor="">{t("receipt.send_receipt_email")}</label>
              <div className={style.sendWrapper}>
                <input
                  type="text"
                  placeholder="example@kassa.kz"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={emailError ? style.errorInput : ""}
                />
                <button
                  onClick={() => sendEmail()}
                  className={sending ? style.sending : ""}
                >
                  {sending ? <Spinner /> : t("receipt.send")}
                </button>
              </div>
              {emailError && <p className={style.error}>{emailError}</p>}
            </div>
          ) : (
            <div className={style.inputWrapper}>
              <label htmlFor="">{t("receipt.send_receipt_whatsapp")}</label>
              <div className={style.sendWrapper}>
                <MaskedInput
                  showMask={false}
                  guide={true}
                  placeholderChar={"\u2000"}
                  mask={[
                    "+",
                    "7",
                    " ",
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                  inputMode="tel"
                  id="inputPhone"
                  name="number"
                  className={phoneError ? style.errorInput : ""}
                  placeholder="+7 (777) 000-00-00"
                  autoComplete="phone"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onFocus={(e) => (e.target.value = "+7")}
                  onBlur={(e) => (e.target.placeholder = "+7 (777) 000-00-00")}
                />
                <button
                  onClick={() => sendWa()}
                  className={sending && style.sending}
                >
                  {sending ? <Spinner /> : t("receipt.send")}
                </button>
              </div>
              {phoneError && <p className={style.error}>{phoneError}</p>}
            </div>
          )}
        </div>
        <div className={style.formFooter}>
          <button onClick={() => printCheck()}>
            <Printer />
            {t("receipt.print")}
          </button>
          <button onClick={() => goBack()}>
            <Goback />
            {t("receipt.return_to_cash_register")}
          </button>
        </div>
      </div>

      {Capacitor.isNativePlatform() ? (
        <div className={style.mobileActions}>
          <button onClick={() => downloadCheck()}>Скачать чек</button>
          <button onClick={() => sendCheck()}>{t("receipt.send")}</button>  
        </div>
      ) : (
        <div className={style.mobileActions}>
          <button onClick={() => printCheck()}>{t("receipt.printing")}</button>
          <button onClick={() => sendCheck()}>{t("receipt.send")}</button>
          <button onClick={() => shareLink()}>
            <Share />
          </button>
        </div>
      )}

      {show && (
        <div className={style.sendCheckWrapper} onClick={() => closeModal()}>
          <div className={style.sendCheckModal} onClick={(e) => stop(e)}>
            <div className={style.formTitle}>
              <p>Отправка чека</p>
            </div>
            <div className={style.formBody}>
              <div className={style.holder}>
                <button
                  className={
                    active === SEND_METHODS.EMAIL
                      ? style.emailActive
                      : style.email
                  }
                  onClick={() => setActive(SEND_METHODS.EMAIL)}
                >
                  Email
                </button>
                <button
                  className={
                    active === SEND_METHODS.WHATSAPP
                      ? style.wtspActive
                      : style.wtsp
                  }
                  onClick={() => setActive(SEND_METHODS.WHATSAPP)}
                >
                  WhatsApp
                </button>
              </div>
              {active === SEND_METHODS.EMAIL ? (
                <div className={style.inputWrapper}>
                  <label htmlFor="">{t("receipt.send_receipt_email")}</label>
                  <div className={style.sendWrapper}>
                    <input
                      type="text"
                      placeholder="example@kassa.kz"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={emailError && style.errorInput}
                    />
                    <button
                      onClick={() => sendEmail()}
                      className={sending && style.sending}
                    >
                      {sending ? <Spinner /> : t("receipt.send")}
                    </button>
                  </div>
                  {emailError && <p className={style.error}>{emailError}</p>}
                </div>
              ) : (
                <div className={style.inputWrapper}>
                  <label htmlFor="">{t("receipt.send_receipt_whatsapp")}</label>
                  <div className={style.sendWrapper}>
                    <MaskedInput
                      showMask={false}
                      guide={true}
                      placeholderChar={"\u2000"}
                      mask={[
                        "+",
                        "7",
                        " ",
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                      ]}
                      inputMode="tel"
                      id="inputPhone"
                      name="number"
                      className={phoneError && style.errorInput}
                      placeholder="+7 (777) 000-00-00"
                      autoComplete="phone"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onFocus={(e) => (e.target.value = "+7")}
                      onBlur={(e) =>
                        (e.target.placeholder = "+7 (777) 000-00-00")
                      }
                    />
                    <button
                      onClick={() => sendWa()}
                      className={sending && style.sending}
                    >
                      {sending ? <Spinner /> : t("receipt.send")}
                    </button>
                  </div>
                  {phoneError && <p className={style.error}>{phoneError}</p>}
                </div>
              )}
            </div>
            <div className={style.formFooter}>
              <p onClick={() => closeModal()}>{t("receipt.close")}</p>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        transition={Slide}
        icon={MyIcon}
        closeButton={false}
        hideProgressBar
        limit={1}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        className={`toastify-container-${Capacitor.getPlatform()}`}
      />
    </div>
  );
};

export default Check;
