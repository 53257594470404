import React, {useEffect, useState} from "react";
import { downloadZReportXLSFile } from '../../../../Api/api';
import style from '../../styles/pages/report/tabs/NewReportZ.module.scss'
import NewPagination from "../../NewSettings/NewPagination";
import {formatDate} from "../../../../Store/snippets";
import FileExcel2FillBig from "../../svg/fill-excel-2-fill-big.svg?react";
import ArrowRightSLine from "../../svg/arrow-right-s-line.svg?react";
import ArrowUpCircleLine from "../../svg/arrow-up-circle-line.svg?react";
import ArrowDownCircleLine from "../../svg/arrow-down-circle-line.svg?react";
import DownloadFill from "../../svg/download-fill.svg?react";
import ArrowUpDownFill from "../../svg/arrow-up-down-fill.svg?react";
import ReplyFill from "../../svg/reply-fill.svg?react";
import ReplyMirrorFill from "../../svg/reply-mirror-fill.svg?react";
import {subDays, format} from "date-fns";
import NewCalendar from "../../NewCalendar";
import ArrowDownSFill from "../../svg/arrow-down-s-fill.svg?react";
import ArrowUpSFill from "../../svg/arrow-up-s-fill.svg?react";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import { getRangePeriod } from "../../../../Utils/calendar";
import write_blob from "capacitor-blob-writer";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { shareFile } from "../../../../Utils/share-file";

const NewReportZ = ({ reportZ, idKkm, showReportZDuplicate, filterReportZByDate, today, weekdayFromToday, 
	reportZTotalAmounts, filterReportZData, getReportZ, isLoading }) => {
		
	const { t } = useTranslation()
	const [activePage, setActivePage] = useState(1)
	const [reportZList, setReportZList] = useState(reportZ.slice(0, 10))
	const [calendarOptionsIsOpen, setCalendarOptionsIsOpen] = useState(false)
	const [calendarIsOpen, setCalendarIsOpen] = useState(false)
	const [sortingIsAscending, setSortingIsAscending] = useState({
		shift: false,
		balanceOpen: false,
		balanceClose: false,
		operationCount: false,
		cashierName: false,
	})
	const [typeToSort, setTypeToSort] = useState('')

	const [datePeriod, setDatePeriod] = useState({
		from: formatDate(weekdayFromToday, 2),
		to: formatDate(today, 2)
	})
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: subDays(new Date(), 7),
			key: 'selection',
		}
	]);
	const [sortingIsClicked, setSortingIsClicked] = useState(false)

	useEffect(() => {
		getReportZ()
	}, [])

	useEffect(() => {
		filterReportZData(typeToSort, sortingIsAscending)
	}, [sortingIsAscending, typeToSort])

	useEffect(() => {
		setReportZList(reportZ.slice(0, 10))
	}, [reportZ, sortingIsAscending])

	useEffect(() => {
		setReportZList(reportZ.slice(10 * (activePage-1), 10 * activePage))
	}, [reportZ, sortingIsAscending])

	const filterReportZ = (type) => {
		setSortingIsAscending({
			...sortingIsAscending,
			[type]: !sortingIsAscending[type],
		})
		setTypeToSort(type)
		setSortingIsClicked(true)
	}

	const setPage = (page) => {
		setActivePage(page)
		setReportZList(reportZ.slice(10 * (page-1), 10 * page))
	}


	const toggleSortingIcon = (type) => {
		if (!sortingIsClicked) {
			return (
				<ArrowDownSFill/>
			)
		}

		if (typeToSort === type && sortingIsClicked) {
			return (
				<React.Fragment>
					{ sortingIsAscending[type] ? <ArrowUpSFill/> : <ArrowDownSFill/>}
				</React.Fragment>
			)
		}
	}



	const getPeriod = (type, selectedDateRange) => {
		setActivePage(1)

		const { date, dateISOstring } = getRangePeriod(type, selectedDateRange);

		setDatePeriod({
			...date,
		})
		if(type === 4) {
			setCalendarIsOpen(false)
		}

		setCalendarOptionsIsOpen(false)

		filterReportZByDate(dateISOstring)
	}

	const downloadFile = async (period) => {
		const [day, month, year] = period.to.split(".");
		const [day_from, month_from, year_from] = period.from.split(".");
		try {
		  const res = await downloadZReportXLSFile(idKkm, {
			date_from: format(
			  new Date(year_from, month_from - 1, day_from),
			  "yyyy-LL-dd'T'HH:mm:ss"
			),
			date_to: format(
			  new Date(year, month - 1, day, 23, 59, 59),
			  "yyyy-LL-dd'T'HH:mm:ss"
			),
		  });
		  const timestamp = new Date().getTime();
		  const outputFilename = `Отчёт_Z_${period.from}-${period.to}-${timestamp}.xlsx`;
		  const blob = new Blob([res.data]);
	
		  if (Capacitor.isNativePlatform()) {
			await write_blob({
			  path: outputFilename,
			  directory: Directory.Documents,
			  blob: blob,
			});
	
			const fileUri = await Filesystem.getUri({
			  directory: Directory.Documents,
			  path: outputFilename,
			});
	
			await shareFile(outputFilename, fileUri.uri);
		  } else {
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", outputFilename);
			document.body.appendChild(link);
			link.click();
		  }
		} catch (e) {
		  console.error(e);
		}
	  };
	const reportZRenderList = reportZList.map(reportZItem => {
		return (
			<div className={style['table__list']} key={reportZItem.Id}>
					<div className={style['table__item']}>
						<span>{reportZItem.ShiftIndex}</span>
					</div>
					<div className={style['table__item']}>
						<span>{formatDate(new Date(reportZItem.DateOpen))}</span>
					</div>
					<div className={style['table__item']}>
						<span>{formatDate(new Date(reportZItem.DateClose))}</span>
					</div>
					<div className={style['table__item']}>
						<span>{reportZItem.BalanceOpen}</span>
					</div>
					<div className={style['table__item']}>
						<span>{reportZItem.BalanceClose}</span>
					</div>
					<div className={style['table__item']}>
						<span>{reportZItem.Count}</span>
					</div>
					<div className={`${style['table__item']} ${style['table__name']}`}>
						<div className={style['table__name-text']}>
							{reportZItem.User.Name}
							<div className={style['table__hide-text']}></div>
						</div>
						<div className={style['table__full-text']}>{reportZItem.User.Name}</div>
					</div>
					<div className={style['table__item']}>
						<button
							className={`${style.btn} ${style['btn--off']}`}
							onClick={() => showReportZDuplicate(reportZItem.Id)}
						>
							{ t('report.duplicate') }
						</button>
					</div>
				</div>
		)
	})

	const reportZRenderListMobile = reportZList.map((reportZItem, index) => {
		return (
			<div className={style['table__list']} key={reportZItem.Id}>
					<div className={style['table__item']}>
						<span>{reportZItem.ShiftIndex} { t('report.shift') }</span>
					</div>
					<div className={style['table__item']}>
						<span>{formatDate(new Date(reportZItem.DateOpen))} <ArrowRightSLine/> {formatDate(new Date(reportZItem.DateClose))}</span>
					</div>
					<div className={style['table__item']}>
						<span>{ t('report.received') }</span>
						<div className={`${style['underline']} ${style['underline--1']}`}></div>
					</div>
					<div className={style['table__item']}>
						<span>{reportZItem.BalanceOpen}</span>
						<div className={`${style['underline']} ${style['underline--2']}`}></div>
					</div>
					<div className={style['table__item']}>
						<span>{ t('report.handed_over') }</span>
						<div className={`${style['underline']} ${style['underline--1']}`}></div>
					</div>
					<div className={style['table__item']}>
						<span>{reportZItem.BalanceClose}</span>
						<div className={`${style['underline']} ${style['underline--2']}`}></div>
					</div>
					<div className={`${style['table__item']}`}>
						<div className={style['table__name-text']}>
							<span>{ t('report.operations') } {reportZItem.Count} • Кассир: {reportZItem.User.Name}</span>
							<div className={style['table__hide-text']}></div>
							<div className={style['table__full-text']}>{reportZItem.User.Name}</div>
						</div>
					</div>

					<div className={style['table__item']}>
						<button
							className={`${style.btn} ${style['btn--off']}`}
							onClick={() => showReportZDuplicate(reportZItem.Id)}
						>
							{ t('report.duplicate') }
						</button>
					</div>
				</div>
		)
	})

	return (
		<React.Fragment>
			{ !isLoading
				?
				<div className={style.table}>
					<div className={`${style['table__top']} ${style['my-20']}`}>
						<NewCalendar
							setCalendarOptionsIsOpen={setCalendarOptionsIsOpen}
							calendarOptionsIsOpen={calendarOptionsIsOpen}
							datePeriod={datePeriod}
							getPeriod={getPeriod}
							dateRange={dateRange}
							setDateRange={setDateRange}
							calendarIsOpen={calendarIsOpen}
							setCalendarIsOpen={setCalendarIsOpen}
						/>
						<button
							disabled={!reportZ.length}
							className={`${style.btn} ${style['btn--primary']} ${style['ml-20']}`}
							onClick={()=> downloadFile(datePeriod)}
						>
							<FileExcel2FillBig/>
							<span className={style['ml-10']}>{ t('report.export_to_excel') }</span>
						</button>
					</div>
					{ reportZ.length
						?
						<div className={`${style.card} ${style['card--white-nopadding']}`}>
							<div className={style['table__content']}>
								<div className={style['table__total']}>
									<div className={style['table__total-item']}>
										<ArrowUpCircleLine/>
										<div className={style['ml-10']}>
											<span>{ t('report.sale') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.SalesAmount}<span> ₸</span></h3>
										</div>
									</div>
									<div className={style['table__total-item']}>
										<ArrowDownCircleLine/>
										<div className={style['ml-10']}>
											<span>{ t('report.purchase') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.PurchasesAmount}<span> ₸</span></h3>
										</div>
									</div>
									<div className={style['table__total-item']}>
										<DownloadFill/>
										<div className={style['ml-10']}>
											<span>{ t('report.income') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.IncomesAmount}<span> ₸</span></h3>
										</div>
									</div>
									<div className={style['table__total-item']}>
										<ArrowUpDownFill/>
										<div className={style['ml-10']}>
											<span>{ t('report.expense_and_indexation') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.ExpensesAmount}<span> ₸</span></h3>
										</div>
									</div>
									<div className={style['table__total-item']}>
										<ReplyFill/>
										<div className={style['ml-10']}>
											<span>{ t('report.sales_return') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.RefundsAmount}<span> ₸</span></h3>
										</div>
									</div>
									<div className={style['table__total-item']}>
										<ReplyMirrorFill/>
										<div className={style['ml-10']}>
											<span>{ t('report.refund_of_purchase') }</span>
											<h3 className={style['heading-tertiary']}>{reportZTotalAmounts.PurchaseRefundsAmount}<span> ₸</span></h3>
										</div>
									</div>
								</div>
								<div className={style['table__header']}>
									<div
										onClick={() => filterReportZ('shift')}
									>
										<span className={style['mr-10']}>№ смены</span>
										{ toggleSortingIcon('shift') }
									</div>
									<div>
										{ t('report.open') }
									</div>
									<div>
										{ t('report.closed') }
									</div>
									<div
										onClick={() => filterReportZ('balanceOpen')}
									>
										<span className={style['mr-10']}>{ t('report.received_alt') }</span>
										{ toggleSortingIcon('balanceOpen') }
									</div>
									<div
										onClick={() => filterReportZ('balanceClose')}
									>
										<span className={style['mr-10']}>{ t('report.handed_over_alt') }</span>
										{ toggleSortingIcon('balanceClose') }
									</div>
									<div
										onClick={() => filterReportZ('operationCount')}
									>
										<span className={style['mr-10']}>{ t('report.operation_count') }</span>
										{ toggleSortingIcon('operationCount') }
									</div>
									<div
										onClick={() => filterReportZ('cashierName')}
									>
										<span className={style['mr-10']}>{ t('report.cashier') }</span>
										{ toggleSortingIcon('cashierName') }
									</div>
									<div></div>
								</div>
								<div className={`${style['table__body']} ${style['table__body--desktop']}`}>
									{reportZRenderList}
								</div>
								<div className={`${style['table__body']} ${style['table__body--mobile']}`}>
									{ reportZRenderListMobile }
								</div>
								{
									reportZList.length ? (
										<NewPagination
											setPage={setPage}
											list={reportZ}
											activePage={activePage}
										/>
									) : null
								}
							</div>
						</div>
						:
						<div className={`${style.card} ${style['card--white-nopadding']} ${style['mt-20']}`}>
							<div className={`${style['text-center']} ${style['pa-40']}`}>
								<p className={`${style.paragraph} ${style['paragraph--sub']}`}>
									{ t('report.no_z_reports_found_for_current_date') }
								</p>
							</div>
						</div>
					}
				</div>
				:
				<Loader />
			}
		</React.Fragment>
	)
}

export default NewReportZ
