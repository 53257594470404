import React, { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RiEyeCloseLine, RiEyeLine, RiCloseFill, RiLoaderLine } from 'react-icons/ri'
import { Input, Error, Form, Label, MaskedInput } from '../FormGroup'
import Button from '../Button'
import s from './loginform.module.scss'
import { useTranslation } from "react-i18next";

const LoginForm = ({ login, isLoading, isError }) => {
	const { t } = useTranslation()

  const onSubmit = (data) => login(data);
	const [passwordVisible, setPasswordVisible] = useState(false)
	const NODE_ENV = import.meta.env.MODE

	const validationSchema = Yup.object().shape({
			phone: Yup.string()
					.transform(currentValue => currentValue.replace(/\s/g, '').replace('+', ''))
					.required(t('other.enter_phone'))
					.min(11, t('other.enter_phone')),
			password: Yup.string()
					.required(t('other.enter_password'))
					.min(6, t('other.at_least_6_characters'))
	});

  return (
		<Form
			onSubmit={onSubmit}
			formOptions={{
				resolver: yupResolver(validationSchema),
				defaultValues: {
					phone: localStorage.getItem('phone') || '',
					password: '',
				}
			}}
		>
			{({ register, formState: { errors }, control, reset, watch }) => (
				<Fragment>
					<h1 className={s.title}>{ t('auth.log_in') }</h1>

					{ isError ? <Error errorText={ t('other.incorrect_login_or_password') } />
						: errors.phone ? <Error errorText={errors.phone.message} />
						: <Label htmlFor='phone' labelText={t('auth.phone_number')} />
					}
					<div className={s.container_input}>
						<MaskedInput
							format='+7 ### ### ## ##'
							mask=''
							control={control}
							name='phone'
							aria-invalid={errors.phone || isError ? 'true' : 'false'}
							placeholder='+7 (000) 000 00 00'
						/>
						{ watch('phone').replace(/\s/g, '').length > 2
							&& <RiCloseFill color='#BFC7D2' size={22} onClick={() => reset({ phone: '' })} />}
					</div>

					<div className={s.container_label}>
						{ isError ? <Error errorText='Неверный логин или пароль' />
							: errors.password ? <Error errorText={errors.password.message} />
							: <Label htmlFor='password' labelText={ t('auth.password') } />
						}
						<a
							href={`${NODE_ENV !== 'production' ? 'https://business-test.kassa24pay.kz/cabinet/reset' : 'https://business.kassa24.kz/cabinet/reset'}`}
							target='_blank'
							rel="noopener noreferrer"
							aria-label='Github'
						>
							{ t('auth.forgot_password') }
						</a>
					</div>

					<div className={s.container_input}>
						<Input
							id='password'
							{...register("password")}
							type={passwordVisible ? 'text' : 'password'}
							aria-invalid={errors.password || isError ? "true" : "false"}
							placeholder={ t('auth.password') }
							spellCheck='false'
						/>
						{	!passwordVisible && <RiEyeCloseLine color='#BFC7D2' width={18} onClick={() => setPasswordVisible(!passwordVisible)}/>	}
						{	passwordVisible && <RiEyeLine color='#BFC7D2' width={18} onClick={() => setPasswordVisible(!passwordVisible)}/>	}
					</div>

					<br/>

					<Button className='btn__save' marginBottom={32} marginTop={13} disabled={isLoading}>
						<span>{ t('auth.log_in') }</span>
						{ isLoading && <RiLoaderLine size={18} /> }
					</Button>

					<a
						className={s.link}
						href={`${NODE_ENV !== 'production' ? 'https://business-test.kassa24pay.kz/cabinet/registration' : 'https://business.kassa24.kz/cabinet/registration'}`}
						target='_blank'
						rel="noopener noreferrer"
						aria-label='Github'
					>
						{ t('auth.register') }
					</a>
				</Fragment>
			)}
		</Form>
	)
}

export default LoginForm
