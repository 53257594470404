import React from "react";
import backToBtn from "../../styles/components/backToBtn.module.scss";
import head from "../../styles/pages/shift/head.module.scss";
import GreenStatus from "../../svg/shiftGreenStatus.svg?react";
import GreyStatus from "../../svg/shiftGreyStatus.svg?react";
import RedStatus from "../../svg/shiftRedStatus.svg?react";
import Left from "../../svg/arrowLeft.svg?react";
import { useTranslation } from "react-i18next";

const Head = ({ kkm, history, tab, setTab }) => {
  const { t } = useTranslation();
  const goToKkmsList = () => {
    history.push(`/www/comps_new`);
  };

  const getStatusInfo = (id) => {
    switch (id) {
      case 1:
        return {
          name: t("kassa.in_progress"),
          icon: <GreyStatus />,
        };
      case 2:
        return {
          name: t("kassa.active"),
          icon: <GreenStatus />,
        };
      case 3:
        return {
          name: t("kassa.blocked"),
          icon: <RedStatus />,
        };
      default:
        return null;
    }
  };

  return (
    <div className={head.head}>
      <div className={head.left}>
        <button className={backToBtn.backTo} onClick={goToKkmsList}>
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.2185 4.83336H11.3332V6.1667H3.2185L6.7945 9.7427L5.85184 10.6854L0.666504 5.50003L5.85184 0.314697L6.7945 1.25736L3.2185 4.83336Z"
              fill="#8E8E8E"
            />
          </svg>
          <p>{t("kassa.back_to_cash_register_selection")}</p>
        </button>

        <div className={backToBtn.mobileBackTo} onClick={goToKkmsList}>
          <Left />
          <p className={head.name}>{kkm.Name}</p>
        </div>

      </div>
      <div className={head.status}>
        {getStatusInfo(kkm.StatusKKM.Id).icon}
        <p className={head.statusName}>
          {getStatusInfo(kkm.StatusKKM.Id).name}
        </p>
      </div>
      <div className={head.tabs}>
        <div
          className={tab === 1 ? head.activeTab : head.tab}
          onClick={() => setTab(1)}
        >
          {t("other.cash_register_info")}
        </div>
        <div
          className={tab === 2 ? head.activeTab : head.tab}
          onClick={() => setTab(2)}
        >
          {t("other.analytics")}
        </div>
      </div>
    </div>
  );
};

export default Head;
