import React from "react"
import { Store } from "../../../../Store/OperationProvider";
// import Header from '../../../Header/Header';
import MobileNav from '../../../MobileNav/MobileNav';
import Title from "../modules/Title"
import IncomeContainer from "./IncomeContainer";
// import ArrowRight from "../images/arrowRight.svg?react";
import style from "../../styles/pages/operation.module.scss"
// import versionStyle from "../../styles/oldVersionBtn.module.scss"
// import {setAppVersion} from "../../../../Store/appVersion";
import HeaderVersion from "../../HeaderVersion/HeaderVersion";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";

const IncomeOperation = (props) => {
  const { t } = useTranslation()

  // const goOld = () => {
  //   props.history.push('income')
  //   setAppVersion(false)
  // }

  return (
    <Store.Consumer>
      {
        context => (
          <React.Fragment>
            {
              window.matchMedia("(min-width: 1100px)").matches ? (
                <HeaderVersion isActive={true} balances={context.Balances} {...props} />
              ) : (
                <MobileNav isActive={true} />
              )
            }
            <div className={`${style.mainShort} ${style[`mainShort--${Capacitor.getPlatform()}`]}`}>
              {/* <div className="container">
                <div className={versionStyle.oldVersion} onClick={() => goOld()}>
                  <p className={versionStyle.oldVersionText}>Старый вид<br />сайта</p>
                  <ArrowRight />
                </div>
              </div> */}
              <div className={style.container}>
                <Title title={ t('receipt.service_income') } history={props.history} />
                <IncomeContainer context={context} history={props.history} />
              </div>
            </div>
          </React.Fragment>
        )
      }
    </Store.Consumer>
  )
}

export default IncomeOperation;
