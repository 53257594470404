import React  from 'react'
import { useHistory } from "react-router-dom";
import shiftcard from '../../styles/pages/shift/shiftcard.module.scss'
import button from '../../styles/components/actionBtn.module.scss'
import Spinner from '../../../Common/Spinner';
import User from '../../svg/shiftHolder.svg?react'
import Settings from '../../svg/settings.svg?react'
// import { getAppModuleUrl } from "../../../../Store/appVersion";
import { useTranslation } from "react-i18next";

const ShiftCard = ({
  kkm, balances, shiftOpen, shiftOpenByAnotherCashier, shiftBlocked, pending, shiftExpired, openShift, openModal
}) => {
  const { t } = useTranslation()

  const history = useHistory()
  const startOperation = () => {
    history.push(`/www/kkms/${kkm.Id}/sale_new`)
  }
  const forbiddenDefault = () => {
    history.push(`/www/kkms/${kkm.Id}/settings/forbiden_new_default`);
  }
  const forbiddenNewOther = () => {
    history.push(`/www/kkms/${kkm.Id}/settings/forbiden_new_other`);
  }
  const settings = () => {
    history.push(`/www/kkms/${kkm.Id}/settings_new`);
  }
  const reports = () => {
    history.push(`/www/shifts/${kkm.IdShift}/report`)
  }

  return (
    <div className={shiftcard.card}>
      <div className={shiftcard.header}>
          {
            shiftOpen ? (
              <div className={shiftcard.shiftIndex}>
                <User />
                <p>{ t('kassa.shift') } №{kkm.ShiftIndex}</p>
              </div>
            ) : (
              <div className={shiftcard.shiftIndex}>
                <User />
                <p>{ t('kassa.new_shift') }</p>
              </div>
            )
          }
        {balances.map((balance, idx) => (
          <div className={shiftcard.row} key={balance.Id}>
            <p className={shiftcard.key}>{balance.TypeBalance.Name === 'Наличные средства' ? t('kassa.cash_resources') : t('kassa.non_cash_resources')}</p>
            <p className={shiftcard.value}>{new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸</p>
          </div>
        ))}
      </div>
      <div className={shiftcard.body}>
        <div className={shiftcard.row}>
          <p className={shiftcard.key}>Касса</p>
          <p className={shiftcard.value}>№{kkm.Id}</p>
        </div>
        <div className={shiftcard.row}>
          <p className={shiftcard.key}>ЗНМ</p>
          <p className={shiftcard.value}>{kkm.Znm}</p>
        </div>
        {/* <div className={shiftcard.row}>
          <p className={shiftcard.key}>Оплачена до:</p>
          <p className={shiftcard.value}>04.01.2022</p>
        </div> */}
        <div className={shiftcard.row}>
          <p className={shiftcard.key}>{ t('kassa.company') }</p>
          <p className={shiftcard.value}>{kkm.Company.FullName}</p>
        </div>
        <div className={shiftcard.row}>
          <p className={shiftcard.key}>{ t('kassa.address') }</p>
          <p className={shiftcard.value}>{kkm.Address.Town.Name}, {kkm.Address.Street} {kkm.Address.House}, {kkm.Address.Flat}</p>
        </div>
      </div>
      <div className={shiftcard.footer}>
        {
          !shiftBlocked && (
            <React.Fragment>
              {shiftOpen ? (
                <React.Fragment>
                  {!shiftExpired && (
                    <button className={button.blueBtn} onClick={startOperation}>
                      { t('kassa.start_issuing_receipts') }
                    </button>
                  )}
                </React.Fragment>
              ) :
              (
                <React.Fragment>
                  {pending ?
                    <button
                      className={button.blueBtn}
                      disabled
                    >
                      Открываем <Spinner className="mr-2" />
                    </button>
                    :
                    <button
                      className={button.blueBtn}
                      onClick={() => openShift()}
                    >
                      { t('other.open_shift') }
                    </button>
                  }
                </React.Fragment>
              )}
            </React.Fragment>
          )
        }
        <div className={shiftcard.extraActions}>
          <div className={shiftcard.double}>
            <button className={button.greyBtn} onClick={reports}>{ t('kassa.operations_log').split('|')[0] }<br/>{ t('kassa.operations_log').split('|')[1] }</button>
            <button className={button.greyBtn} onClick={reports}>{ t('kassa.all_reports') }</button>
          </div>
          {
            shiftOpen ? (
              <button className={button.greyBtn} onClick={forbiddenDefault}>
                <Settings />
                { t('kassa.settings') }
              </button>
            ) : shiftOpenByAnotherCashier ? (
              <button className={button.greyBtn} onClick={forbiddenNewOther}>
                <Settings />
                { t('kassa.settings') }
              </button>
              ) : (
              <button className={button.greyBtn} onClick={settings}>
                <Settings />
                { t('kassa.settings') }
              </button>
            )
          }
          {
            shiftOpen && (
              <button className={button.redBtn} onClick={() => openModal()}>{ t('kassa.close_shift') }</button>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ShiftCard;
