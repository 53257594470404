import React from 'react'
import stats from '../../styles/pages/shift/statistics.module.scss'
import Info from '../../svg/info.svg?react'
import  Reload from '../../svg/reload.svg?react'
import StatsUp from '../../svg/statsUp.svg?react'
import StatsDown from '../../svg/statsDown.svg?react'
import StatsNoChange from '../../svg/statsNoChange.svg?react'
import { useTranslation } from "react-i18next";

const GetStats = ({curr, prev}) => {
  if (curr < prev) {
    let proportion = ((curr * 100 / prev) - 100).toFixed(2)
    if (!curr) {
      proportion = '-100.00'
    }
    return (
      <div className={stats.right}>
        <StatsDown />
        <p>{proportion}%</p>
      </div>
    )
  } else if (curr > prev) {
    let proportion = ((curr * 100 / prev) - 100).toFixed(2)
    if (!prev) {
      proportion = '100.00'
    }
    return (
      <div className={stats.right}>
        <StatsUp />
        <p>+{proportion}%</p>
      </div>
    )
  } else {
    return (
      <div className={stats.right}>
        <StatsNoChange />
        <p>0%</p>
      </div>
    )
  }
}

const Statistics = ({ sells, oldSells, refunds, oldRefunds, avgCheck, oldAvgCheck, reloadTime, updataDashboard, newFormatDate }) => {
  const { t } = useTranslation()
  return (
    <div className={stats.wrapper}>
      <div className={stats.head}>
        <div className={stats.left}>
          <p>{ t('kassa.shift_stats') }</p>
          <Info />
          <div className={stats.tooltip}>
            <p>
              { t('kassa.shift_stats_explanation') }
            </p>
            <p>
              { t('kassa.shift_stats_explanation_alt') }
            </p>
          </div>
        </div>
        <div className={stats.right}>
          <Reload onClick={updataDashboard}/>
          <p>{ t('kassa.updated_at') } {newFormatDate(reloadTime)}</p>
        </div>
      </div>

      <div className={stats.stats}>
        <div className={stats.profit}>
          <div className={stats.left}>
            <p className={stats.title}>{ t('kassa.revenue') }</p>
            <p className={stats.sum}>{sells.Total.toFixed(2)} ₸</p>
          </div>
          <GetStats curr={Math.round(sells.Total)} prev={Math.round(oldSells)} />
        </div>
        <div className={stats.refund}>
          <div className={stats.left}>
            <p className={stats.title}>{ t('kassa.refunds') }</p>
            <p className={stats.sum}>{refunds.Total.toFixed(2)} ₸</p>
          </div>
          <GetStats curr={Math.round(refunds.Total)} prev={Math.round(oldRefunds)} />
        </div>
        <div className={stats.average}>
          <div className={stats.left}>
            <p className={stats.title}>{ t('kassa.average_receipt') }</p>
            <p className={stats.sum}>{avgCheck.toFixed(2)} ₸</p>
          </div>
          <GetStats curr={Math.round(avgCheck)} prev={Math.round(oldAvgCheck)} />
        </div>
      </div>
    </div>
  )
}

export default Statistics;
