/* eslint-disable eqeqeq */
import React, {useEffect, useState} from "react";
import style from '../../styles/pages/report/duplicates/NewReportZDuplicate.module.scss'
import ArrowLeftLine from "../../svg/arrow-left-line.svg?react";
import NewReportZCloseTop from "./NewReportZCloseTop";
import NewReportZCloseSections from "./NewReportZCloseSections";
import NewReportZCloseInfo from "./NewReportZCloseInfo";
import NewReportZCloseOperations from "./NewReportZCloseOperations";
import {BASE_URL, getError, getHeaders, getKkm, getUser} from "../../../../Store/snippets";
import axios from "axios";
import PreloaderFullScreen from "../../../Preloader/Preloader";
import {Link, useHistory} from "react-router-dom";
import {getAppModule} from "../../../../Store/appVersion";
import HeaderVersion from "../../HeaderVersion/HeaderVersion";
// import PrintButton from "../../../Buttons/PrintButton";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";

const NewReportZClose = () => {
	const { t } = useTranslation()

	const history = useHistory()
	const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
	const headers = getHeaders(history)
	let uid = localStorage.getItem(`Uid_${idKkm}`);

	let user = getUser(history);
	let kkm = getKkm(history);

	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState({
		status: false,
		title: '',
		text: '',
	})

	const [reportZChoosen, setReportZChoosen] = useState(null)

	useEffect(() => {
		axios.post(`${BASE_URL}/shifts/${user.IdShift}/z`, { 'IdKkm': idKkm }, { headers: { ...headers, 'uid': uid } })
			.then(response => {
				user = { ...user, Lock: false };
				kkm = { ...kkm, Lock: false };
				localStorage.setItem('USER', JSON.stringify(user));
				localStorage.setItem('KKM', JSON.stringify(kkm));
				localStorage.removeItem('SHIFT');
				setReportZChoosen(response.data.Data)
				setIsLoading(false)
			})
			.catch(async e => {
				let errorData;
				try {
					if (e.response && e.response.status === 452) {
						localStorage.removeItem(`Uid_${idKkm}`);

						const uidResponse = await axios.get(`${BASE_URL}/uid/${idKkm}`, { headers: headers });
						uid = uidResponse.data.Data.Uid;
						localStorage.setItem(`Uid_${idKkm}`, uid);

						const resp = await axios.post(`${BASE_URL}/shifts/${user.IdShift}/z`, { 'IdKkm': idKkm }, { headers: { ...headers, 'uid': uid } });
						user = { ...user, Lock: false };
						kkm = { ...kkm, Lock: false };

						setReportZChoosen(resp.data.Data)
						localStorage.setItem('USER', JSON.stringify(user));
						localStorage.setItem('KKM', JSON.stringify(kkm));
						localStorage.removeItem('SHIFT');

						setIsLoading(false)
					} else {
						errorData = getError(e, history);
						throw errorData;
					}
				} catch (e) {
					if (e.response && e.response.status === 429) {
						errorData = {
							Status: "429",
							Message: t('other.operation_failed_device_blocking')
						}
					}

					setError({
						status: true,
						title: `${t('close_shift.z_report_error')} ${errorData && errorData.Status}`,
						text: errorData.Message,
					})

					setIsLoading(false)
				}
			})
	}, [])

	useEffect(() => {
		document.getElementById('root').style.backgroundColor = getAppModule() ? '#F5F5F5' : 'transparent'
		return () => document.getElementById('root').style.backgroundColor = 'transparent'
	}, [getAppModule()])

	const goBack = () => {
		history.push('/www/comps_new')
	}


	return (
		<React.Fragment>
			{
				!isLoading
					?
					error.status
						?
						<React.Fragment>
							<HeaderVersion isActive={true} />
							<div className={style['report__back']}>
							</div>
							<main role="main" className={`${style.container} ${style[`container--${Capacitor.getPlatform()}`]}`}>
								<div className="alert alert-danger">
									<h5>{error.title}</h5>
									<p>{error.text}</p>
									<div className="d-sm-flex">
										<Link to={`/www/kkms/${idKkm}/shift_new`} replace={true} className="btn btn-warning btn-lg ml-auto d-none d-lg-block">{ t('close_shift.return_to_cash_register') }</Link>
									</div>
								</div>
								<button
									className={`${style.btn} ${style['btn--back']}`}
									style={{ 'textDecoration': 'none' }}
									onClick={goBack}
									>
									<ArrowLeftLine className={style['arrow-left-line']}/>
									{ t('close_shift.back') }
								</button>
							</main>
						</React.Fragment>
						:
						<React.Fragment>
						<HeaderVersion isActive={true} />
						<main role="main" className={style.container}>
							<NewReportZCloseTop reportZChoosen={reportZChoosen} />
							<div className={`${style['flex-4']} ${style['mt-20']}`} id="report-container">
								<NewReportZCloseInfo reportZChoosen={reportZChoosen} user={user} />
								<NewReportZCloseSections reportZChoosen={reportZChoosen} />
								<NewReportZCloseOperations reportZChoosen={reportZChoosen} />
							</div>
						</main>
					</React.Fragment>
					:
					<PreloaderFullScreen/>
			}
		</React.Fragment>

	)
}

export default NewReportZClose
