import React from "react";
import style from "../../styles/components/printBtn.module.scss";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { toJpeg } from "html-to-image";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { shareFile } from "../../../../Utils/share-file";
import write_blob from "capacitor-blob-writer";

const NewDownloadPDFButton = ({
  absolutePosition,
  fileName,
  pdfCurrentStyle,
}) => {
  const { t } = useTranslation();

  const print = async () => {
    const element = document.getElementById("report-container");

    if (!element) {
      console.error("Элемент не найден");
      return;
    }

    let clonedElement = element.cloneNode(true);

    clonedElement.classList.add(pdfCurrentStyle);
    document.body.appendChild(clonedElement);
    clonedElement.style.position = "fixed";
    clonedElement.style.top = "-16px";
    clonedElement.style.zIndex = "-10";
    clonedElement.id = "cloned-report";
    const hideElems = clonedElement.querySelectorAll(".hide-for-pdf");

    hideElems.forEach((elem) => {
      elem.remove();
    });

    const img = await toJpeg(clonedElement, {
      backgroundColor: "#ffffff",
      height: clonedElement.offsetHeight + 100,
    });

    const pdf = new jsPDF("p", "mm", [297, clonedElement.offsetHeight]);

    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);

    const pdfBytes = pdf.output("arraybuffer");

    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

    const timestamp = new Date().getTime();
    const outputFileName = `${fileName}-${timestamp}.pdf`;

    if (Capacitor.isNativePlatform()) {
      await write_blob({
        path: outputFileName,
        directory: Directory.Documents,
        blob: pdfBlob,
      });

      const fileUri = await Filesystem.getUri({
        directory: Directory.Documents,
        path: outputFileName,
      });

      await shareFile(
        outputFileName,
        fileUri.uri,
        `${outputFileName} успешно экспортирован`
      );
    } else {
      pdf.save(outputFileName);
    }

    // const cloned = document.getElementById("cloned-report");
    // cloned.remove();
    // clonedElement = null;
  };

  return (
    <button
      className={`${style.btn} ${style["btn--primary"]} ${
        absolutePosition ? style["btn--absolute"] : ""
      }`}
      onClick={print}
    >
      <span className={style["ml-10"]}>Экспорт в PDF</span>
    </button>
  );
};

export default NewDownloadPDFButton;
