import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Header from '../../Header/Header';
import {getAppModule} from "../../../Store/appVersion";
import style from "../styles/pages/cashbox/NewCashboxSettings.Forbiden.Other.module.scss"
import NewCashboxTop from "./NewCashboxTop";
import HeaderVersion from "../HeaderVersion/HeaderVersion";
import { useTranslation } from "react-i18next";

const NewCashboxSettingsForbidenOther = ({ history }) => {
	const { t } = useTranslation()

	let kkm = (JSON.parse(localStorage.getItem('KKM')))
	let user = (JSON.parse(localStorage.getItem('USER')))

	useEffect(() => {
		console.log(getAppModule())
		document.getElementById('root').style.backgroundColor = getAppModule() ? '#F5F5F5' : 'transparent'
		return () => document.getElementById('root').style.backgroundColor = 'transparent'
	}, [getAppModule()])

	return (
		<React.Fragment>
			<HeaderVersion isActive={true} />
			<main role="main" className={`${style.container} ${style[`container--${Capacitor.getPlatform()}`]}`} >
				<NewCashboxTop kkm={kkm}></NewCashboxTop>
				<div className={style.wrapper}>
					<div className={`${style.card} ${style['card--white']} ${style['text-center']}`}>
						<h1 className={`${style['heading-secondary']}`}>{ t('settings.settings_unavailable') }</h1>
						<p className={`${style.paragraph} ${style['mt-10']} ${style}`}>{ t('settings.settings_close_shift') }</p>
						<div className={`${style.card} ${style['card--gray']} ${style['text-left']} ${style['mt-20']} ${style['pa-16']}`}>
							<div>
								<p className={style.paragraph}>{ t('settings.shift_opened_by_cashier') }</p>
								<h3 className={`${style['heading-tertiary']} ${style['mt-4']}`}>{ user.Name }</h3>
							</div>
							<div className={style['mt-10']}>
								<p className={style.paragraph}>{ t('settings.on_cash_register') }</p>
								<h3 className={`${style['heading-tertiary']} ${style['mt-4']}`}>{ user.IdShift }</h3>
							</div>
						</div>
					</div>
				</div>
			</main>
		</React.Fragment>
	)
}

export default NewCashboxSettingsForbidenOther;
