import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {getAppModuleUrl} from "../../Store/appVersion";

const MobileNavLayout = ({ kkm, toggleMenu, isOpen }) => {
	const { t } = useTranslation()

	return (
		<div className="mobile-nav__menu-wrapper">
      <input type="checkbox" id="mob_menu" defaultChecked={isOpen} />
      <label htmlFor="mob_menu" className="mobile-nav__btn mobile-nav__menu-controller" onClick={toggleMenu}>
        <div className="mobile-nav__btn-circle"></div>
        <div className="mobile-nav__menu">
          <div className="mobile-nav__arrow">
            <svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.82416C0 5.11207 0.853768 6.24393 2.09212 6.59775L12.9011 9.68603C13.6193 9.89124 14.3807 9.89124 15.0989 9.68603L25.9079 6.59775C27.1462 6.24393 28 5.11207 28 3.82416C28 1.90771 26.1657 0.52409 24.323 1.05058L15.0989 3.68603C14.3807 3.89124 13.6193 3.89124 12.9011 3.68603L3.67703 1.05058C1.83432 0.52409 0 1.90771 0 3.82416Z" fill="#EAE6F5"/>
            </svg>
          </div>
          <Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.sale}`} className="mobileOperationBtn active btn-lg btn-block">{ t('main.new_sale') }</Link>
          <Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.purchase}`} className="mobileOperationBtn not-active btn-lg btn-block">{ t('main.new_purchase') }</Link>
          <div className="mobile-nav__menu-sqr">
            <Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.income}`} className="btn btn-sqr mobileOperationBtn btn-lg btn-block not-active">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_7727_93646)"> <path d="M10.8333 9.99998H16.6666L9.99998 16.6666L3.33331 9.99998H9.16665V3.33331H10.8333V9.99998Z" fill="#8E8E8E"/> </g> <defs> <clipPath id="clip0_7727_93646"> <rect width="20" height="20" fill="white"/> </clipPath> </defs> </svg>
              <div>{ t('main.income') }</div>
            </Link>
            <Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.expense}`} className="btn btn-sqr mobileOperationBtn btn-lg btn-block not-active">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_7727_93652)"> <path d="M10.8333 9.99998V16.6666H9.16665V9.99998H3.33331L9.99998 3.33331L16.6666 9.99998H10.8333Z" fill="#8E8E8E"/> </g> <defs> <clipPath id="clip0_7727_93652"> <rect width="20" height="20" fill="white"/> </clipPath> </defs> </svg>
              <div>{ t('main.expense') }</div>
            </Link>
            <Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().operations.refund}`} className="btn btn-sqr mobileOperationBtn btn-lg btn-block not-active">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_7727_93658)"> <path d="M7.16669 5.83331V9.16665L2.16669 4.99998L7.16669 0.833313V4.16665H11.3334C13.1015 4.16665 14.7972 4.86903 16.0474 6.11927C17.2976 7.36951 18 9.0652 18 10.8333C18 12.6014 17.2976 14.2971 16.0474 15.5474C14.7972 16.7976 13.1015 17.5 11.3334 17.5H3.83335V15.8333H11.3334C12.6594 15.8333 13.9312 15.3065 14.8689 14.3688C15.8066 13.4312 16.3334 12.1594 16.3334 10.8333C16.3334 9.50723 15.8066 8.23546 14.8689 7.29778C13.9312 6.3601 12.6594 5.83331 11.3334 5.83331H7.16669Z" fill="#8E8E8E"/> </g> <defs> <clipPath id="clip0_7727_93658"> <rect width="20" height="20" fill="white" transform="translate(0.5)"/> </clipPath> </defs> </svg>
              <div>{ t('main.refund') }</div>
            </Link>
          </div>
        </div>
        <div className="mobile-nav__overlay" onClick={toggleMenu}></div>
      </label>
    </div>
	)
}
export default MobileNavLayout;
