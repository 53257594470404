import React, { Component, useEffect, useState } from "react";
import { getAppModule } from "./Store/appVersion";
import "./Styles/App.scss";
import "./Styles/layout/_version.scss";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { App as CapacitorApp } from "@capacitor/app";
// import setupStore from './Store/';

import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
// import ProtectedRouteOperation from './Components/ProtectedRoute/ProtectedRouteOperation';

// import Header from './Components/Header/Header';
import Forbidden from "./Components/Forbidden/Forbidden";

// Oперации
import NewSale from "./Components/NewSale/NewSale";
import Refund from "./Components/Refund/Refund";
import PurchaseRefund from "./Components/PurchaseRefunds/PurchaseRefund";
import Purchase from "./Components/Purchase/Purchase";
import IncomeWrapper from "./Components/Income/IncomeWrapper";
import ExpenseWrapper from "./Components/Expanse/ExpenseWrapper";

// Новые операции
import NewSaleOperation from "./Components/NewDesign/NewOperations/Sale/NewSaleOperation";
import NewPurchaseOperation from "./Components/NewDesign/NewOperations/Purchase/NewPurchaseOperation";
import NewRefundOperation from "./Components/NewDesign/NewOperations/Refund/NewRefundOperation";
import NewPurchaseRefundOperation from "./Components/NewDesign/NewOperations/PurchaseRefund/NewPurchaseRefundOperation";
import NewIncomeOperation from "./Components/NewDesign/NewOperations/Income/NewIncomeOperation";
import NewExpenseOperation from "./Components/NewDesign/NewOperations/Expense/NewExpenseOperation";
import CheckWrapper from "./Components/NewDesign/NewOperationsSuccess/CheckWrapper";
import NewCashboxSettings from "./Components/NewDesign/NewSettings/NewCashboxSettings";
import NewCashboxSettingsForbidenDefault from "./Components/NewDesign/NewSettings/NewCashboxSettingsForbidenDefault";
import NewCashboxSettingsForbidenOther from "./Components/NewDesign/NewSettings/NewCashboxSettingsForbidenOther";
import NewShiftOperation from "./Components/NewDesign/NewShift/ShiftContainer";

// Настройки
import CashboxSettings from "./Components/Settings/CashboxSettings";
import CashboxSettingsForbiden from "./Components/Settings/CashboxSettingsForbiden";

// Отчеты
import ReportX from "./Components/ReportX/ReportX";
import ReportZ from "./Components/ReportZ/ReportZ";
import ReportSections from "./Components/ReportSections/ReportSections";
// import OpersLog from './Components/OpersLog/OpersLog';
// import ShiftsLog from './Components/ShiftsLog/ShiftsLog';

import NewReport from "./Components/NewDesign/NewReports/NewReport";
import NewReportZClose from "./Components/NewDesign/NewReports/reportZClose/NewReportZClose";

// Сбор данных
// import Auth from './Components/Auth/Auth';
import AuthNew from "./Components/NewDesign/NewLogin";
import AuthGarant from "./Components/AuthGarant/Auth";
import Companies from "./Components/Companies/Companies";
import CompaniesNew from "./Components/NewDesign/NewCompanies/Companies";
import NewShift from "./Components/NewShift/NewShift";
import Registration from "./Components/Registration/Registration";
import SmsConfirmation from "./Components/Registration/SMSconfirmation";
import FinalStep from "./Components/Registration/FinalStepRegistration";
import PassRecover from "./Components/Registration/PasswordRecover/PassRecover";
import PassRecConfirmation from "./Components/Registration/PasswordRecover/PassRecConfirm";

//Проверка чека
import BillCheck from "./Components/CheckForClients/BillCheck";
import CheckPage from "./Components/CheckPage/CheckPage";

import Footer from "./Components/Footer/Footer";

//firebase
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import fire from "./Config/firebase";
import { I18nextProvider } from "react-i18next";
import i18n from "./Config/i18n";
import {
  IonApp,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  setupIonicReact,
  useIonRouter,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";

// const store = setupStore();

setupIonicReact({ mode: Capacitor.getPlatform() === "ios" ? "ios" : "md" });

const App = () => {
  const [user, setUser] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const authListener = () => {
    // fire.auth().onAuthStateChanged((user) => {
    //   if(user){
    //     this.setState({user});
    //   }else{
    //     this.setState({user: null});
    //   }
    // })
  };

  const ionRouter = useIonRouter();

  useEffect(() => {
    document.addEventListener("ionBackButton", (ev) => {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          CapacitorApp.exitApp();
        }
      });
    });
  }, []);

  const handleRefresh = (event) => {
    setTimeout(() => {
      history.go(0);
      event.detail.complete();
    }, 500);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <IonContent className="ion-padding" forceOverscroll="true">
        <IonRefresher
          slot="fixed"
          className="custom-for-ios"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Switch>
          <ProtectedRoute path="/www/comps" component={Companies} />
          <ProtectedRoute path="/www/comps_new" component={CompaniesNew} />
          <ProtectedRoute exact path="/www/kkms/:idKkm" component={NewShift} />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/shift_new"
            component={NewShiftOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idShift/close"
            component={ReportZ}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idShift/close_new"
            component={NewReportZClose}
          />
          {/* <ProtectedRoute exact path='/www/kkms/:idKkm/zlog' component={ShiftsLog} /> */}
          {/* <ProtectedRoute exact path='/www/shifts/:idShift/log' component={OpersLog} /> */}
          <ProtectedRoute
            exact
            path="/www/shifts/:idShift/reports/x"
            component={ReportX}
          />
          <ProtectedRoute
            exact
            path="/www/shifts/:idShift/report"
            component={NewReport}
          />
          <ProtectedRoute
            exact
            path="/www/shifts/:id/reports/sections"
            component={ReportSections}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/sale"
            component={NewSale}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/sale_new"
            component={NewSaleOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/purchase_new"
            component={NewPurchaseOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/refund_new"
            component={NewRefundOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/purchase_refund_new"
            component={NewPurchaseRefundOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/income_new"
            component={NewIncomeOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/expense_new"
            component={NewExpenseOperation}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/purchase"
            component={Purchase}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/refund"
            component={Refund}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/purchase_refund"
            component={PurchaseRefund}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/income"
            component={IncomeWrapper}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/expense"
            component={ExpenseWrapper}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/check/success"
            component={CheckWrapper}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/settings"
            component={CashboxSettings}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/settings/forbiden"
            component={CashboxSettingsForbiden}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/settings_new"
            component={NewCashboxSettings}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/settings/forbiden_new_default"
            component={NewCashboxSettingsForbidenDefault}
          />
          <ProtectedRoute
            exact
            path="/www/kkms/:idKkm/settings/forbiden_new_other"
            component={NewCashboxSettingsForbidenOther}
          />
          <Route exact path="/" component={AuthNew} />
          <Route exact path="/www/garant" component={AuthGarant} />
          <Route exact path="/www/lombard" component={AuthGarant} />
          <Route exact path="/www/showcheck" component={CheckPage} />
          <Route path="/www/register" component={Registration} />
          <Route path="/www/confirmation" component={SmsConfirmation} />
          <Route path="/www/congratulations" component={FinalStep} />
          <Route path="/www/passrec" component={PassRecover} />
          <Route
            path="/www/passrecconfirmation"
            component={PassRecConfirmation}
          />
          <Route path="/www/billcheck" component={BillCheck} />
          <Route path="*">
            <Redirect to="/www/comps_new" />
          </Route>
          <Route component={Forbidden} />
        </Switch>
        {location.pathname !== "/auth" &&
          location.pathname !== "/" &&
          (getAppModule() ? (
            <Footer />
          ) : (
            <div id="app-version" className="text-muted">
              <p>
                Онлайн-касса от <span>Kassa24 Business</span>
                <br />v 2.1.4 (2.0.6)
              </p>
            </div>
          ))}
      </IonContent>
    </I18nextProvider>
  );
};

export default withRouter(App);
