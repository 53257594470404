/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getHeaders, formatDate, getUid, getUser, getKkm, getError } from '../../Store/snippets';
import ReportZlayout from './ReportZlayout';
import PreloaderFullScreen from '../Preloader/Preloader';

let headers;
class ReportZ extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report: {},
			user: {},
			date: '',
			mounted: false,
			error: {
				status: false,
				title: '',
				text: '',
			}
		};
	}

	async componentDidMount() {
		headers = getHeaders(this.props.history);
		const currentDate = new Date();
		const date = formatDate(currentDate);
		const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
		// const uid = localStorage.getItem(`Uid_${idKkm}`);
		let uid
		try {
			uid = await getUid(this.props.history);
		} catch (err) {
			const error = getError(err, this.props.history)
			this.setState({
				...this.state,
				idKkm,
				error: {
					...this.state.error,
					status: true,
					title: `Ошибка ${error.Status}`,
					text: error.Message
				},
				mounted: true
			})
			throw err;
		}
		// getUid().then(res => uid = res);
		// console.log(uid);
		let user = getUser(this.props.history);
		let kkm = getKkm(this.props.history);

		axios.post(`${BASE_URL}/shifts/${user.IdShift}/z`, { 'IdKkm': idKkm }, { headers: { ...headers, 'uid': uid } }).then(resp => {
			user = { ...user, Lock: false };
			kkm = { ...kkm, Lock: false };
			localStorage.setItem('USER', JSON.stringify(user));
			localStorage.setItem('KKM', JSON.stringify(kkm));
			localStorage.removeItem('SHIFT');

			this.setState({
				...this.state,
				idKkm,
				report: resp.data.Data,
				user,
				kkm,
				date,
				mounted: true
			})

		}).catch(async err => {
			let errorData;
			try {
				if (err.response && err.response.status === 452) {
					localStorage.removeItem(`Uid_${idKkm}`);
					// this.props.history.push(`/www/kkms/${idKkm}`);
					// const uid = getUid(this.props.history);
					const uidResponse = await axios.get(`${BASE_URL}/uid/${idKkm}`, { headers: headers });
					uid = uidResponse.data.Data.Uid;
					localStorage.setItem(`Uid_${idKkm}`, uid);

					const resp = await axios.post(`${BASE_URL}/shifts/${user.IdShift}/z`, { 'IdKkm': idKkm }, { headers: { ...headers, 'uid': uid } });
					// console.log('resp', resp)
					user = { ...user, Lock: false };
					kkm = { ...kkm, Lock: false };
					localStorage.setItem('USER', JSON.stringify(user));
					localStorage.setItem('KKM', JSON.stringify(kkm));
					localStorage.removeItem('SHIFT');
					this.setState({
						...this.state,
						idKkm,
						report: resp.data.Data,
						user,
						kkm,
						date,
						mounted: true
					})
				} else {
					errorData = getError(err, this.props.history);
					throw errorData;
				}
			} catch (err) {
				// console.log('ERROR IN CATCH', err);
				if (err.response && err.response.status === 429) {
					errorData = {
						Status: "429",
						Message: "Операция не удалась. Касса заблокирована из-за работы с разных устройств"
					}
				}
				this.setState({
					...this.state,
					idKkm,
					error: {
						...this.state.error,
						status: true,
						title: `Ошибка ${errorData && errorData.Status}`,
						text: errorData.Message
					},
					mounted: true
				})
			}
		});
	}

	render() {
		return (
			this.state.mounted ? <ReportZlayout {...this.state} formatDate={formatDate} {...this.props} />
				: <PreloaderFullScreen />
		)
	}
}

export default ReportZ;
