import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL, getUser, getKkm, getUid, getHeaders, getError } from '../../Store/snippets';
import NewShiftLayout from './NewShiftLayout';
import NewShiftLayoutMobile from './NewShiftLayoutMobile';
import Testing from './testing';
import Modal from '../Modal/closeShiftModal'
// import Header from '../Header/Header';
import MobileNav from '../MobileNav/MobileNav';
// import {getAppModule, getAppModuleUrl} from "../../Store/appVersion";
import HeaderVersion from "../NewDesign/HeaderVersion/HeaderVersion";
// import PreloaderFullScreen from '../Preloader/Preloader';

let headers;

class NewShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      kkm: {},
      balances: {},
      shift: {},
      shiftOpen: false,
      shiftOpenByAnotherCashier: false,
      shiftBlocked: false,
      shiftExpired: false,
      kkmBlocked: false,
      shiftErrorText: '',
      mounted: false,
      pending: false,
      connection: true,
      connectionErrorText: '',
      modalWarning: false,
      shiftOpening: false,
    };
  }

  // shiftOpen - boolean, смена закрыта или открыта
  // shiftBlocked : boolean, работать со сменой нельзя, доступен только архив отчетов
  // shiftExpire : boolean, смена просрочена
  // kkmBlocked : касса заблокирована

  // getUid = async (idKkm) => {
  //   const fromLS = localStorage.getItem(`Uid_${idKkm}`);
  //   if (fromLS) return fromLS;
  //   try {
  //     const response = await axios.get(`${BASE_URL}/uid/${idKkm}`,
  //       {
  //         headers: headers
  //       });
  //     localStorage.setItem(`Uid_${idKkm}`, response.data.Data.Uid);
  //     return response.data.Data.Uid;
  //     // console.log(response);
  //   } catch (e) {
  //     localStorage.removeItem(`Uid_${idKkm}`);
  //     throw e;
  //   }
  // };

  // getShift = async (id) => {
  //   const shiftResponse = await axios.get(`${BASE_URL}/shifts/${id}`, { headers });
  //   // console.log('shiftResponse', shiftResponse);
  //   if (shiftResponse.data.Status === 200) {
  //     const shift = shiftResponse.data.Data;
  //     await this.setState({
  //       ...this.state,
  //       shift
  //     })
  //   }
  //   throw new Error(shiftResponse.status);
  // }

  checkShift = async ({ kkm, user }) => {
    let shiftErrorText = '';
    let shift, shiftOpen;

    if (kkm.Lock === true && user.Lock === true && kkm.IdShift === user.IdShift) {
      shiftOpen = true
    }

    if (!kkm.IsActive) {
      return {
        shiftOpen,
        shiftBlocked: true,
        shiftErrorText: 'Касса не оплачена.'
      };
    }

    if (kkm.IdStatusKkm === 3) {
      return {
        shiftOpen,
        shiftBlocked: true,
        shiftErrorText: `${shiftOpen ? 'Смена на кассе открыта, но касса заблокирована в ОФД' : 'Касса заблокирована в ОФД'}. Это означает, что у вас не получится выбить чек. Если нужно ${shiftOpen ? 'закрыть' : 'открыть'} смену, можете это сделать, нажав кнопку "${shiftOpen ? 'Закрыть' : 'Открыть'} смену". Обратитесь в тех. поддержку чтобы разобраться с этой проблемой.`
      };
    }
    if (kkm.IdStatusKkm === 1) {
      return {
        shiftBlocked: true,
        shiftErrorText: 'Касса в процессе регистрации.'
      }
    }

    if (kkm.Lock === true) {
      // Если есть на кассе есть смена

      if (user.Lock === true) {
        if (kkm.IdShift !== user.IdShift) {
          return {
            shiftOpenByAnotherCashier: true,
            shiftBlocked: true,
            // shiftErrorText: `Смена открыта кассиром ${user.Name}.`,
            shiftErrorText: `Смена открыта другим кассиром.`
          };
        } else {

          const shiftResponse = await axios.get(`${BASE_URL}/shifts/${kkm.IdShift}`, { headers });

          try {
            await getUid(this.props.history);

          } catch (error) {
            // console.log('error', error);
            return {
              shift,
              shiftBlocked: true,
              shiftOpen: true,
              shiftErrorText: 'Касса заблокирована из-за работы с разных устройств.'
            }
          }

          shift = shiftResponse.data.Data;
          localStorage.setItem('SHIFT', JSON.stringify(shift));
        }
        const currentDate = new Date();
        const shiftOpenDate = new Date(shift.DateOpen);
        const shiftExpiredDifferense = (currentDate.getTime() - shiftOpenDate.getTime()) / 1000 / 60 / 60;
        if (currentDate.getTime() > shiftOpenDate.getTime()) {
          if (shiftExpiredDifferense > 23.9) {
            return {
              shift,
              shiftExpired: true,
              shiftOpen: true,
              shiftErrorText: 'Смена просрочена.'
            }
          }
        } else {
          return {
            shift,
            shiftExpired: true,
            shiftOpen: true,
            shiftErrorText: 'Время на вашем устройстве не соответствует действительному.'
          }
        }
      } else {
        return {
          shiftOpenByAnotherCashier: true,
          shiftBlocked: true,
          // shiftErrorText: `Смена открыта кассиром ${user.Name}`
          shiftErrorText: `Смена открыта другим кассиром.`
        };
      }

      return {
        shift,
        shiftBlocked: false,
        shiftErrorText,
        shiftOpen: true
      };

    } else {
      // Если на кассе нет смены

      if (user.Lock === true) {
        return {
          shiftBlocked: true,
          shiftErrorText: 'У вас уже есть открытая смена на другой кассе'
        };
      }

      // if (kkm.IdShift !== user.IdShift) {
      //   return {
      //     shiftBlocked: true,
      //     shiftErrorText: 'Смена открыта другим кассиром'
      //   };
      // }

      return {
        shift,
        shiftOpen: false
      };
    }
  };

  logout = () => {
		// axios.post(`https://api.amplitude.com/2/httpapi`,
        //     {
        //         api_key: "d314c6311f60a96a3d7ec5a856b85143",
        //         events: [
        //             {
        //                 event_type: "login",
        //                 event_category: "login",
        //                 event_type: "logout success",
        //                 device_id: "10001",
        //                 user_id: "+" + "123456",
        //             }
        //         ]
        //     })

    var phone = "";
		if (localStorage.getItem('KKM_GARANT') !== null) {
			this.props.history.push('/www/garant')
			phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		}else if (localStorage.getItem('KKM_LOMBARD') !== null){
			this.props.history.push('/www/lombard')
			phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		}else{
			this.props.history.push('/')
			phone = window.localStorage.getItem('phone_login');
			window.localStorage.clear();
			window.localStorage.setItem('phone_login', phone)
		}
	}

  openShift = async () => {
    await this.setState({
      ...this.state,
      pending: true,
      shiftOpening: true
    })
    const kkm = getKkm(this.props.history);
    const user = getUser(this.props.history);
    const ShiftIndex = kkm.ShiftIndex + 1;
    try {
      const openShiftResponce = await axios.post(`${BASE_URL}/shifts`, { 'IdKkm': this.state.kkm.Id }, { headers });
      const shift = openShiftResponce.data.Data.Shift;
      const uid = openShiftResponce.headers.uid;

      const newKkm = {
        ...kkm,
        ShiftIndex,
        IdShift: shift.Id,
        Lock: true
      }
      const newUser = {
        ...user,
        IdShift: shift.Id,
        Lock: true
      }

      localStorage.setItem('SHIFT', JSON.stringify(shift));
      localStorage.setItem('KKM', JSON.stringify(newKkm));
      localStorage.setItem(`Uid_${this.state.kkm.Id}`, uid);
      localStorage.setItem('USER', JSON.stringify(newUser));

      await this.setState({
        ...this.state,
        shift,
        kkm: newKkm,
        user: newUser,
        pending: false,
        shiftOpen: true,
        shiftOpening: false,
        uid
      })

    } catch (error) {
      const errorData = getError(error, this.props.history);
      await this.setState({
        ...this.state,
        pending: false,
        shiftOpening: false,
        shiftErrorText: errorData.Message
      })
    }
  }

  openModal = () => {
    this.setState({
      ...this.state,
      modalWarning: true
    })
  }

  closeModal = () => {
    this.setState({
      ...this.state,
      modalWarning: false
    })
  }

  closeShift = async () => {
    await this.setState({
      ...this.state,
      pending: true
    })
    this.props.history.push(`/www/kkms/${this.state.kkm.Id}/close`);
  }

  async componentDidMount() {
    headers = getHeaders(this.props.history);
    const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
    const user = getUser(this.props.history);

    try {
      const updatedUserResponse = await axios.get(`${BASE_URL}/users/${user.Id}`, { headers });
      const updatedUser = updatedUserResponse.data.Data;
      localStorage.setItem(`USER`, JSON.stringify(updatedUser));

      const kkmResponse = await axios.get(`${BASE_URL}/kkms/${idKkm}`, { headers });
      const kkm = kkmResponse.data.Data.Kkm;
      localStorage.setItem(`KKM`, JSON.stringify(kkm));
      localStorage.setItem("SHIFT_INDEX", kkmResponse.data.Data.Kkm.ShiftIndex);

      const balancesResponse = await axios.get(`${BASE_URL}/kkms/${kkm.Id}/balances`, { headers });
      const balances = await balancesResponse.data.Data.Balances;
      localStorage.setItem(`BALANCES`, JSON.stringify(balances));

      await this.setState({
        balances,
        user,
        kkm
      });
    } catch (e) {
      const errorData = getError(e, this.props.history);
      await this.setState({
        ...this.state,
        connection: false,
        connectionErrorText: errorData.Message,
        user
      })
      throw e
    }

    await this.checkShift(this.state).then(res => {
      this.setState({
        ...this.state,
        ...res,
        mounted: true
      })
    })
  }


  render() {
    return (
      <React.Fragment>
        <HeaderVersion isActive={this.state.shiftOpen} errorText={this.state.shiftErrorText} {...this.props} />
        <MobileNav isActive={this.state.shiftOpen} />
        {this.state.mounted ? (
          window.matchMedia("(min-width: 1100px)").matches ?
            <NewShiftLayout {...this.state} openShift={this.openShift} openModal={this.openModal} history={this.props.history}/>
            :
            <NewShiftLayoutMobile {...this.state} openShift={this.openShift} openModal={this.openModal} />
        )
          : <Testing {...this.state} connection={this.state.connection} text={this.state.connectionErrorText} />}
        {
          /* {this.state.mounted ?
          (
            this.state.shiftOpening ? <Testing connection={this.state.connection} text={this.state.connectionErrorText} /> : <NewShiftInterface {...this.state} openShift={this.openShift} openModal={this.openModal} />
          )
          : <PreloaderFullScreen />} */}

        <Modal isOpen={this.state.modalWarning} closeModal={this.closeModal} closeShift={this.closeShift} logout={this.logout} pending={this.state.pending} balances={this.state.balances} />
      </React.Fragment >
    );
  }
}

export default NewShift;
