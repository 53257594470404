import React from 'react';
import LogoFiscal from '../Svg/logo.svg?react';
import axios from 'axios';
import { BASE_URL } from '../../Store/snippets';
import Modal from '../Modal/billCheckModal';

let headers;

const dateFrom = new Date();
		var dd = dateFrom.getDate();
		var mm = dateFrom.getMonth() + 1;
		var yyyy = dateFrom.getFullYear();
		var hh = dateFrom.getHours();
		var min = dateFrom.getMinutes();
		if(dd < 10){
			dd = "0"+ dd;
		}
		if(mm < 10){
			mm = "0"+ mm;
		}
		if(hh < 10){
			hh = "0"+ hh;
		}
		if(min < 10){
			min = "0"+ min;
		}

const today = yyyy+ '-' + mm + '-' + dd + "T" + hh + ":" + min;

const urlParams = new URLSearchParams(window.location.search);
const ZNM = urlParams.get('znm')
const DATETIME = urlParams.get('datetime')
const FN = urlParams.get('fn')
const TOTAL = urlParams.get('total')

export default class BillCheck extends React.Component {
    
    state = {
        znm: '',
        fn: '',
        date: today,
        total: '',
        modal: {
            status: false,
            header: "",
            img: ''
        }
    }

    handleChangeCheckNumber = (e) => {
        this.setState({
            checkNumber: e.target.value
        })
    }
    handleChangeZnm = (e) => {
        this.setState({
            znm: e.target.value
        })
    }
    handleChangeFn = (e) => {
        this.setState({
            fn: e.target.value
        })
    }
    handleChangeDate = (e) => {
        this.setState({
            date: e.target.value
        })
    }

    handleChangeTotal = (e) => {
        this.setState({
            total: e.target.value
        })
    }
    
    
    componentDidMount() {
        if(window.location.search !== '') {
            this.setState({
                znm: ZNM,
                fn: FN,
                date: DATETIME,
                total: TOTAL,
            }, () => axios.get(`${BASE_URL}/receipt?datetime=${this.state.date}&znm=${this.state.znm}&fn=${this.state.fn}&total=${this.state.total}`, { headers })
            .then(resp => {
                    resp.data.Data ? 
                    this.setState({
                        modal: {
                            img: resp.data.Data.Receipt,
                            status: true,
                            header: "Дубликат чека"
                        },
                        checkNumber: '',
                        znm: '',
                        fn: '',
                        date: today,
                        total: '',
                    })
                    :
                    this.setState({
                        modal: {
                            header: "Чек не найден",
                            status: true
                        }
                    })
                }
            )
            .catch(err => {
                console.log("Error", err)
            })
            )
        }
        document.title = "Проверка чека"
    }

    handleClick = async() => {
        await axios.get(`${BASE_URL}/receipt?datetime=${this.state.date}&znm=${this.state.znm}&fn=${this.state.fn}&total=${this.state.total}`, { headers })
        .then(resp => {
                resp.data.Data !== null ? 
                this.setState({
                    modal: {
                        img: resp.data.Data.Receipt,
                        status: true,
                        header: "Дубликат чека"
                    },
                    checkNumber: '',
                    znm: '',
                    fn: '',
                    date: today,
                    total: '',
                })
                :
                this.setState({
                    modal: {
                        header: "Чек не найден",
                        status: true
                    }
                })
            }
        )
    }

    closeModal = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                status: false
            }
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="auth text-center">
                    <form className="form-signin">
                        <LogoFiscal className="mb-5" />
                        <h1 className='h3 mb-1 font-weight-normal'>Fiscal24</h1>
                        <h5 className="mb-4 text-muted">Онлайн-касса</h5>
                        <p style={{
                            fontSize: "15px",
                            marginBottom: "30px"
                            }} 
                        className="text-muted">
                            Укажите данные чека
                        </p>
                        {/* <input
                            type="number"
                            id="inputCheckNumber"
                            name="checkNumber"
                            className="form-control"
                            placeholder="Номер чека"
                            autoComplete="off"
                            required
                            autoFocus
                            value={this.state.checkNumber}
                            onChange={this.handleChangeCheckNumber}
                            // ref={passField}
                        /> */}
                        <input
                            type="number"
                            id="inputCashboxZnm"
                            name="znm"
                            className="form-control"
                            placeholder="ЗНМ Кассы"
                            autoComplete="off"
                            required
                            value={this.state.znm}
                            onChange={this.handleChangeZnm}
                            // ref={passField}
                        />
                        <input
                            type="number"
                            id="inputFiscalCode"
                            name="fn"
                            className="form-control"
                            placeholder="Фискальный признак"
                            autoComplete="off"
                            required
                            value={this.state.fn}
                            onChange={this.handleChangeFn}
                            // ref={passField}
                        />
                        <input
                            type="datetime-local"
                            id="inputCheckDate"
                            name="datetime"
                            className="form-control"
                            placeholder="Дата"
                            autoComplete="off"
                            required
                            value={this.state.date}
                            onChange={this.handleChangeDate}
                        />
                        <input
                            type="number"
                            id="inputTotalsum"
                            name="total"
                            className="form-control"
                            placeholder="Итоговая сумма"
                            autoComplete="off"
                            required
                            value={this.state.total}
                            onChange={this.handleChangeTotal}
                            // ref={passField}
                        />
                        <button className={`btn btn-primary btn-block`} type="button" onClick={this.handleClick}>
                            Подтвердить
                        </button>
                    </form>
                    <Modal show={this.state.modal.status} header={this.state.modal.header} img={this.state.modal.img} onClose={this.closeModal} />
                </div>
            </React.Fragment>
        )
    }
}