import { Share } from "@capacitor/share";

export const shareFile = async (outputFilename, fileData, text) => {
  try {
    await Share.share({
      title: "Экспорт файла",
      text: text || "Отчет успешно экспортирован в Эксель",
      url: fileData,
      dialogTitle: "Share with",
    });
  } catch (e) {
    console.error("Не удалось экспортировать файл", e);
  }
};
