/* eslint-disable eqeqeq */
import React, {useEffect, useState} from 'react'
import NewReportTop from "./NewReportTop";
// import Header from "../../Header/Header";
import {useHistory} from "react-router-dom";
import NewReportTab from "./NewReportTab";
import NewReportOperations from "./tabs/NewReportOperations";
import NewReportX from "./tabs/NewReportX";
import NewReportSections from "./tabs/NewReportSections";
import NewReportZ from "./tabs/NewReportZ";
import axios from "axios";
import {BASE_URL, getHeaders, getKkm, getUser, getDataToString, getCurrentDate} from "../../../Store/snippets";
import {getAppModule} from "../../../Store/appVersion";
// import PreloaderFullScreen from "../../Preloader/Preloader";
import style from '../styles/pages/report/NewReport.module.scss'
import NewReportZDuplicate from "./duplicates/NewReportZDuplicate";
// import {ReactComponent as ArrowRightSFill} from "../svg/arrow-right-s-fill.svg?react";
import HeaderVersion from "../HeaderVersion/HeaderVersion";
import Error503Modal from '../Modals/Error500Modal';
import { useTranslation } from "react-i18next";
import { SORT_TYPES } from '../../../constants/sort-table';
import { Capacitor } from '@capacitor/core';


let dateISOstring = {
	"FromDate" : getDataToString(2, getCurrentDate().weekdayFromToday),
	"ToDate": getDataToString(1, getCurrentDate().today),
}
let dateISOstringZ = {
	"FromDate" : getDataToString(2, getCurrentDate().weekdayFromToday, 'z'),
	"ToDate": getDataToString(1, getCurrentDate().today, 'z'),
}

let initialDateISOstring = {
	"FromDate" : getDataToString(1, getCurrentDate().yearFromToday),
	"ToDate": getDataToString(2, getCurrentDate().today),
}
// let initialDateISOstring2 = {
// 	"FromDate" : getDataToString(1, getCurrentDate().yearFromToday, 'z'),
// 	"ToDate": getDataToString(2, getCurrentDate().today, 'z'),
// }

const NewReport = () => {
	const { t } = useTranslation()

	const navList = [
		{
			id: 1,
			name: t('report.operation_log')
		},
		{
			id: 2,
			name: t('report.x_report')
		},
		{
			id: 3,
			name: t('report.z_report_archive')
		},
		{
			id: 4,
			name: t('report.by_sections')
		},
	]

	const history = useHistory()

	const kkm = getKkm()
	const headers = getHeaders(history)
	const user = getUser()
	const idKkm = JSON.parse(localStorage.getItem('CURRENT_KKM_ID'));
	// let uid = localStorage.getItem(`Uid_${idKkm}`);

	const [activeNav, setActiveNav] = useState(1);
	const [step, setStep] = useState(1);
	const [report, setReport] = useState({
		reportX: [],
		reportZ: [],
		reportOperations: [],
		reportSections: [],
	})
	const [isLoading, setIsLoading] = useState(false)
	const [reportOperationModalInfo, setReportOperationModalInfo] = useState({
		status: false,
		check: null,
		header: '',
	})

	const [reportZDuplicateIsOpen, setReportZDuplicateIsOpen] = useState(false)
	const [reportZChoosen, setReportZChoosen] = useState(null)
	const [calendarIsAvailableForOperations, setcalendarIsAvailableForOperations] = useState(false)
	const [reportZTotalAmounts, setReportZTotalAmounts] = useState(null)
	const [errorData, setErrorData] = useState(null)

	useEffect(() => {
		if (localStorage.getItem('reportStep') !== null) {
			setActiveNav(JSON.parse(localStorage.getItem('reportStep')))
			setStep(JSON.parse(localStorage.getItem('reportStep')))
		}

		return () => {
			if (localStorage.getItem('reportStep') !== null) {
				localStorage.removeItem('reportStep')
			}
		}
	}, [])

	useEffect(() => {
		document.getElementById('root').style.backgroundColor = getAppModule() ? '#F5F5F5' : 'transparent'
		return () => document.getElementById('root').style.backgroundColor = 'transparent'
	}, [getAppModule()])



	const getReportOperations = async () => {
		try {
			setIsLoading(true)
			const response = await axios.post(`${BASE_URL}/kkms/${idKkm}/reports`, dateISOstring , {headers: {...headers}} )
			const operations = response.data.Data.Operations;
			setReport({
				...report,
				reportOperations: operations,
			})
			setIsLoading(false)
		} catch (e) {
			setErrorData(e.response)
		}
	}

	const checkReportOperations = async () => {
		try {
			setIsLoading(true)
			const response = await axios.post(`${BASE_URL}/kkms/${idKkm}/reports`, initialDateISOstring , {headers: {...headers}} )
			if (response.data.Data.Operations.length) {
				setcalendarIsAvailableForOperations(true)
			}
			setIsLoading(false)
		} catch (e) {
			setErrorData(e.response)
		}
	}


	const getReportX = async () => {
		try {
			setIsLoading(true)
			const uid = await getUid(idKkm);
			const response = await axios.get(`${BASE_URL}/shifts/${kkm.IdShift}/x`, {
				headers: { ...headers, 'uid': uid }
			})

			await setUid(response, idKkm);

			setReport({
				...report,
				reportX: response.data.Data,
			})

			setIsLoading(false)
		} catch (e) {
			setErrorData(e.response)
		}
	}

	const setUid = async (response, idKkm) => {
		const { headers: { uid, Uid } } = response

		console.log('setUid() SET UID NewReport:', uid, Uid);
		localStorage.setItem(`Uid_${idKkm}`, uid || Uid);
	}

	const getUid = async (idKkm) => {
		const fromLS = localStorage.getItem(`Uid_${idKkm}`);
		if (fromLS) return fromLS;
		try {
			const response = await axios.get(`${BASE_URL}/uid/${idKkm}`,
				{
					headers: headers
				});
				console.log('getUid() SET UID NewReport:', response.data.Data.Uid);
			await localStorage.setItem(`Uid_${idKkm}`, response.data.Data.Uid);
			return response.data.Data.Uid;
		} catch (e) {
			await localStorage.removeItem(`Uid_${idKkm}`);
			throw e;
		}
	};

	const getReportZTotalAmounts = (reportZ) => {
		const totalResult = {
			SalesAmount: 0,
			PurchasesAmount: 0,
			IncomesAmount: 0,
			ExpensesAmount: 0,
			RefundsAmount: 0,
			PurchaseRefundsAmount: 0,
		}

		for (let reportZItem of reportZ) {
			totalResult.SalesAmount += Math.trunc(reportZItem.SalesAmount)
			totalResult.PurchasesAmount += Math.trunc(reportZItem.PurchasesAmount)
			totalResult.IncomesAmount += Math.trunc(reportZItem.IncomesAmount)
			totalResult.ExpensesAmount += Math.trunc(reportZItem.ExpensesAmount)
			totalResult.RefundsAmount += Math.trunc(reportZItem.RefundsAmount)
			totalResult.PurchaseRefundsAmount += Math.trunc(reportZItem.PurchaseRefundsAmount)
		}

		setReportZTotalAmounts(totalResult)
	}

	const getReportZ = async () => {
		try {
			setIsLoading(true)
			const response1 = await axios.get(`${BASE_URL}/kkms/${idKkm}/zlog?datefrom=${dateISOstringZ.FromDate}&dateto=${dateISOstringZ.ToDate}`, { headers })
			getReportZTotalAmounts(response1.data.Data)

			setReport({
				...report,
				reportZ: response1.data.Data,
			})

			setIsLoading(false)
		} catch (e) {
			setErrorData(e.response)
		}
	}

	const getReportSections = async () => {
		try {
			setIsLoading(true)
			const response = await axios.get(`${BASE_URL}/shifts/${kkm.IdShift}/sections/reports`, { headers })
			setReport({
				...report,
				reportSections: response.data.Data.Sections,
			})
			setIsLoading(false)
		} catch (e) {
			setErrorData(e.response)
		}
	}



	const showReportOperationsDuplicate = async(docId) => {
		try {
			const response = await axios.get(`${BASE_URL}/operations/${docId}`, { headers })
			Promise.all([response.data.Data]).then((data) => {
				console.log(data);
				setReportOperationModalInfo({
					status: true,
					check: {
						id: data[0].Document.Id,
						link: data[0].Location,
						img: data[0].Receipt
					},
					header: 'Дубликат чека'
				})
			})
		} catch (e) {
			setErrorData(e.response)
		}
	}

	const resetReportOperationsDuplicate = () => {
		setReportOperationModalInfo({
			status: false,
			check: null,
			header: ''
		})
	}

	const showReportZDuplicate = (id) => {
		setReportZChoosen(report.reportZ.find(reportZItem => reportZItem.Id === id))
		setReportZDuplicateIsOpen(!reportZDuplicateIsOpen)
	}

	const filterReportOperationByDate = (dates) => {
		axios.post(`${BASE_URL}/kkms/${idKkm}/reports`, dates, {headers: {...headers}} )
			.then(response => {
				setReport({
					...report,
					reportOperations: response.data.Data.Operations,
				})
			})
	}

	const filterReportZByDate = (dates) => {
		axios.get(`${BASE_URL}/kkms/${idKkm}/zlog?datefrom=${dates.FromDate}&dateto=${dates.ToDate}`, {headers})
			.then(response => {
				setReport({
					...report,
					reportZ: response.data.Data,
				})
				getReportZTotalAmounts(response.data.Data)
			})
	}

	const filterReportData = (type, sortingIsAscending) => {
		if (type === SORT_TYPES.DATE) {
			const sortedOperations = [...report.reportOperations].sort((a, b) => 
				sortingIsAscending[SORT_TYPES.DATE]
					? new Date(a.DateDocument) - new Date(b.DateDocument)
					: new Date(b.DateDocument) - new Date(a.DateDocument)
			);
			setReport({
				...report,
				reportOperations: sortedOperations,
			});
		}
		
		if (type === SORT_TYPES.NUM_CHECK) {
			const sortedOperations = [...report.reportOperations].sort((a, b) => 
				sortingIsAscending[SORT_TYPES.NUM_CHECK]
					? a.Id - b.Id
					: b.Id - a.Id
			);
			setReport({
				...report,
				reportOperations: sortedOperations,
			});
		}
	};

	const filterReportZData = (type, sortingIsAscending) => {
		if (type === 'shift' && sortingIsAscending.shift) {
			setReport({
				...report,
				reportZ: report.reportZ.sort((a, b) => a.ShiftIndex - b.ShiftIndex)
			})
		}
		if (type === 'shift' && !sortingIsAscending.shift) {
			setReport({
				...report,
				reportZ: report.reportZ.reverse()
			})
		}

		// balanceOpen
		if (type === 'balanceOpen' && sortingIsAscending.balanceOpen) {
			setReport({
				...report,
				reportZ: report.reportZ.sort((a, b) => a.BalanceOpen - b.BalanceOpen)
			})
		}
		if (type === 'balanceOpen' && !sortingIsAscending.balanceOpen) {
			setReport({
				...report,
				reportZ: report.reportZ.reverse()
			})
		}

		// balanceClose
		if (type === 'balanceClose' && sortingIsAscending.balanceClose) {
			setReport({
				...report,
				reportZ: report.reportZ.sort((a, b) => a.BalanceClose - b.BalanceClose)
			})
		}
		if (type === 'balanceClose' && !sortingIsAscending.balanceClose) {
			setReport({
				...report,
				reportZ: report.reportZ.reverse()
			})
		}

		// operationCount
		if (type === 'operationCount' && sortingIsAscending.operationCount) {
			setReport({
				...report,
				reportZ: report.reportZ.sort((a, b) => a.Count - b.Count)
			})
		}
		if (type === 'operationCount' && !sortingIsAscending.operationCount) {
			setReport({
				...report,
				reportZ: report.reportZ.reverse()
			})
		}

		// cashierName
		if (type === 'cashierName' && sortingIsAscending.cashierName) {
			setReport({
				...report,
				reportZ: report.reportZ.sort((a, b) => a.User.Name.localeCompare(b.User.Name))
			})
		}
		if (type === 'cashierName' && !sortingIsAscending.cashierName) {
			setReport({
				...report,
				reportZ: report.reportZ.reverse()
			})
		}
	}


	const selectTabComponent = () => {
		switch (step) {
			case 1:
				return (
									<NewReportOperations
										reportOperations={report.reportOperations}
										idKkm={idKkm}
										showReportOperationsDuplicate={showReportOperationsDuplicate}
										filterReportOperationByDate={filterReportOperationByDate}
										filterReportData={filterReportData}
										weekdayFromToday={getCurrentDate().weekdayFromToday}
										today={getCurrentDate().today}
										calendarIsAvailableForOperations={calendarIsAvailableForOperations}
										reportOperationModalInfo={reportOperationModalInfo}
										resetReportOperationsDuplicate={resetReportOperationsDuplicate}
										getReportOperations={getReportOperations}
										checkReportOperations={checkReportOperations}
										isLoading={isLoading}
									/>
								)
			case 2:
				return <NewReportX
					reportX={report.reportX}
					user={user}
					getReportX={getReportX}
					isLoading={isLoading}
				/>
			case 3:
				return <NewReportZ
					reportZ={report.reportZ}
					idKkm={idKkm}
					showReportZDuplicate={showReportZDuplicate}
					filterReportZByDate={filterReportZByDate}
					weekdayFromToday={getCurrentDate().weekdayFromToday}
					today={getCurrentDate().today}
					reportZTotalAmounts={reportZTotalAmounts}
					filterReportZData={filterReportZData}
					getReportZ={getReportZ}
					isLoading={isLoading}
				/>
			case 4:
				return <NewReportSections reportSections={report.reportSections} getReportSections={getReportSections} isLoading={isLoading} />
			default:
				return (
					<NewReportOperations
						reportOperations={report.reportOperations}
						idKkm={idKkm}
						showReportOperationsDuplicate={showReportOperationsDuplicate}
						filterReportOperationByDate={filterReportOperationByDate}
						filterReportData={filterReportData}
						weekdayFromToday={getCurrentDate().weekdayFromToday}
						today={getCurrentDate().today}
						calendarIsAvailableForOperations={calendarIsAvailableForOperations}
						reportOperationModalInfo={reportOperationModalInfo}
						resetReportOperationsDuplicate={resetReportOperationsDuplicate}
						getReportOperations={getReportOperations}
						isLoading={isLoading}
					/>
				)
		}
	}

	return (
		<React.Fragment>
						<HeaderVersion isActive={true} />
						<main role="main" id="reports-main" className={`${style.container} ${style[`container--${Capacitor.getPlatform()}`]}`}>
							{
								!reportZDuplicateIsOpen
									?
									<React.Fragment>
									<NewReportTop step={step} idKkm={idKkm}/>
									<NewReportTab
										navList={navList}
										activeNav={activeNav}
										setActiveNav={setActiveNav}
										setStep={setStep}
										user={user}
										kkm={kkm}
									/>
									{ selectTabComponent() }
								</React.Fragment>
									:
									<NewReportZDuplicate
										reportZChoosen={reportZChoosen}
										user={user}
										setReportZDuplicateIsOpen={setReportZDuplicateIsOpen}
									/>
							}
							{
								errorData && errorData.status > 499 && (
									<Error503Modal />
								)
							}
						</main>
		</React.Fragment>
	)
}

export default NewReport
