import React from "react";
import style from "../../styles/pages/report/duplicates/NewReportZDuplicateOperations.module.scss";
import stylePdf from "../../styles/pages/report/duplicates/NewReportZDuplicateTop.module.scss";
import {formatDate} from "../../../../Store/snippets";
import { useTranslation } from "react-i18next";
const NewReportZDuplicateOperations = ({ reportZChoosen }) => {
	const { t } = useTranslation()

	const reporZtOperationsRenderList = reportZChoosen.Operations !== undefined && reportZChoosen.Operations.map(reportZOperation => {
		return (
			<div className={`${style['table__list']} ${stylePdf['pdf-list']}`} key={reportZOperation.Id}>
				<div className={style['table__item']}>
					<span>{reportZOperation.TypeDocument.Name}</span>
				</div>
				<div className={style['table__item']}>
					<span>{formatDate(new Date(reportZOperation.DateDocument))}</span>
				</div>
				<div className={style['table__item']}>
					<span>{reportZOperation.NumberDoc  || t('other.not_specified') }</span>
				</div>
				<div className={style['table__item']}>
					<span>{new Intl.NumberFormat('ru-RU').format(reportZOperation.Value)}</span>
				</div>
			</div>
		)
	})


	return (
		<React.Fragment>
			<div className={`${style.card} ${style['card--white-nopadding']} ${style['mt-20']}`}>
				<div className={style.table}>
					<div className={style['table__content']}>
						<div className={style['table__header']}>
							<div>{ t('report.operation_list') }</div>
						</div>
						{ reportZChoosen.Operations !== undefined
							?
							<React.Fragment>
								<div
									className={style['table__header']}
								>
									<div>{ t('report.operation_type') }</div>
									<div>{ t('report.date') }</div>
									<div>{ t('report.receipt_number') }</div>
									<div>{ t('report.amount') }</div>
								</div>
								<div className={style['table__body']}>
									{reporZtOperationsRenderList}
								</div>
							</React.Fragment>
							:
							<div className={`${style['table__list']} ${stylePdf['pdf-list']}`}>
								<div className={style['table__item']}>{ t('report.no_operations') }</div>
							</div>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicateOperations
