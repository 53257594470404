import React from 'react';
import style from '../Prechek.module.css'
import PreCheckControls from './preCheckControl/PreCheckControls.jsx'

function PreCheckPosition (props) {
    return (
        <React.Fragment>
            <li className={`list-group-item ${style.lhCondensed} ${props.Storno && 'bg-light'}`}>
                <div className="d-flex justify-content-between pb-1">
                    <div>
                        <h6 className={`my-0 text-truncate pb-1 ${props.Storno && 'text-danger'}`}>
                            {props.Name}
                            <span> x <strong>{props.Qty} {props.Unit.ShortName}</strong></span>
                        </h6>
                        <small className={`text-muted`}>Цена {props.Price}</small>
                        {props.Discount > 0 &&
                        <React.Fragment>
                            {/* <span className="text-muted s-d mr-1 ml-1">•</span> */}
                            {/* !props.Storno ?
                                <small className="badge badge-pill badge-success">Скидка {props.DiscountPercent}% = {props.Discount}₸</small>
                                :
                                <small className="badge badge-pill badge-secondary">Скидка {props.DiscountPercent}% = {props.Discount}₸</small>
                            */}

                        </React.Fragment>
                        }
                        {props.Markup > 0 &&
                        <React.Fragment>
                            {/* <span className="text-muted s-d mr-1 ml-1">•</span> */}
                            {/* !props.Storno ?
                                <small className="badge badge-pill badge-warning">Наценка {props.MarkupPercent}% = {props.Markup}₸</small>
                                :
                                <small className="badge badge-pill badge-secondary ">Наценка {props.MarkupPercent}% = {props.Markup}₸</small>
                            */}

                        </React.Fragment>
                        }
                        {props.Nds > 0 &&
                        <React.Fragment>
                            <span className="text-muted s-d mr-1 ml-1">•</span>
                            {!props.Storno ?
                                <small className="badge badge-pill badge-info">НДС {props.Nds}</small>
                                :
                                <small className="badge badge-pill badge-secondary ">НДС {props.Nds}</small>
                            }

                        </React.Fragment>
                        }
                    </div>
                    <strong className={`${props.Storno ? (style.storno + ' text-danger') : '' }`}>{props.Total}</strong>
                </div>
                <div className="pb-1 d-flex justify-content-between">
                    <small>
                        {props.Section.Name &&
                        <React.Fragment>
                            <span className="text-muted">Секция "{props.Section.Name}"</span>
                        </React.Fragment>
                        }
                    </small>
                </div>

                <PreCheckControls index={props.index}/>
            </li>
        </React.Fragment>
    );
}

export default PreCheckPosition;
