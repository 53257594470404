import React from 'react';
import Warning from '../../svg/newCloseShift.svg?react';
import style from "../../styles/pages/shift/modals/newCloseShiftModal.module.scss"
import button from '../../styles/components/actionBtn.module.scss'
import Spinner from '../../../Common/Spinner';
import { useTranslation } from "react-i18next";

const Modal = (props) => {
	const { t } = useTranslation()

	const getBalanceText = (text) => {
		if (text === 'Наличные средства') {
			return t('close_shift.cash_funds')
		}
		if (text === 'Безналичные средства') {
			return t('close_shift.cashless_funds')
		}

		return text
	}

	if (!props.isOpen) {
		return null
	} else {
		return (
			<div className={style.modal}>
				<div className={style['modal__overlay']}>
					<div className={`${style.card} ${style['card--white']}`}>
						<div className={style.cardHeader}>
							<Warning />
							<p>{ t('close_shift.attention_closing_shift') }</p>
						</div>
						<div className={style.cardBody}>
							<p>{ t('close_shift.shift_closure_results') }</p>
							<p className={style.pls}>{ t('close_shift.please_check_cash_register_balances') }</p>
							<div className={style.balanceWrapper}>
								{
									props.balances && props.balances.map((balance) => (
										<div className={style.row}>
											<p>{ getBalanceText(balance.TypeBalance.Name) }</p>
											<p className={style.amount}>{new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸</p>
										</div>
									))
								}
							</div>
						</div>
						<div className="fsc-modal__buttons">
							<p className={style.cancel} onClick={props.closeModal}>{ t('close_shift.cancel') }</p>
							{props.pending ?
								<button className={button.redBtn} disabled>
									{ t('close_shift.closing') } <Spinner className="" />
								</button>
								:
								<button className={button.redBtn} onClick={props.closeShift}>{ t('close_shift.close_shift') }</button>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Modal;
