import React from "react";
import Head from "./modules/Head";
import Analytics from "./modules/Analytics";
import ShiftCard from "./modules/ShiftCard";
import style from "../styles/pages/shift/shift.module.scss";

const Shift = ({
  kkm,
  balances,
  dashboard,
  shift,
  shiftOpen,
  shiftOpenByAnotherCashier,
  shiftBlocked,
  shiftErrorText,
  pending,
  shiftExpired,
  openShift,
  updataDashboard,
  openModal,
  history,
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Head kkm={kkm} history={history} />
        <div className={style.body}>
          <Analytics
            dashboard={dashboard}
            updataDashboard={() => updataDashboard()}
            shiftOpen={shiftOpen}
          />
          <ShiftCard
            kkm={kkm}
            balances={balances}
            shift={shift}
            shiftOpen={shiftOpen}
            shiftOpenByAnotherCashier={shiftOpenByAnotherCashier}
            shiftBlocked={shiftBlocked}
            shiftExpired={shiftExpired}
            shiftErrorText={shiftErrorText}
            pending={pending}
            openShift={() => openShift()}
            openModal={() => openModal()}
          />
        </div>
      </div>
    </div>
  );
};

export default Shift;
