import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Header from '../../Header/Header';
import {getAppModule} from "../../../Store/appVersion";
// import {getAppModule, getAppModuleUrl} from "../../../Store/appVersion";
import style from "../styles/pages/cashbox/NewCashboxSettings.Forbiden.Default.module.scss"
import NewCashboxTop from "./NewCashboxTop";
import CloseCircleLine from '../svg/close-circle-line.svg?react'
import HeaderVersion from "../HeaderVersion/HeaderVersion";
import { useTranslation } from "react-i18next";

const NewCashboxSettingsForbidenDefault = ({ history }) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  useEffect(() => {
    document.getElementById('root').style.backgroundColor = getAppModule() ? '#F5F5F5' : 'transparent'
    return () => document.getElementById('root').style.backgroundColor = 'transparent'
  }, [getAppModule()])

  const closeShift = () => {
    localStorage.removeItem('OPEN_SHIFT_KKM_ID')
    localStorage.removeItem('OPEN_SHIFT_RECEIPT_COUNT')
    localStorage.removeItem('OPEN_SHIFT_BALANCES')

    history.push(`/www/kkms/${kkm.Id}/close_new`)
  }

  return (
    <React.Fragment>
      <HeaderVersion isActive={true} />
      <main role="main" className={`${style.container} ${style[`container--${Capacitor.getPlatform()}`]}`}>
        <NewCashboxTop kkm={kkm}></NewCashboxTop>
        <div className={style.wrapper}>
          <div className={`${style.card} ${style['card--white']} ${style['text-center']}`}>
            <h1 className={`${style['heading-secondary']}`}>{ t('settings.settings_unavailable') }</h1>
            <p className={`${style.paragraph} ${style['mt-10']} ${style}`}>{ t('settings.settings_close_shift') }</p>
            <button
              className={`${style.btn} ${style['btn--danger']}  ${style['mt-20']}`}
              onClick={closeShift}
            >
              <CloseCircleLine style={{marginTop: "3px"}} />
              { t('settings.close_shift') }
            </button>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default NewCashboxSettingsForbidenDefault;
