import React from "react";
import style from '../styles/pages/cashbox/NewCashboxTop.module.scss'
import { useHistory} from "react-router-dom";
import ArrowLeftLine from '../svg/arrow-left-line.svg?react'
import Left from "../svg/arrowLeft.svg?react"
import { useTranslation } from "react-i18next";

const NewCashboxTop = ({kkm}) => {
	const { t } = useTranslation()

	const history = useHistory()

	const goBack = () => {
		history.push(`/www/kkms/${kkm.Id}/shift_new`)
	}


	return (
		<div className={style['flex-2']}>
			<div className={style['flex-2']}>
				<button
					onClick={goBack}
					className={`${style.btn} ${style['btn--back']}`}
					style={{ textDecoration: 'none' }}
				>
					<ArrowLeftLine className={style['arrow-left-line']}/>
					{ t('settings.back') }
				</button>
				<button
					onClick={goBack}
					className={`${style.btn} ${style['btn--icon']}`}
				>
					<Left />
				</button>
				<div className={`${style['cashbox-header__title']} ${style['ml-20']}`}>
					<h1 className={style['heading-primary']}>{ t('settings.settings') }</h1>
				</div>

				<div className={`${style.card} ${style['card--gray']} ${style['flex-4']} ${style['ml-10']} ${style['mt-10']} ${style['px-16']} ${style['py-6']}`} style={{height: '30px'}}>
				<span className={style.span}
				>{ kkm.Name }</span>
				</div>
			</div>
		</div>
	)
}

export default NewCashboxTop
