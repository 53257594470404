import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {getAppModuleUrl} from "../../Store/appVersion";
import MobileNavMenu from "./MobileNavMenu";

const MobileNavLayout = ({ kkm, isActive, toggleMenu, isOpen }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<div className="mobile-nav d-lg-none">
				<div className="mobile-nav__buttons">
					<Link to={`/www/shifts/${kkm.Id}/${getAppModuleUrl().reports.operations}`} className="mobile-nav__btn">
						<div className="mobile-nav__btn-text">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z" fill="#A39EB8" /><path d="M19.74 8.33l-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85V5zm3.44 15H6.56a.532.532 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z" /></svg>
							<div className="ml-1">{ t('main.reports') }</div>
						</div>
					</Link>
					<div className="mobile-nav__buttons">
						{!isOpen && (
							<div>
								<label htmlFor="open_menu_button" onClick={toggleMenu}>
									<svg id="open_menu_button" width="80" height="60" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="80" height="60"/> <g clipPath="url(#clip0_9233_30514)"> <circle cx="40" cy="30" r="22" fill="#00ADEE"/> <g clipPath="url(#clip1_9233_30514)"> <path d="M39 29V23H41V29H47V31H41V37H39V31H33V29H39Z" fill="white"/> </g> </g> <defs> <clipPath id="clip0_9233_30514"> <rect width="44" height="44" fill="white" transform="translate(18 8)"/> </clipPath> <clipPath id="clip1_9233_30514"> <rect width="24" height="24" fill="white" transform="translate(28 18)"/> </clipPath> </defs> </svg>
								</label>
							</div>
						)}
					</div>
					{isActive && isOpen &&
						<MobileNavMenu kkm={kkm} toggleMenu={toggleMenu} isOpen={isOpen} />
					}
					<Link to={`/www/kkms/${kkm.Id}/${getAppModuleUrl().shift}`} className="mobile-nav__btn">
						<div className="mobile-nav__btn-text">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9a3 3 0 0 0-2.82 2H3a1 1 0 0 0 0 2h13.18A3 3 0 1 0 19 9zm0 4a1 1 0 1 1 0-2.002A1 1 0 0 1 19 13zM3 7h1.18a3 3 0 0 0 5.64 0H21a1 1 0 1 0 0-2H9.82a3 3 0 0 0-5.64 0H3a1 1 0 1 0 0 2zm4-2a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM21 17h-7.18a3 3 0 0 0-5.64 0H3a1 1 0 0 0 0 2h5.18a3 3 0 0 0 5.64 0H21a1 1 0 0 0 0-2zm-10 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
							<div className="ml-2">Касса</div>
						</div>
					</Link>
				</div>
			</div>
		</React.Fragment>
	)
}
export default MobileNavLayout;
