import React from "react"
import style from "../../styles/pages/title.module.scss"
import Left from "../images/arrowLeft.svg?react"
import { useTranslation } from "react-i18next";
import SearchField from "../../NewCompanies/SearchField";

const Title = ({ 
  context, 
  title, 
  toggle, 
  history, 
  showPopup, 
  setShowPopup, 
  formType, 
  toggleForm, 
  withSearch, 
  fetchOperationInfo, 
  searchedValue, 
  setSearchedValue 
}) => {
  const { t } = useTranslation()

  let kkm = JSON.parse(localStorage.getItem('KKM'))

  const goBack = () => {
    console.log('goBack')
    history.push(`/www/kkms/${kkm.Id}/shift_new`)
  }

  const changeForm = () => {
    context.resetPosition()
    context.resetNewPreCheck()
    setShowPopup(false)
    toggleForm()
  }

  const handleSearch = () => {
    if (searchedValue && String(searchedValue).length > 3) {
      fetchOperationInfo(searchedValue);
    }
  }

  return (
    <div className={style.titleWrap}>
      <div className={style.titleWrap}>
        <button className={style.backTo} onClick={goBack}>
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2185 4.83336H11.3332V6.1667H3.2185L6.7945 9.7427L5.85184 10.6854L0.666504 5.50003L5.85184 0.314697L6.7945 1.25736L3.2185 4.83336Z" fill="#8E8E8E"/>
          </svg>
          <p>{ t('receipt.return_to_cash_register') }</p>
        </button>
        <span className={style.mobileBackTo} onClick={goBack}>
          <Left />
          <p className={style.title}>{title}</p>
        </span>
      </div>
      <div>
        {withSearch && (
          <SearchField
            searchValue={searchedValue}
            setSearchValue={setSearchedValue}
            searchOpen
            setSearchOpen={() => {}}
            query={handleSearch}
            placeholder={t('report.search_on_receipt_number')}
          />
        )}
      </div>
      {
        toggle && (
          <div className={style.mode}>
            <p>{ t('other.simplified_mode') }</p>
            <div className={formType ? style.typeCheck : style.typeCheckOff} onClick={() => setShowPopup(true)}>
              <div className={style.typeCircle}></div>
            </div>
            {
              showPopup && (
                <div className={style.popUp}>
                  <div className={style.text}>
                    <p>
                      { t('other.switch_advanced_simple_mode_warning').split('|')[0] }
                      {formType ? t('other.switch_advanced_simple_mode_warning').split('|')[1] : t('other.switch_advanced_simple_mode_warning').split('|')[2]} { t('other.switch_advanced_simple_mode_warning').split('|')[3]}
                    </p>
                    <p>{ t('other.confirm_switch_mode').split('|')[0] } {formType ? t('other.confirm_switch_mode').split('|')[1] : t('other.confirm_switch_mode').split('|')[2]}?</p>
                  </div>
                  <div className={style.actions}>
                    <div onClick={() => changeForm()}>{ t('other.proceed') }</div>
                    <div onClick={() => setShowPopup(false)}>{ t('other.cancel') }</div>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default Title;
