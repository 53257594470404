import React, { useState } from 'react'
import Head from './modules/Head'
import Analytics from './modules/Analytics'
import ShiftCard from './modules/ShiftCard'
import style from '../styles/pages/shift/shift.module.scss'
import { Capacitor } from '@capacitor/core'

const MobileShift = ({kkm, balances, dashboard, shift, shiftOpen, shiftOpenByAnotherCashier, shiftBlocked, shiftErrorText, pending, shiftExpired, openShift, updataDashboard, openModal, history}) => {

  const [tab, setTab] = useState(1)

  return (
    <div className={style[`mobileWrapper--${Capacitor.getPlatform()}`]}>
      <Head kkm={kkm} history={history} tab={tab} setTab={setTab} />
      <div className={style.body}>
        {
          tab === 1 ? (
            <ShiftCard 
              kkm={kkm}
              balances={balances}
              shift={shift}
              shiftOpen={shiftOpen}
              shiftOpenByAnotherCashier={shiftOpenByAnotherCashier}
              shiftBlocked={shiftBlocked}
              shiftExpired={shiftExpired}
              shiftErrorText={shiftErrorText}
              pending={pending}
              openShift={() => openShift()}
              openModal={() => openModal()}
            />
          ) : (
            <Analytics
              dashboard={dashboard}
              updataDashboard={() => updataDashboard()}
              shiftOpen={shiftOpen}
            />
          )
        }
      </div>
    </div>
  )
}

export default MobileShift;