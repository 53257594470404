import React, { useState, useEffect } from "react";
import CloseLine from "../../svg/close-line.svg?react";
import { BASE_URL } from "../../../../Store/snippets";
import { toast } from "react-toastify";
import Spinner from "../../NewOperationsSuccess/images/spinner.svg?react";
import Printer from "../../NewOperationsSuccess/images/printer.svg?react";
import MaskedInput from "react-text-mask";
import { useTranslation } from "react-i18next";
import { WHATSAPP_API_URL } from "../../../../constants/app";

import style from "./NewReportOperationsModal.module.scss";

const NewReportOperationsModal = ({
  reportOperationModalInfo,
  closeReportOperationsModal,
  printCheck,
  resetReportOperationsDuplicate,
}) => {
  const { t } = useTranslation();

  const [lang, setLang] = useState(2);
  const [active, setActive] = useState(1);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (document.getElementById("reports-main").offsetWidth < 420) {
      document.getElementById("reports-main").style.overflow = "hidden";
      document.getElementById("reports-main").style.height = "50vh";
      return () => {
        document.getElementById("reports-main").style.overflow = "auto";
        document.getElementById("reports-main").style.height = "100%";
      };
    }
  }, []);

  const sendEmail = () => {
    if (email.length) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError(t("other.check_email_correctness"));
      } else {
        setEmailError(false);
        setSending(true);
        let data = {
          action: "email",
          recipient: email,
        };
        const Token = localStorage.getItem("tokken");

        fetch(
          `${BASE_URL}/www/sendcheck/${reportOperationModalInfo.check.id}`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((d) => {
            setSending(false);
            if (d.Status === 200) {
              setEmail("");
              toast.success(
                `${t("other.check_email_sent_successfully")}: ${email}.`,
                { theme: "dark" }
              );
            }
          });
      }
    } else {
      setEmailError(t("other.enter_email"));
    }
  };

  const sendWa = () => {
    if (phone.replace(/\D+/g, "").length) {
      if (phone.replace(/\D+/g, "").length !== 11) {
        setPhoneError(t("other.check_phone_number_correctness"));
      } else {
        setEmailError(false);
        setSending(true);
        setTimeout(() => {
          setSending(false);
          window.open(
            `${WHATSAPP_API_URL}/send?phone=${phone}&text=Номер чека: ${reportOperationModalInfo.check.id}. Проверьте чек, перейдя по ссылке: ${reportOperationModalInfo.check.link}`
          );
          setPhone("");
          toast.success(
            `${t("other.check_sent_successfully_to_number")}: ${phone}.`,
            { theme: "dark" }
          );
        }, 1000);
      }
    } else {
      setPhoneError(t("other.enter_phone_number"));
    }
  };

  return (
    <React.Fragment>
      <div className={style.modal}>
        <div className={style.modalWrapper}>
          <div className={style.check}>
            {reportOperationModalInfo.check && (
              <div className={style["flex-1"]}>
                  <img
                    style={{ width: "248px" }}
                    id="reciept"
                    src={
                      "data:image/png;base64, " +
                      reportOperationModalInfo.check.img
                    }
                    alt="Check"
                  />
              </div>
            )}
          </div>
          <div className={style.form}>
            <div className={style.formHeader}>
              <p>Дубликат чека</p>
              <CloseLine
                onClick={() => {
                  closeReportOperationsModal();
                  resetReportOperationsDuplicate();
                }}
              />
            </div>
            <div className={style.formBody}>
              <div className={style.holder}>
                <button
                  className={active === 1 ? style.emailActive : style.email}
                  onClick={() => setActive(1)}
                >
                  Email
                </button>
                <button
                  className={active === 2 ? style.wtspActive : style.wtsp}
                  onClick={() => setActive(2)}
                >
                  WhatsApp
                </button>
              </div>
              {active === 1 ? (
                <div className={style.inputWrapper}>
                  <label htmlFor="">Отправить чек на Email</label>
                  <div className={style.sendWrapper}>
                    <input
                      type="text"
                      placeholder="example@kassa.kz"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={emailError && style.errorInput}
                    />
                    <button
                      onClick={() => sendEmail()}
                      className={sending && style.sending}
                    >
                      {sending ? <Spinner /> : "Отправить"}
                    </button>
                  </div>
                  {emailError && <p className={style.error}>{emailError}</p>}
                </div>
              ) : (
                <div className={style.inputWrapper}>
                  <label htmlFor="">Отправить чек на WhatsApp</label>
                  <div className={style.sendWrapper}>
                    <MaskedInput
                      showMask={false}
                      guide={true}
                      placeholderChar={"\u2000"}
                      mask={[
                        "+",
                        "7",
                        " ",
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                      ]}
                      inputMode="tel"
                      id="inputPhone"
                      name="number"
                      className={phoneError && style.errorInput}
                      placeholder="+7 (777) 000-00-00"
                      autoComplete="phone"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onFocus={(e) => (e.target.value = "+7")}
                      onBlur={(e) =>
                        (e.target.placeholder = "+7 (777) 000-00-00")
                      }
                    />
                    <button
                      onClick={() => sendWa()}
                      className={sending && style.sending}
                    >
                      {sending ? <Spinner /> : "Отправить"}
                    </button>
                  </div>
                  {phoneError && <p className={style.error}>{phoneError}</p>}
                </div>
              )}
            </div>
            <div className={style.formFooter}>
              <button
                onClick={() => printCheck(reportOperationModalInfo.check)}
              >
                {Capacitor.isNativePlatform() ? (
                  "Скачать чек"
                ) : (
                  <>
                    <Printer />
                    Распечатать
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewReportOperationsModal;
