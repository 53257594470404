import React, { Component } from "react";
import ForbiddenLayout from "./ForbiddenLayout";

class Forbidden extends Component {
  render() {
    return <ForbiddenLayout />;
  }
}

export default Forbidden;
