import React, {useState} from "react";
import style from '../../styles/pages/cashbox/modals/newSectionModal.module.scss'
import CloseLine from '../../svg/close-line.svg?react'
import { useTranslation } from "react-i18next";


const NewSectionModal = ({ addNewSection, onClose }) => {
	const { t } = useTranslation()

	const [Name, setName] = useState("")
	const [Nds, setNds] = useState("")
	const [NameError, setNameError] = useState("")
	const [NdsError, setNdsError] = useState("")

	const createSection = () => {
		let data = {
			Name,
			Nds: parseInt(Nds)
		}

		if (Name) {
			setNameError("")
		} else {
			setNameError(t('kassa_settings.enter_name'))
		}


		if (Nds) {
			setNdsError("")
		} else {
			setNdsError(t('other.enter_vat'))
		}

		if (Name && Nds) {
			addNewSection(data)
		} else {
			return
		}

		setName("")
		setNds("")
		onClose()
	}

	return (
		<div
			className={style.modal}
		>
			<div
				className={style['modal__overlay']}
			>
				<div
					className={`${style.card} ${style['card--white']}`}
				>
					<div
						className={style['modal__btn--close']}
						onClick={onClose}
					>
						<CloseLine/>
					</div>
					<h2 className={`${style['heading-secondary']} ${style['text-center']}`}>
						{ t('other.add_new_section') }
					</h2>
					<div className={style.form}>
						<div className={`${style['form__group']} ${style['mt-30']}`}>
							<label htmlFor="SectionName" className={style['form__label']}>{ t('other.section_name') }</label>
							<input
								type="text"
								placeholder="Введите название"
								className={`${style['form__input']} ${NameError ? style['form__input--error'] : ''}`}
								id="SectionName"
								name="SectionName"
								onChange={(e) => setName(e.target.value)}
							/>
							{
								NameError && <p className={style['form__error']}>{NameError}</p>
							}
						</div>
						<div className={style['form__group']}>
							<label htmlFor="Nds" className={style['form__label']}>{ t('kassa_settings.vat_rate') }, %</label>
							<input
								type="text"
								placeholder="0"
								className={`${style['form__input']} ${NdsError ? style['form__input--error'] : ''}`}
								id="Nds"
								name="Nds"
								onChange={(e) => setNds(e.target.value)}
							/>

							{
								NdsError && <p className={style['form__error']}>{NdsError}</p>
							}
						</div>
					</div>

					<button
						className={`${style.btn} ${style['btn--primary']} ${style['mt-20']} ${style['btn--block']}`}
						onClick={createSection}
					>
						{ t('kassa_settings.add') }
					</button>
				</div>
			</div>
		</div>
	)
}

export default NewSectionModal
