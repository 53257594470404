import React from "react";
import { Link } from "react-router-dom";

const ForbiddenLayout = () => {
  return (
    <div className="row  justify-content-center">
      <div className="col-8">
        <div className="pt-2 pb-3">
          <Link to="/www/comps">← Вернуться к выбору касс</Link>
        </div>
        <div className="alert alert-danger text-center">
          <h3>Нет доступа к указанному ресурсу</h3>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenLayout;
