import React from "react";
import style from "../../styles/pages/report/duplicates/NewReportZDuplicateTop.module.scss";
import ArrowLeftLine from "../../svg/arrow-left-line.svg?react";
import ArrowLeftSLine from "../../svg/arrow-left-s-line.svg?react";
// import {useHistory} from "react-router-dom";
import NewPrintButton from "../modules/NewPrintButton";
import {formatDate} from "../../../../Store/snippets";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import NewDownloadPDFButton from "../modules/NewDownloadPDFButton";


// const pad = (d) => {
// 	d = Number(d);
// 	return (d < 10) ? '0' + d.toString() : d.toString();
// }

// const currentDate = new Date();
// const date = `${pad(currentDate.getDate())}.${pad(currentDate.getMonth() + 1)}.${currentDate.getFullYear()}`
// const hours = `${pad(currentDate.getHours())}.${pad(currentDate.getMinutes())}`

const NewReportZDuplicateTop = ({ reportZChoosen, setReportZDuplicateIsOpen }) => {
	const { t } = useTranslation()

	// const history = useHistory()
	const shiftNumber = localStorage.getItem("SHIFT_INDEX");

	return (
		<React.Fragment>
			<div className={`${style['flex-2']} ${style['report__top']}`}>
				<div className={style['flex-4']}>
					<div className={style['report__back']}>
						<button
							onClick={() => setReportZDuplicateIsOpen(false)}
							className={`${style.btn} ${style['btn--back']}`}
							style={{ 'textDecoration': 'none' }}
						>
							<ArrowLeftLine className={style['arrow-left-line']}/>
							{ t('report.back') }
						</button>
						<button
							onClick={() => setReportZDuplicateIsOpen(false)}
							className={`${style.btn} ${style['btn--icon']}`}
						>
							<ArrowLeftSLine className={style['arrow-left-s-line']}/>
						</button>
					</div>
					<div className={`${style['report__title']} ${style['ml-20']}`}>
						<h1 className={style['heading-primary']}>{ t('report.duplicate_z_report') }</h1>
					</div>
					<div className={`${style['report__date-wrapper']} ${style['ml-20']}`}>
						<div className={style['report__date']}>
							<span>{ t('report.shift') }: {shiftNumber}</span>
							<div></div>
							<span>{formatDate(new Date(reportZChoosen.DateOpen))}</span>
							<span>-</span>
							<span>{formatDate(new Date(reportZChoosen.DateClose))}</span>
						</div>
					</div>
				</div>
				{Capacitor.isNativePlatform() ? <NewDownloadPDFButton absolutePosition={true} fileName='Дубликат-Z-отчёта' pdfCurrentStyle={style['pdf-styles']} /> : <NewPrintButton/>}	
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicateTop
