export default {
	auth: {
		welcome_to_online_cash_register: "Онлайн-кассаға қош келдіңіз",
		no_more_cash_register: "Сізге енді кассалық аппараттың қажеті жоқ",
		open_and_close_shift: "Ауысымды ашыңыз және жабыңыз",
		issue_receipts_in_a_second: "Чекті бір секундта ұрыңыз",
		collect_X_and_Z_reports_in_real_time: "Нақты уақыт режимінде X және Z-есептерді жинаңыз",
		log_in: "Кіру",
		phone_number: "Телефон нөмірі",
		password: "Құпиясөз",
		forgot_password: "Құпиясөзді ұмыттыңыз ба?",
		register: "Тіркелу",
		online_cash_register_from_kassa24_business: "Kassa24 Business-тен онлайн-касса"

	},
	registration: {
		online_cash_register: "Онлайн-касса",
		work_with_cash_register: "Кассамен сайт, мобильді қосымша арқылы немесе Қазақстандағы алғашқы Telegram фискалдық чат-ботының көмегімен жұмыс жасаңыз",
		online_payments: "Онлайн-төлемдер",
		accept_payments: "Төлемді Интернет дүкеннің веб-сайтында, мобильді қосымшада немесе әлеуметтік желілерде банктік карталар, электрондық әмияндар, төлем терминалдары немесе телефон балансы арқылы қабылдаңыз",
		delivery: "Жеткізу",
		send_goods: "Kassa24 курьерлік қызметі және Қазақстандағы ең ірі постаматтар желісі арқылы клиенттерге тауарлар жіберіңіз",
		advertising_on_terminals: "Терминалдардағы жарнама",
		promote_your_company: "Компанияңызды Kassa24 төлем терминалдарының экрандарында жарнамалаңыз. Біздің желіде Қазақстан бойынша 17000-нан астам терминал бар",
		affiliate_program: "Серіктестік бағдарлама",
		invite_friends_entrepreneurs: "Кәсіпкер достарды Kassa24 Business жүйесіне қосылуға шақырыңыз және әрбір шақырылған адамды қосудан тиімді пайыз алыңыз",
		more_details: "Толығырақ",
		log_in: "Кіру",
		register: "Тіркелу",
		name: "Аты-жөні",
		continue: "Жалғастыру",
		registering_in_personal_account: "Жеке кабинетте тіркелу арқылы сіз Кешенді қызмет көрсету шартының талаптарымен келісесіз"
	},
	main: {
		no_name: "Атауы жоқ",
		cash_register_number: "Касса",
		balance: "Баланс",
		cash: "Қолма-қол ақша",
		cashless: "Қолма-қол емес ақша",
		all_reports: "Барлық есептер",
		x_report: "Х-есеп",
		shift: "Ауысым",
		number_of_receipts: "Чектер саны",
		close_shift: "Ауысымды жабу",
		printed_receipts: "Чектер ұрылды",
		new_sale: "Жаңа сатылым",
		new_operation: "Жаңа операция",
		sale: "Cатылым",
		purchase: "Сатып алу",
		new_purchase: "Жаңа сатып алу",
		income: "Кіріс",
		expense: "Шығыс",
		expense_and_indexation: "Шығыс және инкассалау",
		refund_of_sale: "Сатылымның қайтарылуы",
		refund_of_purchase: "Сатып алудың қайтарылуы",
		refund: "Қайтару",
		title_search_refund: "Чек нөмірі бойынша іздеу жасаңыз",
		info_search_refund: "Чек нөмірі бойынша іздеу",
		info_search_refund_sale: "Төлемнің қайтарылу процесін жалғастыру үшін чек нөмірі бойынша іздеу жасаңыз",
		info_search_refund_purchase: "Төлемнің қайтарылу процесін жалғастыру үшін чек нөмірі бойынша іздеу жасаңыз",
		go_to_operation_log: "Операциялар журналына өту",
		old_site_view: "Сайттың ескі түрі",
		select_company_and_cash_register: "Компанияны және кассаны таңдау",
		select_cash_register: "Кассаны таңдау",
		search_cash_register: "Кассаны іздеу",
		list_of_companies: "Компаниялар тізімі",
		cash_registers: "Кассалар",
		znm_abbreviation: "МЗН (\"Машинаның зауыттық нөмірі\" сөзінен қысқ.)",
		cash_register_blocked_in_k24v: "К24В-де касса бұғатталған",
		cash_register_blocked_in_ofd: "ФДО-да касса бұғатталған",
		shift_opened_by_another_cashier: "Ауысым басқа кассирмен ашылған",
		cash_register_available: "Касса қолжетімді",
		your_shift: "Сіздің ауысымыңыз",
		payment_required: "Төлем жасау талап етіледі",
		"days_left": "{{count}} күн қалды",
    "paid_until_date": "{{date}} дейін төленді",
		old_view: "Сайттын | бұрынғы түрі",
		getting_data: "Деректер алынуда...",
		error: "Қате",
		loading: "Жүктелуде...",
		no_cash: "Кассалар жасалмады",
		not_found: "Сұрауыңыз бойынша ештеңе табылмады",
		found_cash: "Кассалар табылды",
		cashier: "Кассир",
		copied: "Көшірілді",
		reports: "Есептер",
		dear_user: "Құрметті пайдаланушы!",
		dear_users: "Құрметті пайдаланушылар!",
		dear_user_text: "Фискалдық чектерді басып шығаруды ұмытпаңыз. Бұл ҚР Салық кодексінің міндетті талабы.",
		dear_users_text: "Онлайн бақылау-касса машинасы Мемлекеттік кірістер комитетінің талаптарына сәйкес деректерді беру хаттамасының 2.0.2 нұсқасына қолдау көрсетеді. Кассалық аппаратты қайта бағдарламалаудың қажеті жоқ.",
		close_modal: "Жабу",
		more: "Толығырақ білу",
		more_mobile: "Толығырақ",
	},
	close_shift: {
		attention_closing_shift: "Назар аударыңыз! Сіз ауысымды жабасыз!",
		shift_closure_results: "Ауысымның жабылу қорытындысы салық басқармасына жіберіледі.",
		please_check_cash_register_balances: "Касса баланстарымен тексеріңіз.",
		cash_funds: "Қолма-қол ақша қаражаты",
		cashless_funds: "Қолма-қол ақшасыз қаражат",
		cancel: "Күшін жою",
		logout: "Шығу",
		closing: "Жауып жатыр",
		close_shift: "Ауысымды жабу",
		return_to_cash_register: "Кассаға оралу",
		back: "Артқа",
		z_report: "Z-есептер",
		shift: "Ауысым",
		print: "Басып шығару",
		section: "Секция:",
		sales: "Сатылымдар",
		sales_return: "Сатылымның қайтарылуы",
		purchases: "Сатып алулар",
		purchase_refunds: "Возврат Покупок",
		section_report: "Сатып алудың қайтарылуы",
		no_operations: "Операциялар жүргізілген жоқ",
		service_income: "Қызметтік кіріс",
		service_expense: "Қызметтік шығыс",
		purchase_returns: "Сатып алулардың қайтарылуы",
		operation_list: "Операциялар тізімі",
		operation_type: "Операциялар типі",
		date: "Күні",
		receipt_number: "Чек нөмірі",
		amount: "Сома, ₸",
		number_of_receipts: "Чектер саны",
		total_amount: "Жалпы сома",
		no_receipts: "Чектер жоқ",
		main_information: "Негізгі ақпарат",
		organization_name: "Ұйымның атауы",
		tax_id: "ЖСН/БСН",
		kkm: "БКМ",
		kgd_registration_number: "Тіркеу нөмірі | МКД-дегі",
		cash_register_name: "Кассаның атауы",
		trading_point_address: "Сауда нүктесінің мекенжайы",
		cashier: "Кассир",
		balance_at_opening: "Ауысым ашылған сәттегі баланс",
		balance_at_closing: "Ауысым жабылған сәттегі баланс",
		issued_receipts_alt: "Чектер ресімделді",
		issued_receipts: "Ресімделген чектер",
	},
	error: {
		logout: "Выйти",
		no_access_to_cash_register: "Кассаға кіру мүмкіндігі жоқ!",
		contact_tech_support: "Техникалық қолдауға жүгініңіз:",
		back_to_cash_register_list: "Кассалар тізіміне оралу"
	},
	kassa: {
		back_to_cash_register_selection: "Кассаларды таңдауға кері қарай",
		cash_register_for: "Касса үшін ...",
		active: "Іске қосылып тұр",
		shift_stats: "Ауысым статистикасы, ₸",
		shift_stats_explanation: "Ағымдағы ауысым үшін сәтті төлемдер мен қайтарулар сомасы.",
		shift_stats_explanation_alt: "Карталарда көрсетілген түсімнің, қайтарулар мен орташа чектің алдыңғы ауысыммен салыстырғанда, қалай өзгергенін және проценттегі айырмашылықты көрсететін тренд көрсетілген.",
		updated_at: "Жаңартылды",
		revenue: "Түсім",
		refunds: "Қайтарулар",
		average_receipt: "Орташа чек",
		make_operation_to_see_chart: "Кестені көру үшін операция жасаңыз",
		top_sales_of_shift: "Ауысым үшін ең жақсы сатылымдар",
		product_name: "Тауардың атауы",
		quantity: "Саны",
		amount: "Сома, ₸",
		make_operation_to_see_top_sales: "Ең жақсы сатылымдарды көру үшін операция жасаңыз",
		new_shift: "Жаңа ауысым",
		cash_resources: "Қолма-қол ақша қаражаты:",
		non_cash_resources: "Қолма-қол ақшасыз қаражат:",
		cash_register: "Касса",
		company: "Компания",
		address: "Мекенжайы",
		check_for_log: "Ауысым бойынша тексерулер",
		cash: "Қолма-қол ақшамен",
		non_cash: "Қолма-қол ақшасыз",
		transfer: "Аударым",
		shift: "Ауысым",
		operations_log: "Операциялар | журналы",
		all_reports: "Барлық есептер",
		settings: "Баптаулар",
		close_shift: "Ауысымды жабу",
		sales: "Сатылымдар",
		total: "Жиынтығы",
		start_issuing_receipts: "Чекті ұруды бастау",
		in_progress: "Тіркеу кезеңінде",
		blocked: "Бұғатталған",
		trade: 'Сауда',
		services: 'Қызметтер',
		hotels: 'Қонақ үйлер',
		parking: 'Көлік тұрағы'
	},
	report: {
		receipt_data_unavailable: "Түбіртек деректері қолжетімсіз",
		back: "Артқа",
		reports: "Есептер",
		last_shift: "Соңғы ауысым:",
		operations_log: "Операциялар журналы",
		export_to_excel: "Excel-ге экспорт",
		no_operations_found_for_current_date: "Ағымдағы күн үшін операциялар табылмады",
		x_report: "Х-есеп",
		z_report_archive: "Z-есептер архиві",
		by_sections: "Секциялар бойынша",
		no_z_reports_found_for_current_date: "Ағымдағы күн үшін Z-есептер табылмады",
		print: "Басып шығару",
		section_totals_for_shift: "Ауысым үшін секциялар бойынша жиыны",
		section: "Секция:",
		sales: "Сатылымдар",
		sales_refunds: "Сатылымдардың қайтарылуы",
		purchases: "Сатып алулар",
		purchase_refunds: "Сатып алудың қайтарылуы",
		operation_log: "Операциялар журналы",
		duplicate: "Телнұсқа",
		return: "Қайтару",
		operation_type: "Операциялар типі",
		date: "Күні",
		receipt_number: "Чек нөмірі",
		document_number: "Құжат нөмірі",
		search_on_receipt_number: "Чек нөмірі бойынша іздеу",
		cash: "Қолма-қол ақшамен, ₸",
		non_cash: "Қолма-қол ақшасыз, ₸",
		transfer: "Аударым, ₸",
		total: "Жиынтығы, ₸",
		main_information: "Негізгі ақпарат",
		issued_receipts: "Ресімделген чектер",
		organization_name: "Ұйымның атауы",
		service_income: "Қызметтік кіріс",
		number_of_receipts: "Чектер саны",
		tax_id: "ЖСН/БСН",
		total_amount: "Жалпы сома",
		kkm: "БКМ",
		kgd_registration_number: "Тіркеу нөмірі | МКД-дегі",
		service_expense: "Қызметтік шығыс",
		cash_register_name: "Кассаның атауы",
		trading_point_address: "Сауда нүктесінің мекенжайы",
		cashier: "Кассир",
		balance_at_opening: "Ауысым ашылған сәттегі баланс",
		balance_at_closing: "Ауысым жабылған сәттегі баланс",
		sales_returns: "Сатылымдардың қайтарылуы",
		issued_receipts_alt: "Чектер ресімделді",
		purchase_returns: "Сатып алулардың қайтарылуы",
		sale: "Сатылым",
		expenditure_and_encashment: "Шығыс және инкассация",
		sales_return: "Сатылымның қайтарылуы",
		shift_number: "Ауысым №",
		open: "Ашық",
		closed: "Жабық",
		received: "Қабылданды",
		handed_over: "Өткізілді",
		operation_count: "Операциялар саны",
		error: "Қате",
		start_issuing_receipts: "Чекті ұруды бастау",
		basic_information: "Негізгі ақпарат",
		identification_number: "ЖСН/БСН",
		cash_register_machine: "БКМ (Бақылау-кассалық машина)",
		registration_number_in_tax_committee: "МКК-дегі (Мемлекеттік кірістер комитеті) тіркеу нөмірі",
		point_of_sale_address: "Сауда нүктесінің мекенжайы",
		balance_at_shift_opening: "Ауысым ашылған | сәттегі баланс",
		registered_receipts: "Ресімделген чектер",
		no_receipts: "Чектер жоқ",
		section_report: "Секциялар бойынша есеп",
		operation_list: "Операциялар тізімі",
		no_operations: "Операциялар жүргізілген жоқ",
		shift_closure: "Ауысымды жабу",
		page: "Бетке өту",
		shift: "Ауысым",
		operations: "Операциялар:",
		purchase: "Сатып алу",
		income: "Кіріс",
		expense_and_indexation: "Шығыс және инкассалау",
		refund_of_purchase: "Сатып алудың қайтарылуы",
		received_alt: "Қабылданды, ₸",
		handed_over_alt: "Өткізілді, ₸",
		today: "Бүгін",
		yesterday: "Кеше",
		three_days: "3 күн",
		week: "Апта",
		month: "Ай",
		quarter: "Тоқсан",
		half_year: "Жарты жыл",
		year: "Жыл",
		select_period: "Кезеңді таңдаңыз",
		duplicate_z_report: "Z-есептердін телнұсқасы",
		amount: "Сома, ₸",
	},
	settings: {
		settings: "Баптаулар",
		settings_unavailable: "Баптаулар қолжетімді емес",
		settings_close_shift: "Берілген кассаның параметрлеріне өту үшін ауысымды жабу керек",
		shift_opened_by_cashier: "Ауысымды кассир ашты::",
		on_cash_register: "Кассада::",
		close_shift: "Ауысымды жабу",
		back: "Артқа",
	},
	open_kassa: {
		start_issuing_receipts: "Чекті ұруды бастау",
	},
	duplicate: {
		select_receipt_language: "Чек тілін таңдау",
		duplicate_receipt: "Чектің телнұсқасы",
		send_receipt_to_email: "Чекті Email-ға жіберу",
		send: "Жіберу"
	},
	kassa_settings: {
		cash_management: "Кассаны басқару",
		name: "Атауы",
		place_of_use: "Пайдалану орны",
		automatic_shift_closure: "Ауысымның автоматты жабылуы",
		shift_will_be_closed_automatically: "Касса 23:45-тен 23:59-ға дейін автоматты түрде жабылады",
		save_changes: "Өзгерісті сақтау",
		cash_settings_saved: "Касса параметрлері сақталды",
		ofd_settings: "ФДО параметрлері",
		sections: "Секциялар",
		cashiers: "Кассирлар",
		nom: "Номенклатура",
		ofd_settings_saved: "ФДО параметрлі сақталды",
		address: "Мекенжайы",
		saving_changes: "Өзгерісті сақтап жатырмыз",
		ofd: "ФДО",
		rnm: "МТН (Машинаның тіркеу нөмірі)",
		kkm_id: "БКМ id",
		token: "Токен",
		section_alt: "секция",
		sections_alt: "секция",
		search: "Іздеу",
		add_section: "Секция қосу",
		vat_rate: "ҚҚС мөлшері",
		actions: "Әрекеттер",
		disable: "Күшін жою",
		goods_without_vat: "ҚҚС-сіз тауарлар",
		vat: "ҚҚС мөлшері",
		enable: "Қосу",
		cashier_alt: "кассир",
		cashier_alt_2: "кассир",
		cashiers_alt: "кассирлер",
		block: "Бұғаттау",
		unblock: "Бұғаттаудан шығару",
		items: "21 тауар",
		add_item: "Тауар қосу",
		import_items: "Тауарлар импорты",
		barcode: "Штрихкод",
		unit_of_measurement: "Өлшем бірлігі",
		price: "Баға",
		section: "Секция",
		discount: "Жеңілдік",
		markup: "Үстеме баға",
		in_sale: "Сатылымда",
		edit: "Өзгерту",
		yes: "Иә",
		no: "Жоқ",
		piece: "дана",
		cubic_centimeter: "текше сантиметр",
		not_found: "Секция табылған жоқ. | “+ Тауар қосу“ батырмасын басу арқылы қосыңыз",
		enter_name: "Атауын енгізіңіз",
		add: "Қосу",
		cashier: "Кассир",
		product_name: "Тауардың атауы",
		price_alt: "Баға, ₸",
		markup_alt: "Үстеме баға, %",
		discount_alt: "Жеңілдік, %",
		redact: "Өңдеу",
		item: "тауар",
		item_alt: "тауар",
		items_alt: "тауарлар",
		choose_file: "Файлды таңдаңыз немесе оны осы жерге апарыңыз",
		download_template: "Номенклатура тізімін жүктеу және толтыру қажет.",
		results: "Жүктелген тізім бойынша нәтижелер кестеде қолжетімді болады.",
		download_excel_template: "Үлгіні Excel-де жүктеу",
		cancel: "Күшін жою",
		save: "Сақтау",
		item_editor: "Тауар редакторы",
		add_new_item: "Жаңа тауар қосу",
		enter_barcode: "Штрихкодты енгізіңіз",
		not_displayed: "Тауар сатылымды ресімдеу бетіндегі ашылмалы тізімде көрсетілмейді",
		selection_list: "Тауарды сатылымды ресімдеу бетіндегі ашылмалы тізімнен таңдауға болады және барлық деректер автоматты түрде толтырылады",
		kkm_sections: "Секции БКМ",
		status: "Күй",
		active: "Белсенді",
		disabled: "Өшірілген",
	},
	add_nom: {
		add_new_item: "Жаңа тауар қосу",
		barcode: "Штрихкод",
		scan_barcode: "Өнімнің штрихкодын сканерлеңіз",
		enter_barcode: "Штрихкодты енгізіңіз",
		enter_name: "Атауын енгізіңіз",
		unit_of_measurement: "Өзгеріс өлшемі",
		unit_options: [
			"дана",
			"миллиграмм",
			"грамм",
			"килограмм",
			"тонна",
			"миллилитр",
			"литр",
			"сантиметр",
			"метр",
			"шаршы сантиметр",
			"шаршы метр",
			"текше сантиметр",
			"текше метр",
			"қума метр",
			"қаптама",
			"жұп",
			"бір қызмет",
			"сағат",
			"тәулік",
			"ай",
			"жиынтық"
		],
		available_for_sale: "Тауар сатылымға қолжетімді",
		not_displayed: "Тауар сатылымды ресімдеу бетіндегі ашылмалы тізімде көрсетілмейді",
		selection_list: "Тауарды сатылымды ресімдеу бетіндегі ашылмалы тізімнен таңдауға болады және барлық деректер автоматты түрде толтырылады",
		close: "Жабу",
		save: "Сақтау",
		item_editor: "Тауар редакторы",
		choose_file: "Файлды таңдаңыз немесе оны осы жерге апарыңыз",
		file_size_limit: "Барлық файл 10 мБ артық емес, Excel (.xls) форматында",
		download_template: "Номенклатура тізімін жүктеу және толтыру қажет. Жүктелген тізім бойынша нәтижелер кестеде қолжетімді болады.",
		download_excel_template: "Үлгіні Excel-де жүктеу"

	},
	receipt: {
		old_view: "Бұрынғы түрі",
		return_to_cash_register: "Кассаға оралу",
		new_sale: "Жаңа сатылым",
		new_operation: "Жаңа операция",
		simplified_mode: "Жеңілдетілген режим",
		add_item: "Позиция қосу",
		tota_amount: "Төлем",
		clear_form: "Форманы тазалау",
		item_name: "Тауардың атауы",
		price: "Баға, ₸",
		quantity: "Саны",
		unit_of_measurement: "Өлшем бірлігі",
		excise_goods: "Акцизделетін тауар",
		section: "Секция",
		markup: "Үстеме баға",
		discount: "Жеңілдік",
		add_another: "Тағы қосу",
		precheck: "Алдыңғы чек",
		reset_precheck: "Алдыңғы чекті ауыстыру",
		payment: "Төлем",
		cash: "Қолма-қол ақшамен, ₸",
		card: "Картамен, ₸",
		discount_rate: "Жеңілдік 0.00% = 0 ₸",
		markup_rate: "Үстеме баға 0.00% = 0 ₸",
		vat: "ҚҚС 10%",
		customer_tax: "ЖСН/БСН сатып алушының",
		customer_tax_id: "ЖСН/БСН | сатып алушының",
		total_to_pay: "Төлемге",
		change: "Қайтарым",
		issue_receipt: "Чекті ресімдеу",
		select_receipt_language: "Чек тілін таңдау",
		operation_successful: "Операция сәтті өтті",
		russian: "Орыс",
		send_receipt_email: "Чекті Email-ге жіберу",
		send_receipt_whatsapp: "Чекті WhatsApp-қа жіберу",
		send: "Жіберу",
		print: "Басып шығару",
		to: "Moon ЖШС (компанияның атауы)",
		bin: "БСН ('бизнес-сәйкестендіру нөмірі' сөзінен қысқ.)",
		cashier: "Кассир",
		sale: "Сатылым",
		cost: "Құны",
		no_vat: "ҚҚС-сыз",
		discount_alt: "Жеңілдік",
		including_vat: "о.і. ҚҚС",
		total: "ЖИЫНТЫҒЫ",
		cash_alt: "Қолма-қол ақшамен",
		non_cash: "Қолма-қол ақшасыз",
		kkm_address: "БКМ мекенжайы",
		rnm: "МТН",
		znm: "МЗН",
		fiscal_code: "Фискалдық код",
		ofd: "ФДО",
		website: "Сайт",
		ks_registered_number: "КС (касса) ҚР Мемлекеттік тізіліміне № 182 болып енгізілді",
		check_by_link: "Чекті сілтеме арқылы тексеріңіз",
		receipt: "Чек",
		successful_operation: "Операция сәтті өтті",
		back: "Артқа",
		reports: "Есептер",
		last_shift: "Соңғы ауысым",
		operation_log: "Операциялар журналы",
		x_report: "Х-есеп",
		z_report_archive: "Z-есептер архиві",
		by_sections: "Секциялар бойынша",
		duplicate: "Телнұсқа",
		export_to_excel: "Excel-ге экспорт",
		online_cash_register: "Онлайн кассасы",
		operation_type: "Операциялар типі",
		date: "Күні",
		receipt_number: "Чек нөмірі",
		amount: "Сома, ₸",
		reset: "Ауыстыру",
		shift_closure: "Ауысымды жабу",
		return: "Қайтару",
		purchase: "Сатып алу",
		copy_precheck: "Алдыңғы чекті көшіру",
		go_to_page: "Бетке өту",
		online_cash_register_kassa24: "Kassa24 Business-тен онлайн-касса",
		edit: "Редакциялау",
		kg: "Кг.",
		save_changes: "Өзгерісті сақтау",
		changed_at: "15:29 өзгертілді",
		changes_saved: "Өзгеріс сақталды",
		receipt_template: "Чек үлгісі",
		select_template: "Үлгіні таңдаңыз",
		template_1: "1 үлгі",
		save_precheck_template: "Алдыңғы чектің үлгісін сақтау",
		delete_template_1: "“1 үлгіні” жою керек пе?",
		delete: "Жою",
		cancel: "Күшін жою",
		save_receipt_template: "Чектің үлгісін сақтау",
		template_name: "Үлгінің атауы",
		enter_name: "Атауын енгізіңіз",
		save: "Сақтау",
		close: "Жабу",
		scan_barcode: "Өнімнің штрихкодын сканерлеңіз",
		item: "тауар",
		items: "тауар",
		markup_alt: "Үстеме баға",
		transfer: "Аударым, ₸",
		cash_alt_2: "Қолма-қол ақша",
		transfer_alt: "Аударым",
		card_alt: "Карта",
		refund_of_sale: "Сатылымның қайтарылуы",
		refund_of_purchase: "Сатып алудың қайтарылуы",
		service_income: "Қызметтік кіріс",
		income_amount: "Кіріс сомасы, ₸",
		expense_amount: "Шығыс сомасы, ₸",
		service_expense: "Қызметтік шығыс",
		printing: "Басу",
	},
	discount_type: {
		label: "Құны/ақшалық түрін таңдаңыз",
		percent: "Процентті",
		fixed: "Сабит"
	},
	num: {
		add_product: "Тауар қосу",
		add_new_product: "Тауар қосу",
		required_field: "Міндетті өріс",
		barcode: "Штрихкод",
		enter_barcode: "Штрихкодты енгізіңіз",
		product_name: "Тауардың атауы",
		unit_piece: "дана",
		product_available_for_sale: "Тауар сатылымға қолжетімді",
		product_not_displayed_in_dropdown: "Тауар сатылымды ресімдеу бетіндегі ашылмалы тізімде көрсетілмейді",
		add: "Қосу"
	},
	translation: {
		translation: "Аударым",
	},
	other: {
		technical_break: "Техникалық үзіліс",
		apologies_for_disruptions: "Онлайн-кассаның жұмысындағы үзіліс үшін кешірім сұраймыз",
		on_support: "Біздің техникалық мамандар ақаулықты жоюмен жұмыс істеуде",
		registration_in_progress: "Касса тіркеу кезеңінде",
		k24b_blocked: "Касса K24B-де бұғатталған",
		reports: "Есептер",
		unpaid_cashier: "Касса төленген жоқ",
		open_shift_blocked_ofd: "Кассадағы ауысым ашық, бірақ касса ФДО-да бұғатталған",
		device_blocking: "Касса әртүрлі құрылғылар жұмысынан бұғатталған",
		expired_shift: "Ауысым кешіктірілген",
		incorrect_device_time: "Сіздің құрылғыдағы уақыт нақты уақытпен сәйкес келмейді",
		open_shift_on_another_cashier: "Сізде басқа кассада ашық ауысым бар",
		open_shift_ofd_block: "Кассадағы ауысым ашық, бірақ касса ФДО-да бұғатталған",
		failed_testing: "Тестілеу қатесі",
		contact_support: "Техникалық қолдау қызметімен хабарласу",
		cash_register_info: "Касса туралы ақпарат",
		analytics: "Сараптама",
		shift_receipts: "Ауысымдағы чектер",
		close_cash_register: "кассаны жабу",
		return_to_cash_register: "Кассаға оралу",
		operation_failed_device_blocking: "Операция жасалмады. Касса әртүрлі құрылғылар жұмысынан бұғатталған",
		open_shift: "Ауысымды ашу",
		testing_error: "Тестілеу қате",
		not_specified: "Біздің техникалық мамандар ақаулықты жоюмен жұмыс істеуде",
		no_operations_in_cash_register: "Аталған кассада бірде бір | операция жасалған жоқ",
		click_for_details: "Жайлы толығырақ алу үшін басыңыз",
		no_open_shift_for_x_report: "X-есепті көру үшін | ашық ауысым жоқ",
		balance_at_x_report_time: "X-есепті қалыптастырған | уақыттағы баланс",
		no_open_shift_for_section_report: "Секциялар бойынша есепті көру үшін | ашық ауысым жоқ",
		no_completed_shifts_for_z_reports: "Z-есептерді көру үшін | аяқталған ауысым жоқ",
		switch_advanced_simple_mode_warning: " | Кеңейтілген режимге көшу кезінде | Жеңілдетілген режимге көшу кезінде | барлық деректер жоғалады",
		confirm_switch_mode: " | Кеңейтілген режимге көшу керекпіз бе | Жеңілдетілген режимге көшу керекпіз бе",
		cancel: "Жою",
		proceed: "Жалғастыру",
		simplified_mode: "Жеңілдетілген режим",
		enter_name: "Атауын енгізіңіз",
		enter_amount: "Соманы енгізіңіз",
		activity_type: "Қызметтер түрі",
		add_to_precheck: "Алдыңғы чекке қосу",
		excise_code: "Акциз коды",
		add_position: "Позицияны қосу",
		empty_precheck: "Бос чек",
		add_items_on_the_left_block: "Сол жақты блокқа тауарды қосыңыз",
		precheck_refund_description: "Іздеу жолағындағы қажетті чекті табыңыз",
		non_cash_payments_received: "Мобильді қосымша | басқа құрылғы арқылы алатын штрих-код арқылы тауар | қызмет үшін ақшасыз төлемдер (ҚР Заңы 31.12.2021 №100-VII)",
		iin_bin_optional: "ЖСН/БИН міндетті емес, сұрау бойынша енгізіледі",
		enter_valid_bin_iin: "Дұрыс БИН/ЖСН енгізіңіз",
		insufficient_funds_for_operation: "Операцияларды жасау үшін қажетті қаражат жоқ",
		purchase_of_goods: "Тауарды сатып алу",
		section_successfully_added: "Секция сәтті қосылды",
		item_successfully_updated: "Тауар сәтті жаңартылды",
		item_successfully_added: "Тауар сәтті қосылды",
		enter_cash_register_name: "Кассаның атауын көрсетіңіз",
		enter_section_name: "Секцияның атауын көрсетіңіз",
		enter_vat: "ҚҚС-ты көрсетіңіз",
		add_new_section: "Жаңа секцияны қосу",
		section_name: "Секцияның атауы",
		cashiers_not_found: "Кассирлер табылған жоқ.",
		you_can_add_cashiers_in_kassa24_business_account_go_to_cash_register_settings: "Кассирлерді кассалар параметріне өте отырып, | Kassa24 Business жеке кабинетіне қосуға болады",
		no_items_found_add_by_clicking_add_item: "Тауарлар табылған жоқ. '+Тауар қосу' батырмасын | басумен қосыңыз",
		uploaded_files: "Жүктелген файлдар",
		invalid_file_format: "Файлдың форматы қате",
		file_too_large: "Файл өте көп",
		file_size_limit: "Excel (.xls) форматындағы файлдың көлемі 10 мБ көп емес",
		uploading_your_file: "Сіздің файлды жүктеудеміз",
		rows_not_loaded_edit_file_and_upload_again: "Таңдалған жолдар жүктелмеді, файлды өңдеңіз және оны қайта жүктеңіз",
		successfully_imported_items: "Тауарлар сәтті импортталды",
		enter_item_name: "Тауардың атауын көрсетіңіз",
		enter_item_price: "Тауардың бағасын көрсетіңіз",
		barcode_length_8_or_13: "Штрихкодтың ұзындығы 8 немесе 13 болуы тиіс",
		access_restricted: "Қолжетімділік шектелген",
		programming_mode_available_only_with_closed_shift: "Бағдарламалау режимі кассадағы жабық ауысымда ғана қолжетімді.",
		close_shift_to_access_this_mode: "Осы режимге қол жеткізу үшін кассадағы ауысымды жабу қажет.",
		return_to_operation_mode: "Операциялар режиміне оралу",
		check_email_correctness: "Email почтасының дұрыстығын тексеріңіз",
		check_email_sent_successfully: "Чек email почтаға сәтті жіберілді",
		enter_email: "Email почтасын көрсетіңіз",
		check_phone_number_correctness: "Телефон нөмірінің дұрыстығын тексеріңіз",
		check_sent_successfully_to_number: "Чек нөмірге сәтті жіберілді",
		enter_phone_number: "Телефон нөмірін көрсетіңіз",
		check_sent_for_printing: "Чек басып шығаруға жіберілді",
		add_new_section_alt: "Жаңа секцияны қосу",
		enter_password: "Құпиясөзді енгізіңіз",
		enter_phone: "Телефонды енгізіңіз",
		at_least_6_characters: "Кемінде 6 символ",
		incorrect_login_or_password: "Логин немесе құпиясөз қате",
		your_shift: "Сіздің ауысымыңыз",
		cash_register_available: "Касса қолжетімді",
		shift_opened_by_another_cashier: "Ауысым басқа кассирмен ашылған",
		cash_register_blocked_in_ofd: "ФДО-да касса бұғатталған",
		cash_register_blocked_in_ofd_kassa: "ФДО-да мен К24В-де касса бұғатталған",
		go_to_page: "Бетке өту",
	}
}
