import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import axios from "axios";

import MobileNav from '../../../MobileNav/MobileNav';
import Title from "../modules/Title"
import PurchaseRefundContainer from "./PurchaseRefundContainer";
import style from "../../styles/pages/operation.module.scss"
import HeaderVersion from "../../HeaderVersion/HeaderVersion";
import { BASE_URL, getHeaders } from "../../../../Store/snippets";
import { OPERATION_TYPES } from "../../../../constants/app";
import GoToSaleRefundModal from "../RefundModals/GoToAnotherRefundPageModal";
import CanNotBeRefundedModal from "../RefundModals/CanNotBeRefundedModal";
import { Capacitor } from "@capacitor/core";

const PurchaseRefundOperation = (props) => {
  const { t } = useTranslation()
  const headers = getHeaders(props.history)
  const historyState = props.history.location.state;
  const purchaseInfoFromSaleRefund = historyState ? historyState.purchasePositionsFromSaleRefund : [];
  const snInfoFromSaleRefund = historyState ? historyState.snFromSaleRefund : '';
  const [searchedOperationPositions, setSearchedOperationPositions] = useState([]);
  const [snForRefundsPosition, setSnForRefundsPosition] = useState('');
  const [isOpenGoToSaleRefundModal, setOpenGoToSaleRefundModal] = useState(false); // отображается когда тип операции = продажа
  const [isOpenErrorRefundModal, setOpenErrorRefundModal] = useState(false); // отображается когда тип операции не соответствует продаже или покупке

  useEffect(() => {
    if (!localStorage.getItem('PositionDetailsFromReport')) {
      return
    }

    props.context.getRefundPositionDetails()

    const operationIdFromLs = localStorage.getItem('OperationIdFromReport');
    if (operationIdFromLs) {
      props.context.setSnForRefunds(operationIdFromLs)
    }
    
    return () => {
      localStorage.removeItem('PositionDetailsFromReport')
      localStorage.removeItem('OperationIdFromReport')
      localStorage.removeItem('PositionDetailsIsCashTransaction')
    }
  }, [])

  useEffect(() => {
    if (purchaseInfoFromSaleRefund.length) {
      props.context.getRefundPositionDetails(purchaseInfoFromSaleRefund)
      props.context.setSnForRefunds(snInfoFromSaleRefund)
    }
  }, [purchaseInfoFromSaleRefund, snInfoFromSaleRefund])

	const fetchOperationInfo = (receiptId) => {
    props.context.setSnForRefunds(receiptId)
    
		axios.get(`${BASE_URL}/operations/${receiptId}`, { headers })
    .then(response => {
      const operationData = response.data.Data.Document;
      const isCashTransaction = operationData.Cash > 0;

        // если тип операции = покупка, то даем cделать возврат
        if (operationData.IdTypedocument === OPERATION_TYPES.PURCHASE.ID) {
          setSearchedOperationPositions(operationData.Positions);
          props.context.getRefundPositionDetails(operationData.Positions, isCashTransaction);   
        } else if (operationData.IdTypedocument === OPERATION_TYPES.SALE.ID) {
          setSearchedOperationPositions(operationData.Positions);  
          setSnForRefundsPosition(receiptId);
          setOpenGoToSaleRefundModal(true);
        } else {
          setOpenErrorRefundModal(true);
        }
			})
			.catch(e => {
        console.log(e);
        setOpenErrorRefundModal(true);
      })
	}

  const handleGoToPurchaseRefundClick = () => {
    props.history.push('refund_new', { 
      salePositionsFromPurchaseRefund: searchedOperationPositions, 
      snFromPurchaseRefund: snForRefundsPosition 
    });
  };

  return (
    <React.Fragment>
      {isOpenGoToSaleRefundModal && 
        <GoToSaleRefundModal 
          closeModal={() => setOpenGoToSaleRefundModal(false)} 
          handleGoToPurchaseRefundClick={handleGoToPurchaseRefundClick}
          currentOperationType={OPERATION_TYPES.PURCHASE_REFUND.KEY}
        /> 
      }
      {isOpenErrorRefundModal &&
        <CanNotBeRefundedModal closeModal={() => setOpenErrorRefundModal(false)} />
      }
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <HeaderVersion isActive={true} balances={props.context.Balances} {...props} />
        ) : (
          <MobileNav isActive={true} />
        )
      }
      <div className={`${style.main} ${style[`main--${Capacitor.getPlatform()}`]}`}>
        <div className={style.container}>
          <Title
            title={ t('receipt.refund_of_purchase') }
            history={props.history} 
          />
          <PurchaseRefundContainer context={props.context} history={props.history} fetchOperationInfo={fetchOperationInfo} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default PurchaseRefundOperation;
