import React, {useState} from "react";
import style from "../../styles/pages/report/duplicates/NewReportZDuplicateInfo.module.scss";
import stylePdf from "../../styles/pages/report/duplicates/NewReportZDuplicateTop.module.scss";
import ArrowDownSLine from "../../svg/arrow-down-s-line.svg?react";
import ArrowUpSLine from "../../svg/arrow-up-s-line.svg?react";
import {formatAmount} from "../../../../Store/snippets";
import { useTranslation } from "react-i18next";

const NewReportZDuplicateInfo = ({ reportZChoosen, user }) => {
	const { t } = useTranslation()

	const [infoMobileIsCollapsed, setInfoMobileIsCollapsed] = useState(false)
	const [receiptMobileIsCollapsed, setReceiptMobileIsCollapsed] = useState(false)

	const reportZReceiptTitles = [
		{
			title: t('report.service_income'),
			amount: 'IncomesQty',
			total: 'IncomesAmount',
		},
		{
			title: t('report.service_expense'),
			amount: 'ExpensesQty',
			total: 'ExpensesAmount',
		},
		{
			title: t('report.sale'),
			amount: 'SalesQty',
			total: 'SalesAmount',
		},
		{
			title: t('report.sales_refunds'),
			amount: 'RefundsQty',
			total: 'RefundsAmount',
		},
		{
			title: t('report.purchases'),
			amount: 'PurchasesQty',
			total: 'PurchasesAmount',
		},
		{
			title: t('report.purchase_refunds'),
			amount: 'PurchaseRefundsQty',
			total: 'PurchaseRefundsAmount',
		},
	]

	const reportZReceiptRenderList = reportZReceiptTitles.map((reportZItem, index) => {
		return (
			<div key={index} className={`${style['table__list']} ${stylePdf['pdf-list']}`}>
				<div className={`${style['table__item']} ${style['table__item--2']}`}>
					{ reportZItem.title }
				</div>
				{
					reportZChoosen[reportZItem.amount] > 0
						?
						<React.Fragment>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportZChoosen[reportZItem.amount]}</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
							<div className={`${style['table__item']} ${style['table__item--2']}`}>{formatAmount(reportZChoosen[reportZItem.total])} ₸</div>
						</React.Fragment>
						:
						<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.no_receipts') }</div>
				}
			</div>
		)
	})
	return (
		<React.Fragment>
			<div className={`${style.card} ${style['card--white-nopadding']}`}>
				<div className={style.table}>
					<div className={`${style['table__content']} ${infoMobileIsCollapsed && style['collapsed']} ${stylePdf['pdf-collapsed']}` }>
						<div
							className={style['table__header']}
							onClick={() => {
								setInfoMobileIsCollapsed(!infoMobileIsCollapsed)
							}}
						>
							<div>{ t('report.main_information') }</div>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowDownSLine/>
							</button>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowUpSLine/>
							</button>
							<p className={style.paragraph}>{ t('other.click_for_details') }</p>
						</div>
						<div className={`${style['table__body']}`}>
							<div className={`${style['table__list']} ${stylePdf['pdf-list']}`}>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.organization_name') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Company.FullName}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.tax_id') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Company.Bin}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.kkm') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Id}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.total_amount').split('|')[0] }
									<br/>
									{ t('report.total_amount').split('|')[1] }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Rnm}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.cash_register_name') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Name}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.trading_point_address') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Kkm.Address.Town.Name}, {reportZChoosen.Kkm.Address.Street}
									{reportZChoosen.Kkm.Address.House} {reportZChoosen.Kkm.Address.Flat}
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.cashier') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{user.Name}
								</div>
							</div>
							<div className={`${style['table__list']} ${stylePdf['pdf-list']}`}>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.balance_at_opening') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{new Intl.NumberFormat('ru-RU').format(reportZChoosen.BalanceOpen)} ₸
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.balance_at_closing') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{new Intl.NumberFormat('ru-RU').format(reportZChoosen.BalanceClose)} ₸
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{ t('report.issued_receipts_alt') }
								</div>
								<div className={`${style['table__item']} ${style['table__item--1']}`}>
									{reportZChoosen.Count}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`${style.card} ${style['card--white-nopadding']} ${style['ml-20']}`}>
				<div className={`${style['table__content']} ${receiptMobileIsCollapsed && style['collapsed']} ${stylePdf['pdf-collapsed']}`}>
					<div className={style['table__content']}>
						<div
							className={style['table__header']}
							onClick={() => {
								setReceiptMobileIsCollapsed(!receiptMobileIsCollapsed)
							}}
						>
							<div>{ t('report.registered_receipts') }</div>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowDownSLine/>
							</button>
							<button className={`${style.btn} ${style['btn--icon']}`}>
								<ArrowUpSLine/>
							</button>
							<p className={style.paragraph}>{ t('other.click_for_details') }</p>
						</div>
						<div className={style['table__body']}>
							{reportZReceiptRenderList}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicateInfo
